import { chunk } from 'lodash-es';
import type { JSX } from 'react';

import { SegmentedProgressBarChart } from 'Components/BaseCharts/SegmentedProgressBarChart';
import { Carousel } from 'Components/Carousel/Carousel';
import { CarouselItem } from 'Components/Carousel/CarouselItem/CarouselItem';
import { CSSColors } from 'Components/Colors';
import { Text } from 'Components/Text/Text';
import { ComplianceSummary } from 'Models/Dashboards';

import styles from './ComplianceCarousel.module.css';

export interface ComplianceCarouselProps {
    complianceSummaries: ComplianceSummary[];
    handleChartClick: (regulationTypeSelected: string, complianceName: string) => void;
}

export const ComplianceCarousel = (props: ComplianceCarouselProps): JSX.Element => {
    const inactiveColor = CSSColors.DARK_GRAY;
    const moderateColor = CSSColors.YELLOW;
    const lineColor = CSSColors.LIGHT_BLUE;
    const gray = CSSColors.GRAY;

    const complianceSection = (complianceSummary: ComplianceSummary, index: number) => {
        return (
            <div key={index}>
                <Text variant="Text2" color="darkGray">
                    {complianceSummary.regulation}
                </Text>
                <SegmentedProgressBarChart
                    name={complianceSummary.regulation}
                    data={[
                        { value: complianceSummary.met_count, name: 'Met', color: lineColor },
                        { value: complianceSummary.not_met_count, name: 'Unmet', color: moderateColor },
                        { value: complianceSummary.awaiting_assessments_count, name: 'Not Assessed', color: gray },
                        { value: complianceSummary.not_mapped_count, name: 'Not Mapped', color: inactiveColor },
                    ]}
                    onChartClick={(item: string) => props.handleChartClick(item, complianceSummary.regulation)}
                    chartDimensions={{ width: '100%', height: 50 }}
                    tooltipDirection={index === 0 ? 'bottom' : undefined}
                />
            </div>
        );
    };

    const filteredRegulations = props.complianceSummaries.filter((complianceSummary) => {
        const totalActiveRequirements = complianceSummary.awaiting_assessments_count + complianceSummary.met_count + complianceSummary.not_met_count;
        return totalActiveRequirements > 0;
    });

    const compareByRegulationProgress = (summaryA: ComplianceSummary, summaryB: ComplianceSummary): number => {
        const totalActiveRequirementsA = summaryA.not_met_count + summaryA.awaiting_assessments_count + summaryA.met_count;
        const totalRequirementsA = totalActiveRequirementsA + summaryA.not_mapped_count;
        const totalActiveRequirementsB = summaryB.not_met_count + summaryB.awaiting_assessments_count + summaryB.met_count;
        const totalRequirementsB = totalActiveRequirementsB + summaryB.not_mapped_count;

        const percentageMetA = totalActiveRequirementsA / totalRequirementsA;
        const percentageMetB = totalActiveRequirementsB / totalRequirementsB;

        return percentageMetA > percentageMetB ? -1 : 1;
    };

    if (filteredRegulations.length === 0) {
        return (
            <div>
                <Text variant="Text3" color="darkGray">
                    No compliance requirements to display. Map a requirement to a control to get started.
                </Text>
            </div>
        );
    } else if (filteredRegulations.length <= 3) {
        return <>{[...filteredRegulations].sort(compareByRegulationProgress).map(complianceSection)}</>;
    } else {
        const complianceSummaryMap = chunk([...filteredRegulations].sort(compareByRegulationProgress), 3);

        return (
            <Carousel showControls={true} showIndicators={false}>
                {complianceSummaryMap.map((complianceArray, index) => {
                    return (
                        <CarouselItem key={index}>
                            <div className={styles.carousel}>{complianceArray.map(complianceSection)}</div>
                        </CarouselItem>
                    );
                })}
            </Carousel>
        );
    }
};
