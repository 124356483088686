import { FileUpdates, UploadedFile } from './Files';
import { OperationalControl } from './OperationalControls';
import { OptionType } from './Types/GlobalType';

export enum GovernanceStatus {
    ACTIVE = 'ACTIVE',
    RETIRED = 'RETIRED',
}

interface BaseGovernanceVersion {
    id: string;
    status: GovernanceStatus;
    last_updated: string;
    last_updated_by: string;
    associated_controls: OperationalControl[];
    is_effective: boolean;
    has_future_effective_date: boolean;
    owner_user_id: string;
    title: string;
    type: GovernanceType;
    effective_date: string;
    changelog: string;
}

export interface UrlBasedGovernanceVersion extends BaseGovernanceVersion {
    content_type: GovernanceContentType.EXTERNAL_URL;
    external_url: string;
}

export interface DocumentBasedGovernanceVersion extends BaseGovernanceVersion {
    content_type: GovernanceContentType.DOCUMENT;
    file: UploadedFile;
}

export interface TextBasedGovernanceVersion extends BaseGovernanceVersion {
    content_type: GovernanceContentType.TEXT;
    text: string;
}

export type GovernanceVersion = UrlBasedGovernanceVersion | DocumentBasedGovernanceVersion | TextBasedGovernanceVersion;

interface BaseCreateGovernanceVersionRequest {
    owner_user_id: string;
    title: string;
    type: GovernanceType;
    effective_date: string;
    changelog: string;
    associated_controls: string[];
}

export interface CreateTextBasedGovernanceVersionRequest extends BaseCreateGovernanceVersionRequest {
    content_type: GovernanceContentType.TEXT;
    text: string;
}

export interface CreateDocumentBasedGovernanceVersionRequest extends BaseCreateGovernanceVersionRequest {
    content_type: GovernanceContentType.DOCUMENT;
    /**
     * This parameter can be a little confusing, since an existing document _can_ be preserved if and only if there is an existing governance version that is _effective_ (i.e., has an effective datetime in the past).
     * Here are the possible cases and what `file_updates` will look like in each case:
     * * Creating the initial version:
     * * * `new_files` contains the file.
     * * * `existing_files_to_delete` is empty.
     * * Adding a new version for an existing policy when there is no existing effective version:
     * * * `new_files` contains the file.
     * * * `existing_files_to_delete` is empty.
     * * Adding a new version for an existing policy when there is an existing effective version, and the existing file is being preserved:
     * * * `new_files` is empty.
     * * * `existing_files_to_delete` is empty.
     * * Adding a new version for an existing policy when there is an existing effective version, and the existing file is being replaced:
     * * * `new_files` contains the new file.
     * * * `existing_files_to_delete` contains the ID of the old file.
     */
    file_updates: FileUpdates;
}

export interface CreateUrlBasedGovernanceVersionRequest extends BaseCreateGovernanceVersionRequest {
    content_type: GovernanceContentType.EXTERNAL_URL;
    external_url: string;
}

export type CreateGovernanceVersionRequest = CreateTextBasedGovernanceVersionRequest | CreateDocumentBasedGovernanceVersionRequest | CreateUrlBasedGovernanceVersionRequest;

export enum GovernanceContentType {
    EXTERNAL_URL = 'EXTERNAL_URL',
    TEXT = 'TEXT',
    DOCUMENT = 'DOCUMENT',
}

export enum GovernanceType {
    PROCEDURE = 'PROCEDURE',
    POLICY = 'POLICY',
    STANDARD = 'STANDARD',
}

export const GovernanceValuesSelectOptions: OptionType[] = [
    {
        label: 'Procedure',
        value: GovernanceType.PROCEDURE,
    },
    {
        label: 'Policy',
        value: GovernanceType.POLICY,
    },
    {
        label: 'Standard',
        value: GovernanceType.STANDARD,
    },
];

/**
 * Note that the values in this enum must correspond to the property names of GovernanceVersion.
 * This is because the user sorting function uses index access based on these values.
 */
export enum GovernanceSortFilterOptions {
    TITLE = 'title',
    OWNER = 'owner_user_id',
    EFFECTIVE_DATE = 'effective_date',
    CONTROLS_MAPPED = 'controls_mapped',
}

export const getHumanReadableGovernanceType = (type: GovernanceType): string => {
    switch (type) {
        case GovernanceType.POLICY:
            return 'Policy';
        case GovernanceType.PROCEDURE:
            return 'Procedure';
        case GovernanceType.STANDARD:
            return 'Standard';
    }
};

export const getHumanReadableGovernanceContentType = (type: GovernanceContentType): string => {
    switch (type) {
        case GovernanceContentType.DOCUMENT:
            return 'Document';
        case GovernanceContentType.TEXT:
            return 'Text';
        case GovernanceContentType.EXTERNAL_URL:
            return 'External URL';
    }
};
