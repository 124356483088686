import moment from 'moment';
import { type JSX, useMemo, useState } from 'react';
import { Form } from 'react-bootstrap';

import { ControlsApi } from 'Api/Controls/ControlsApi';
import { DocumentApi } from 'Api/Document/DocumentApi';
import { GovernanceApi } from 'Api/Governance/GovernanceApi';
import { Button } from 'Components/Buttons/Buttons';
import { useCachedData } from 'Components/Context/CachedDataContext';
import { FileManagementArea, FileManagementAreaRestriction, useFileManagementArea } from 'Components/Files/FileManagementArea';
import { FormFieldDatePicker } from 'Components/FormField/FormFieldDatePicker/FormFieldDatePicker';
import { ChangeEventType, FormFieldSelect } from 'Components/FormField/FormFieldSelect/FormFieldSelect';
import { FormFieldText } from 'Components/FormField/FormFieldText/FormFieldText';
import { FormFieldTextArea } from 'Components/FormField/FormFieldTextArea/FormFieldTextArea';
import { FormFieldUserSelect } from 'Components/FormField/FormFieldUserSelect/FormFieldUserSelect';
import { ModalHeader } from 'Components/Modal/ModalHeader';
import { MultipleControlMapping, MultipleControlMappingProps } from 'Components/MultipleControlMapping/MultipleControlMapping';
import { LinkButtonToast, TextToast } from 'Components/Toast/Toast';
import { ICON_SUBMIT } from 'Config/Icons';
import { GOVERNANCE } from 'Config/Paths';
import { jsDateToIso8601 } from 'Helpers/DateTimeUtils/DateTimeUtils';
import { validateUrl } from 'Helpers/InputValidation';
import { getFrameworkGroupControlURL } from 'Helpers/URLBuilder/URLBuilder';
import { CreateDocumentBasedGovernanceVersionRequest, CreateGovernanceVersionRequest, CreateTextBasedGovernanceVersionRequest, CreateUrlBasedGovernanceVersionRequest, GovernanceContentType, GovernanceType, GovernanceValuesSelectOptions, GovernanceVersion } from 'Models/Governance';
import { OperationalControl } from 'Models/OperationalControls';
import { OptionType } from 'Models/Types/GlobalType';
import { UserResponse } from 'Models/User';

import styles from './AddGovernanceVersionForm.module.css';

export interface AddGovernanceVersionFormProps {
    controlsApi: ControlsApi;
    controlMappingItems: OperationalControl[];
    defaultMappedControls: string[];
    documentApi: DocumentApi;
    governanceApi: GovernanceApi;

    controlNavigatedFrom?: OperationalControl;
    existingGovernanceId?: string;
    initialEffectiveVersion?: GovernanceVersion;
}

interface FormFieldsState {
    effective_date: Date;
    associated_controls: string[];
    content_type: GovernanceContentType;
    type: GovernanceType;

    title?: string;
    owner?: UserResponse;
    changelog?: string;
    external_url?: string;
    text?: string;
}

interface FormState {
    isUpdatingGovernance: boolean;
    successMessage?: string;
    failureMessage?: string;
}

/**
 * Returns the start of the current date without the time, so that policies are considered effective at the exact start of today.
 */
const getStartOfToday = () => {
    return moment().startOf('day').toDate();
};

const GovernanceContentTypeSelectOptions: OptionType[] = [
    {
        label: 'Document',
        value: GovernanceContentType.DOCUMENT,
    },
    {
        label: 'Text',
        value: GovernanceContentType.TEXT,
    },
    {
        label: 'External URL',
        value: GovernanceContentType.EXTERNAL_URL,
    },
];

export const AddGovernanceVersionForm = (props: AddGovernanceVersionFormProps): JSX.Element => {
    const cachedData = useCachedData();
    const [formState, setFormState] = useState<FormState>({ isUpdatingGovernance: false });
    const [submitRequestWithManagedFiles, fileManagementHookValues] = useFileManagementArea<string>(props.documentApi, props.initialEffectiveVersion?.content_type === GovernanceContentType.DOCUMENT ? [props.initialEffectiveVersion.file] : [], FileManagementAreaRestriction.EXACTLY_ONE_FILE);

    const [createdVersionId, setCreatedVersionId] = useState<string>();

    const [formFieldState, setFormFieldState] = useState<FormFieldsState>(() => {
        if (props.initialEffectiveVersion) {
            return {
                associated_controls: props.initialEffectiveVersion.associated_controls.map((control) => control.identifier),
                content_type: props.initialEffectiveVersion.content_type,
                effective_date: getStartOfToday(),
                external_url: props.initialEffectiveVersion.content_type === GovernanceContentType.EXTERNAL_URL ? props.initialEffectiveVersion.external_url : undefined,
                owner: cachedData.users.find((user) => user.cognito_subject === props.initialEffectiveVersion!.owner_user_id),
                text: props.initialEffectiveVersion.content_type === GovernanceContentType.TEXT ? props.initialEffectiveVersion.text : undefined,
                title: props.initialEffectiveVersion.title,
                type: props.initialEffectiveVersion.type,
            };
        } else {
            return {
                associated_controls: props.defaultMappedControls,
                content_type: GovernanceContentType.DOCUMENT,
                effective_date: getStartOfToday(),
                type: GovernanceType.POLICY,
            };
        }
    });

    const multipleControlMappingProps: MultipleControlMappingProps | undefined = useMemo(
        () => ({
            controls: props.controlMappingItems,
            handleControlChange: (controls: string[]) => setFormFieldState((current) => ({ ...current, associated_controls: controls })),
            currentMappedControlIdentifiers: props.defaultMappedControls,
        }),
        [props.controlMappingItems, props.defaultMappedControls]
    );

    const governanceTypeText = (() => {
        switch (formFieldState.type) {
            case GovernanceType.POLICY:
                return 'Policy';
            case GovernanceType.STANDARD:
                return 'Standard';
            case GovernanceType.PROCEDURE:
                return 'Procedure';
        }
    })();

    const submitRequest = async () => {
        // Define a helper function for creating the new version, which will use either the "create initial version" or the "add new version" endpoint.
        const createNewVersion = async (request: CreateGovernanceVersionRequest) => {
            if (props.existingGovernanceId) {
                return (await props.governanceApi.addGovernanceVersion(props.existingGovernanceId, request)).data;
            } else {
                return (await props.governanceApi.createInitialGovernanceVersion(request)).data;
            }
        };

        try {
            setFormState({ isUpdatingGovernance: true });

            // Perform content-type-agnostic validation.
            if (!formFieldState.title) {
                throw new Error('Title is required.');
            }

            if (!formFieldState.owner) {
                throw new Error('Owner is required.');
            }

            if (!formFieldState.effective_date) {
                throw new Error('Effective date is required.');
            }

            if (!formFieldState.changelog) {
                throw new Error('Changelog is required.');
            }

            const baseRequest = {
                title: formFieldState.title,
                type: formFieldState.type,
                owner_user_id: formFieldState.owner.cognito_subject,
                content_type: formFieldState.content_type,
                effective_date: jsDateToIso8601(formFieldState.effective_date),
                changelog: formFieldState.changelog,
                associated_controls: formFieldState.associated_controls,
                external_url: formFieldState.content_type === GovernanceContentType.EXTERNAL_URL ? formFieldState.external_url : undefined,
            };

            // Perform content-type-specific validation and submit the request.
            let createdVersionId: string;
            switch (formFieldState.content_type) {
                case GovernanceContentType.DOCUMENT:
                    createdVersionId = await submitRequestWithManagedFiles(async (fileUpdates) => {
                        const documentRequest: CreateDocumentBasedGovernanceVersionRequest = {
                            ...baseRequest,
                            content_type: GovernanceContentType.DOCUMENT,
                            file_updates: fileUpdates,
                        };

                        return await createNewVersion(documentRequest);
                    });

                    break;
                case GovernanceContentType.TEXT:
                    if (!formFieldState.text) {
                        throw new Error(`A definition is required for a text-based ${governanceTypeText}.`);
                    }

                    const textRequest: CreateTextBasedGovernanceVersionRequest = {
                        ...baseRequest,
                        content_type: GovernanceContentType.TEXT,
                        text: formFieldState.text,
                    };

                    createdVersionId = await createNewVersion(textRequest);
                    break;
                case GovernanceContentType.EXTERNAL_URL:
                    if (!formFieldState.external_url) {
                        throw new Error(`A link is required for a URL-based ${governanceTypeText}.`);
                    }
                    const validationResult = validateUrl(formFieldState.external_url);
                    if (validationResult['valid'] !== true) {
                        throw new Error(validationResult['message']);
                    }

                    const urlRequest: CreateUrlBasedGovernanceVersionRequest = {
                        ...baseRequest,
                        content_type: GovernanceContentType.EXTERNAL_URL,
                        external_url: formFieldState.external_url,
                    };

                    createdVersionId = await createNewVersion(urlRequest);
                    break;
            }

            // Record the ID of the new governance, so that the user can be linked to the Manage Governance page (and so this component knows to show a success message).
            setCreatedVersionId(createdVersionId);
            setFormState({ isUpdatingGovernance: false, successMessage: `${governanceTypeText} version saved.` });
        } catch (error) {
            setFormState({ failureMessage: error.message, isUpdatingGovernance: false });
        }
    };

    const handleChange = (event: React.FormEvent<HTMLInputElement>): void => {
        event.preventDefault();
        setFormFieldState({ ...formFieldState, [event.currentTarget.name]: event.currentTarget.value });
    };

    const handleSelectChange = (value: ChangeEventType, formFieldId: string): void => {
        setFormFieldState({ ...formFieldState, [formFieldId]: value });
    };

    const handleSelectOwnerChange = (user: UserResponse, formFieldId: string): void => {
        setFormFieldState({ ...formFieldState, owner: user });
    };

    const handleChangeEffectiveDate = (date: Date): void => {
        setFormFieldState({ ...formFieldState, effective_date: date });
    };

    const governanceUploadFields = (): JSX.Element => {
        switch (formFieldState.content_type) {
            case GovernanceContentType.DOCUMENT:
                return (
                    <div className={styles.fileSelectionContainer}>
                        <FileManagementArea fileDragAndDropFormFieldLabel="Document" existingFilesFormFieldLabel="Existing Document" disabled={false} documentApi={props.documentApi} fileManagementHookValues={fileManagementHookValues} />
                    </div>
                );
            case GovernanceContentType.TEXT:
                return (
                    <div className={styles.fieldContainer}>
                        <FormFieldTextArea required handleChange={handleChange} formFieldId="text" formFieldLabel="Definition" rows={3} tooltip={`The textual definition of the ${governanceTypeText}.`} value={formFieldState.text} />
                    </div>
                );
            case GovernanceContentType.EXTERNAL_URL:
                return (
                    <div className={styles.fieldContainer}>
                        <FormFieldText required handleChange={handleChange} formFieldId="external_url" formFieldLabel="Link" tooltip={`The hyperlink to the definition of the ${governanceTypeText}.`} value={formFieldState.external_url ?? ''} />
                    </div>
                );
        }
    };

    const manageGovernanceLocation = (versionId: string) => `/${GOVERNANCE}/${versionId}`;

    return (
        <>
            {createdVersionId && formState.successMessage && <LinkButtonToast variant="success" clearToast={() => setFormState({ isUpdatingGovernance: false })} linkButtonText={props.controlNavigatedFrom ? 'Return to control' : 'Manage versions'} linkButtonTo={props.controlNavigatedFrom ? `${getFrameworkGroupControlURL(props.controlNavigatedFrom.identifier)}#governance` : manageGovernanceLocation(createdVersionId)} text={formState.successMessage} />}
            {formState.failureMessage && <TextToast clearToast={() => setFormState({ isUpdatingGovernance: false })} text={formState.failureMessage} variant="failure" />}
            <>
                <Form noValidate>
                    <div className={styles.formFieldContainer}>
                        <FormFieldText required handleChange={handleChange} formFieldId="title" formFieldLabel="Title" tooltip={`The unique name of the ${governanceTypeText}.`} value={formFieldState.title ?? ''} />
                    </div>
                    <div className={styles.formFieldGroup}>
                        <div className={styles.formFieldContainer}>
                            <FormFieldUserSelect required users={cachedData.users} onUserSelected={handleSelectOwnerChange} formFieldId="owner" selectedUser={formFieldState.owner} formFieldLabel="Owner" tooltip={`The individual responsible for the ${governanceTypeText}.`} />
                        </div>
                        <div className={styles.formFieldContainer}>
                            <FormFieldDatePicker required dateFormat="MM/dd/yyyy" selected={formFieldState.effective_date} handleChange={handleChangeEffectiveDate} formFieldId="effective_date" formFieldLabel="Effective Date" placeholder={'MM/DD/YYYY'} tooltip={`The date on which the ${governanceTypeText} did/will become effective.`} />
                        </div>
                    </div>
                    <div className={styles.formFieldContainer}>
                        <FormFieldSelect required options={GovernanceValuesSelectOptions} handleChange={handleSelectChange} formFieldId="type" selectedOption={formFieldState.type} formFieldLabel="Type" tooltip="The type of governance." />
                    </div>
                    <div className={styles.formFieldContainer}>
                        <FormFieldTextArea required formFieldId="changelog" formFieldLabel="Changelog" value={formFieldState.changelog} handleChange={handleChange} />
                    </div>
                    <div className={styles.contentContainer}>
                        <ModalHeader text="Content" />
                        <div className={styles.formFieldContainer}>
                            <FormFieldSelect formFieldId="content_type" selectedOption={formFieldState.content_type} options={GovernanceContentTypeSelectOptions} handleChange={handleSelectChange} formFieldLabel="Content Type" required tooltip={`The medium by which the ${governanceTypeText} is defined.`} />
                        </div>
                        {governanceUploadFields()}
                    </div>
                </Form>
                <ModalHeader text={`Map ${governanceTypeText} to Controls`} />
                <MultipleControlMapping {...multipleControlMappingProps} />
                <div className={styles.submitButton}>
                    <Button variant="primary" onClick={submitRequest} fontAwesomeImage={ICON_SUBMIT} disabled={createdVersionId !== undefined} isLoading={formState.isUpdatingGovernance} loadingText="Submitting...">
                        Submit
                    </Button>
                </div>
            </>
        </>
    );
};
