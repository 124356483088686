import { Checkbox } from '@mui/material';
import { useState } from 'react';
import { Form } from 'react-bootstrap';

import { DocumentApi } from 'Api/Document/DocumentApi';
import { TPRMApi } from 'Api/TPRM/TPRMApi';
import { Button } from 'Components/Buttons/Buttons';
import { useCachedData } from 'Components/Context/CachedDataContext';
import { FileManagementArea, useFileManagementArea } from 'Components/Files/FileManagementArea';
import { FormFieldDatePicker } from 'Components/FormField/FormFieldDatePicker/FormFieldDatePicker';
import { ChangeEventType, FormFieldSelect } from 'Components/FormField/FormFieldSelect/FormFieldSelect';
import { FormFieldText } from 'Components/FormField/FormFieldText/FormFieldText';
import { FormFieldTextArea } from 'Components/FormField/FormFieldTextArea/FormFieldTextArea';
import { Text } from 'Components/Text/Text';
import { LinkButtonToast, TextToast } from 'Components/Toast/Toast';
import { FormFieldTooltip } from 'Components/Tooltips/FormFieldTooltip';
import { VisualLabel } from 'Components/VisualLabel/VisualLabel';
import { ICON_ADD_CREATE } from 'Config/Icons';
import { FOLDERS, THIRD_PARTIES, TPRM } from 'Config/Paths';
import { iso8601ToJsDate, jsDateToIso8601 } from 'Helpers/DateTimeUtils/DateTimeUtils';
import { UserNameFormat, getUserNameFromSubject } from 'Helpers/UserUtils';
import { ValidationError } from 'Models/ErrorTypes';
import { CreateOrUpdateFolderRequest, FolderVersion, ThirdPartyResponse } from 'Models/TPRM';
import { OptionType } from 'Models/Types/GlobalType';

import styles from './ManageFolderForm.module.css';
import { TprmFolderPageType } from '../ManageFolders/ManageFolders';

export interface ManageFolderFormProps {
    documentApi: DocumentApi;
    folderTypeOptions: OptionType[];
    pageType: TprmFolderPageType;
    thirdParty: ThirdPartyResponse;
    tprmApi: TPRMApi;

    currentVersion?: FolderVersion;
}

interface FormFieldState {
    name: string;
    type: string;
    third_party_manager_notifications_enabled: boolean;
    effectiveDate?: Date;
    expirationDate?: Date;
    comments?: string;
}

export const ManageFolderForm = (props: ManageFolderFormProps) => {
    const cachedData = useCachedData();
    const [submitRequestWithManagedFiles, fileManagementHookValues] = useFileManagementArea(props.documentApi, props.currentVersion?.files ?? []);
    const [isSaving, setIsSaving] = useState(false);
    const [isSaved, setIsSaved] = useState(false);
    const [folderId, setFolderId] = useState<string | undefined>(props.currentVersion?.id);
    const [toastErrorMessage, setToastErrorMessage] = useState<string>();
    const [toastSuccessMessage, setToastSuccessMessage] = useState<string>();
    const [formFieldsState, setFormFieldsState] = useState<FormFieldState>({
        name: props.currentVersion?.name ?? '',
        type: props.currentVersion?.type ?? '',
        third_party_manager_notifications_enabled: props.currentVersion?.vendor_manager_notifications_enabled ?? false,
        comments: props.currentVersion?.comments,
        effectiveDate: props.currentVersion?.effective_date ? iso8601ToJsDate(props.currentVersion.effective_date) : undefined,
        expirationDate: props.currentVersion?.expiration_date ? iso8601ToJsDate(props.currentVersion.expiration_date) : undefined,
    });

    const validateFormInput = () => {
        if (formFieldsState.name.length === 0) {
            throw new ValidationError('Name is required.');
        }
        if (!formFieldsState.type) {
            throw new ValidationError('Type is required.');
        }
    };

    const folderRequest = async (): Promise<void> => {
        setToastErrorMessage(undefined);
        setToastSuccessMessage(undefined);
        setIsSaving(true);

        try {
            validateFormInput();

            await submitRequestWithManagedFiles(async (fileUpdates) => {
                const request: CreateOrUpdateFolderRequest = {
                    name: formFieldsState.name,
                    type: formFieldsState.type,
                    file_updates: fileUpdates,
                    third_party_manager_notifications_enabled: formFieldsState.third_party_manager_notifications_enabled,
                    effective_date: formFieldsState.effectiveDate ? jsDateToIso8601(formFieldsState.effectiveDate) : undefined,
                    expiration_date: formFieldsState.expirationDate ? jsDateToIso8601(formFieldsState.expirationDate) : undefined,
                    comments: formFieldsState.comments,
                };

                if (props.currentVersion) {
                    if (props.pageType === TprmFolderPageType.ADD_VERSION) {
                        await props.tprmApi.createNewFolderVersion(props.thirdParty.id, props.currentVersion.id, request);
                    } else {
                        await props.tprmApi.manageFolder(props.thirdParty.id, props.currentVersion.id, request);
                    }
                } else {
                    const createNewFolderResponse = await props.tprmApi.createNewFolder(props.thirdParty.id, request);
                    setFolderId(createNewFolderResponse.data);
                }
            });

            setIsSaved(true);
            setToastSuccessMessage('Folder saved.');
        } catch (error) {
            setToastErrorMessage(error.message);
        } finally {
            setIsSaving(false);
        }
    };

    const handleChange = (event: React.FormEvent<HTMLInputElement>): void => {
        event.preventDefault();
        setFormFieldsState({ ...formFieldsState, [event.currentTarget.name]: event.currentTarget.value });
    };

    const handleSelectChange = (value: ChangeEventType, formFieldId: string): void => {
        setFormFieldsState({ ...formFieldsState, [formFieldId]: value });
    };

    const handleChangeEffectiveDate = (date: Date | undefined): void => {
        setFormFieldsState({ ...formFieldsState, effectiveDate: date });
    };

    const handleChangeExpirationDate = (date: Date | undefined): void => {
        const notificationsEnabled = date ? formFieldsState.third_party_manager_notifications_enabled : false;
        setFormFieldsState({ ...formFieldsState, expirationDate: date, third_party_manager_notifications_enabled: notificationsEnabled });
    };

    const handleCheckSelect = (event: React.FormEvent<HTMLInputElement>) => {
        setFormFieldsState({ ...formFieldsState, third_party_manager_notifications_enabled: event.currentTarget.checked });
    };

    return (
        <>
            {toastSuccessMessage && <LinkButtonToast variant="success" clearToast={() => setToastSuccessMessage(undefined)} text={toastSuccessMessage} linkButtonText={'View Folder'} linkButtonTo={`/${TPRM}/${THIRD_PARTIES}/${props.thirdParty.id}/${FOLDERS}/${folderId}`} />}
            {toastErrorMessage && <TextToast variant="failure" clearToast={() => setToastErrorMessage(undefined)} text={toastErrorMessage} />}
            <Form>
                <div className={styles.formFieldsContainer}>
                    <div className={styles.fieldGroupContainer}>
                        <div className={styles.fieldContainer}>
                            <FormFieldText formFieldLabel="Name" formFieldId="name" handleChange={handleChange} value={formFieldsState.name} required />
                        </div>
                        <div className={styles.fieldContainer}>
                            <FormFieldSelect formFieldLabel="Type" formFieldId="type" options={props.folderTypeOptions} handleChange={handleSelectChange} required selectedOption={formFieldsState.type} />
                        </div>
                    </div>
                    <div className={styles.fieldGroupContainer}>
                        <div className={styles.fieldContainer}>
                            <FormFieldDatePicker formFieldLabel="Effective Date" formFieldId="effectiveDate" dateFormat="MM/dd/yyyy" placeholder={'MM/DD/YYYY'} selected={formFieldsState.effectiveDate} handleChange={handleChangeEffectiveDate} />
                        </div>
                        <div className={styles.fieldContainer}>
                            <FormFieldDatePicker formFieldLabel="Expiration Date" formFieldId="expirationDate" dateFormat="MM/dd/yyyy" placeholder={'MM/DD/YYYY'} handleChange={handleChangeExpirationDate} selected={formFieldsState.expirationDate} />
                        </div>
                    </div>
                    <div className={styles.dragAndDrop}>
                        <FileManagementArea disabled={false} documentApi={props.documentApi} fileManagementHookValues={fileManagementHookValues} />
                    </div>
                    <div className={styles.fieldContainer}>
                        <FormFieldTextArea formFieldId="comments" formFieldLabel="Comments" handleChange={handleChange} value={formFieldsState.comments} />
                    </div>
                </div>
                <VisualLabel>Notifications</VisualLabel>
                <div className={styles.checkboxContainer}>
                    <Checkbox checked={formFieldsState.third_party_manager_notifications_enabled} color="default" onChange={handleCheckSelect} disabled={!formFieldsState.expirationDate} />
                    <Text noStyles color={formFieldsState.expirationDate ? 'blue' : 'darkGray'}>{`Third-Party Manager - ${getUserNameFromSubject(props.thirdParty.vendor_manager_user_id, cachedData.users, UserNameFormat.FIRST_SPACE_LAST)}`}</Text>
                    <FormFieldTooltip text={!formFieldsState.expirationDate ? 'Please enter an expiration date to enable notifications.' : 'Third-Party Manager will be notified when expiration date is approaching.'} />
                </div>
                <div className={styles.buttonContainer}>
                    <Button variant="primary" onClick={() => folderRequest()} disabled={isSaved} fontAwesomeImage={ICON_ADD_CREATE} isLoading={isSaving} loadingText="Submitting...">
                        Submit
                    </Button>
                </div>
            </Form>
        </>
    );
};
