import { Checkbox } from '@mui/material';
import { useMemo, useState } from 'react';
import { Alert, Form } from 'react-bootstrap';

import { DocumentApi } from 'Api/Document/DocumentApi';
import { TPRMApi } from 'Api/TPRM/TPRMApi';
import { Button } from 'Components/Buttons/Buttons';
import { FileManagementAreaWithSiblings, FileManagementAreaWithSiblingsHookValues, useFileManagementAreas } from 'Components/Files/FileManagementArea';
import { ChangeEventType, FormFieldSelect } from 'Components/FormField/FormFieldSelect/FormFieldSelect';
import { FormFieldTextArea } from 'Components/FormField/FormFieldTextArea/FormFieldTextArea';
import { RadioButtonGroup } from 'Components/FormField/RadioButtonGroup/RadioButtonGroup';
import { Text } from 'Components/Text/Text';
import { TextToast } from 'Components/Toast/Toast';
import { FormFieldTooltip } from 'Components/Tooltips/FormFieldTooltip';
import { ICON_SAVE, ICON_SUBMIT } from 'Config/Icons';
import { TPRM_IRQ_DISABLED_BECAUSE_AWAITING_ASSESSMENT, TPRM_IRQ_DISABLED_BECAUSE_PERFORMING_DUE_DILIGENCE } from 'Config/Tooltips';
import { InherentRiskQuestionnaireQuestion, InherentRiskQuestionnaireResponse, InherentRiskQuestionnaireSection, RiskRating, RiskRatingPointThresholds, RiskRatingSelectOptions, SaveOrSubmitInherentRiskQuestionnaireRequest, Service, ServiceAssessmentState, ThirdPartyContact, riskRatingAsString } from 'Models/TPRM';

import { ConfirmSubmitIrqModal, ConfirmSubmitIrqModalProps } from '../ConfirmSubmitIrqModal/ConfirmSubmitIrqModal';
import { DDQQuestionCount } from '../InherentRiskQuestionnaire';
import styles from './InherentRiskQuestionnaireForm.module.css';

export enum Modals {
    ClearIRQModal,
    ConfirmSubmitIrqModal,
    None,
}

export interface InherentRiskQuestionnaireFormProps {
    ddqQuestionCount: DDQQuestionCount;
    defaultQuestionnaire: InherentRiskQuestionnaireResponse;
    documentApi: DocumentApi;
    onFormSubmitted: () => Promise<void>;
    serviceResponse: Service;
    tprmApi: TPRMApi;
}

export const InherentRiskQuestionnaireForm = (props: InherentRiskQuestionnaireFormProps) => {
    const [displayedModal, setDisplayedModal] = useState<Modals>();
    const [toastMessage, setToastMessage] = useState<{ message: string; type: 'success' | 'failure' }>();
    const [requestState, setRequestState] = useState<'idle' | 'saving' | 'submitting'>('idle');
    const [selectedRiskRating, setSelectedRiskRating] = useState<RiskRating>(props.defaultQuestionnaire.inherent_risk_score as RiskRating);
    const [questionnaire, setQuestionnaire] = useState<InherentRiskQuestionnaireResponse>(props.defaultQuestionnaire);

    const [submitRequestWithManagedFiles, fileManagementHookValues] = useFileManagementAreas(
        props.documentApi,
        props.defaultQuestionnaire.sections.map((section) => section.files)
    );

    const formDisabled = requestState !== 'idle' || props.serviceResponse.assessment_workflow_data.state !== ServiceAssessmentState.EVALUATING_INHERENT_RISK;

    const recommendedRiskRating = useMemo(() => {
        return questionnaire ? calculateRecommendedInherentRiskScore(questionnaire.sections, questionnaire.thresholds) : undefined;
    }, [questionnaire]);

    const handleSubmitClicked = () => {
        if (!selectedRiskRating) {
            setToastMessage({ type: 'failure', message: 'Select an inherent risk rating before submitting the inherent risk questionnaire.' });
            return;
        }

        const allQuestionsAnswered = questionnaire.sections.every((section) => {
            return !section.is_applicable || section.questions.every((question) => question.selected_answer_index !== undefined);
        });

        if (!allQuestionsAnswered) {
            setToastMessage({ type: 'failure', message: 'All questions must be answered before submitting the inherent risk questionnaire.' });
            return;
        }

        setDisplayedModal(Modals.ConfirmSubmitIrqModal);
    };

    /**
     * Set the inherent risk rating for the Vendor Service.
     * @param submit Indicates whether the IRQ should be saved as in-progress or submitted as complete.
     * @param vendorContacts A list of Vendor Service contacts to notify about the Due Diligence Questionnaire (DDQ).
     */
    const saveQuestionnaire = async (submit: boolean, vendorContacts: ThirdPartyContact[]): Promise<void> => {
        setRequestState(submit ? 'submitting' : 'saving');
        setToastMessage(undefined);

        try {
            await submitRequestWithManagedFiles(async (fileInfoBySection) => {
                const request: SaveOrSubmitInherentRiskQuestionnaireRequest = {
                    submit: submit,
                    inherent_risk_score: selectedRiskRating,
                    section_selections: questionnaire.sections.map((section, sectionIndex) => {
                        // If a section does not apply, no responses are included, as the backend will ignore them (i.e., not persist them) even if included.
                        return {
                            is_applicable: section.is_applicable,
                            answers: section.is_applicable ? section.questions.map((question) => question.selected_answer_index ?? null) : undefined,
                            text: section.is_applicable ? section.text : undefined,
                            // If a section is not applicable, don't send file updates. Conceptually, the behavior of the app will be as if the frontend specified to delete each existing file in the section, but the backend will handle this automatically.
                            file_updates: section.is_applicable ? fileInfoBySection[sectionIndex] : undefined,
                        };
                    }),
                    third_party_contacts: vendorContacts,
                };

                await props.tprmApi.saveInherentRiskQuestionnaire(props.serviceResponse.vendor_id, props.serviceResponse.id, request);
            });

            await props.onFormSubmitted();
            setToastMessage({ message: `Inherent risk questionnaire ${submit ? 'submitted' : 'saved'}.`, type: 'success' });
        } catch (error) {
            console.log(error);
            setToastMessage({ message: error.message, type: 'failure' });
        } finally {
            setRequestState('idle');
        }
    };

    const handleChangeSelectedRiskRating = (value: ChangeEventType): void => {
        setSelectedRiskRating(value as RiskRating);
    };

    const onConfirmBeginRiskWorkflow = (vendorContacts: ThirdPartyContact[]) => {
        setDisplayedModal(Modals.None);
        saveQuestionnaire(true, vendorContacts);
    };

    const confirmSubmitIrqModalProps: ConfirmSubmitIrqModalProps = {
        inherentRiskRating: selectedRiskRating!,
        hideModal: () => setDisplayedModal(Modals.None),
        service: props.serviceResponse,
        onConfirm: onConfirmBeginRiskWorkflow,
        hasValidDDQConfiguration: selectedRiskRating !== RiskRating.INACTIVE && props.ddqQuestionCount[selectedRiskRating!] > 0 ? true : false,
    };

    const alertForDisabledIrq = (() => {
        switch (props.serviceResponse.assessment_workflow_data.state) {
            case ServiceAssessmentState.AWAITING_ASSESSMENT:
                return (
                    <Alert variant="warning">
                        <div className={styles.disabledIrqAlert}>
                            The inherent risk questionnaire has been submitted and cannot be edited at this time.
                            <FormFieldTooltip text={TPRM_IRQ_DISABLED_BECAUSE_AWAITING_ASSESSMENT} />
                        </div>
                    </Alert>
                );
            case ServiceAssessmentState.PERFORMING_DUE_DILIGENCE:
                return (
                    <Alert variant="warning">
                        <div className={styles.disabledIrqAlert}>
                            The inherent risk questionnaire has been submitted and cannot be edited at this time.
                            <FormFieldTooltip text={TPRM_IRQ_DISABLED_BECAUSE_PERFORMING_DUE_DILIGENCE} />
                        </div>
                    </Alert>
                );
            default:
                return undefined;
        }
    })();

    return (
        <>
            {toastMessage && <TextToast variant={toastMessage.type} clearToast={() => setToastMessage(undefined)} text={toastMessage.message} />}
            {displayedModal === Modals.ConfirmSubmitIrqModal && <ConfirmSubmitIrqModal {...confirmSubmitIrqModalProps} />}
            <Form noValidate>
                <div className={styles.sections}>
                    {alertForDisabledIrq}
                    {questionnaire.sections.map((section, sectionIndex) => {
                        return (
                            <SectionDisplay
                                key={section.name}
                                fileManagementHookValues={fileManagementHookValues}
                                disabled={formDisabled}
                                documentApi={props.documentApi}
                                section={section}
                                sectionIndex={sectionIndex}
                                onUpdateSection={(updatedSection) => {
                                    const newSections = [...questionnaire.sections];
                                    newSections[sectionIndex] = updatedSection;
                                    setQuestionnaire({ ...questionnaire, sections: newSections });
                                }}
                            />
                        );
                    })}
                </div>
                <fieldset disabled={formDisabled}>
                    <div className={styles.sectionHeader}>
                        <div className={styles.sectionNameAndUserSelectContainer}>
                            <Text variant="Header2" noStyles>
                                Overall
                            </Text>
                        </div>
                        <hr />
                    </div>
                    <div className={styles.questions}>
                        <FormFieldSelect formFieldId="selectedRiskRating" formFieldLabel="Inherent Risk Rating" options={RiskRatingSelectOptions} selectedOption={selectedRiskRating} handleChange={handleChangeSelectedRiskRating} required badge={recommendedRiskRating ? `Recommendation: ${riskRatingAsString(recommendedRiskRating)}` : undefined} />
                    </div>
                    <div className={styles.buttonsContainer}>
                        <Button fontAwesomeImage={ICON_SAVE} variant="secondary" isLoading={requestState === 'saving'} loadingText="Saving..." onClick={() => saveQuestionnaire(false, [])}>
                            Save
                        </Button>
                        <Button fontAwesomeImage={ICON_SUBMIT} variant="primary" isLoading={requestState === 'submitting'} loadingText="Submitting..." onClick={handleSubmitClicked}>
                            Submit
                        </Button>
                    </div>
                </fieldset>
            </Form>
        </>
    );
};

interface SectionDisplayProps {
    disabled: boolean;
    documentApi: DocumentApi;
    fileManagementHookValues: FileManagementAreaWithSiblingsHookValues;
    onUpdateSection: (updatedSection: InherentRiskQuestionnaireSection) => void;
    section: InherentRiskQuestionnaireSection;
    sectionIndex: number;
}

const SectionDisplay = (props: SectionDisplayProps) => {
    return (
        <div className={styles.section}>
            <div className={styles.sectionHeader}>
                <div className={styles.sectionNameAndCheckboxContainer}>
                    <Text variant="Header2" noStyles>
                        {props.section.name}
                    </Text>
                    <div className={styles.checkboxContainer}>
                        <Checkbox
                            disabled={props.disabled}
                            checked={props.section.is_applicable}
                            color="default"
                            onChange={(event: React.FormEvent<HTMLInputElement>) => {
                                props.onUpdateSection({ ...props.section, is_applicable: event.currentTarget.checked });
                            }}
                        />
                        <Text noStyles>Relevant to this service</Text>
                        <FormFieldTooltip text="Indicates whether the questions in this section are applicable to the third-party service. If unchecked, responses in this section will not be required for this service." />
                    </div>
                </div>
                <hr />
            </div>
            {props.section.is_applicable && (
                <>
                    {props.section.questions.map((question, questionIndex) => {
                        const questionDisplayProps: QuestionDisplayProps = {
                            question,
                            section: props.section,
                            sectionIndex: props.sectionIndex,
                            questionIndex,
                            onUpdateQuestion: (updatedQuestion) => {
                                props.onUpdateSection({ ...props.section, questions: props.section.questions.map((q, i) => (i === questionIndex ? updatedQuestion : q)) });
                            },
                            disabled: props.disabled,
                        };
                        return <QuestionDisplay key={question.text} {...questionDisplayProps} />;
                    })}
                    <div className={styles.textEvidenceContainer}>
                        <FormFieldTextArea
                            formFieldId={`${props.section.name} additional information`}
                            formFieldLabel="Additional Information"
                            handleChange={(event: React.FormEvent<HTMLInputElement>) => {
                                props.onUpdateSection({ ...props.section, text: event.currentTarget.value });
                            }}
                            value={props.section.text}
                            disabled={props.disabled}
                        />
                    </div>
                    <div className={styles.formFieldContainer}>
                        <FileManagementAreaWithSiblings siblingIndex={props.sectionIndex} disabled={props.disabled} documentApi={props.documentApi} fileManagementHookValues={props.fileManagementHookValues} />
                    </div>
                </>
            )}
        </div>
    );
};

interface QuestionDisplayProps {
    question: InherentRiskQuestionnaireQuestion;
    section: InherentRiskQuestionnaireSection;
    sectionIndex: number;
    questionIndex: number;
    onUpdateQuestion: (updatedQuestion: InherentRiskQuestionnaireQuestion) => void;
    disabled: boolean;
}

const QuestionDisplay = (props: QuestionDisplayProps) => {
    return (
        <div className={styles.question}>
            <Text variant="Text2">{`${props.questionIndex + 1}. ${props.question.text}`}</Text>
            <RadioButtonGroup
                clearable
                defaultValue={props.question.selected_answer_index}
                disabled={props.disabled}
                formFieldLabelId={`section${props.sectionIndex}Question${props.questionIndex}AnswerLabel`}
                formFieldLabel="Answer"
                options={props.question.answers.map((answer, index) => ({
                    label: answer.text,
                    value: index,
                }))}
                onChange={(selectedIndex) => props.onUpdateQuestion({ ...props.question, selected_answer_index: selectedIndex })}
            />
        </div>
    );
};

const calculateRecommendedInherentRiskScore = (sections: InherentRiskQuestionnaireSection[], thresholds: RiskRatingPointThresholds): RiskRating | undefined => {
    let totalPoints = 0;
    for (const section of sections) {
        if (!section.is_applicable) {
            continue;
        }

        for (const question of section.questions) {
            if (question.selected_answer_index === undefined) {
                return undefined;
            }

            totalPoints += question.answers[question.selected_answer_index].points;
        }
    }

    if (totalPoints <= thresholds.low) {
        return RiskRating.LOW;
    } else if (totalPoints <= thresholds.low_moderate) {
        return RiskRating.LOW_MODERATE;
    } else if (totalPoints <= thresholds.moderate) {
        return RiskRating.MODERATE;
    } else if (totalPoints <= thresholds.moderate_high) {
        return RiskRating.MODERATE_HIGH;
    } else {
        return RiskRating.HIGH;
    }
};
