/**
 * The hooks and components in this file provide consistent/standardized ways for users to manage files in a form that corresponds to some entity (for example, the files attached to an issue or exception).
 * Terminology: A "file management area" is an area of the page where the user can upload new files and view/download/delete existing files. It uses the `FileDragAndDrop` component under the hood, which may or may not be in "single file" mode.
 *
 * The file state for a file management area is managed by either the `useFileManagementArea` hook or the `useFileManagementAreas` hook. The area is rendered by either the `FileManagementArea` component or the `FileManagementAreaWithSiblings` component.
 *
 * How to choose the right hook and component(s) to use:
 * * The entity has a single set of files associated with it (issues, exceptions, etc.): Use `useFileManagementArea` and `FileManagementArea`.
 * * The entity has multiple sets/sections of files associated with it (IRQ with multiple sections, DDQ control with multiple questions, etc.): Use `useFileManagementAreas` and one instance of `FileManagementAreaWithSiblings` per independent section.
 *
 * NOTE: IDs for file drag-and-drop inputs are generated automatically. Do not use an ID equal to or beginning with `fileManagementArea` for any element on the page.
 *
 * TODO: File validation should occur when dropping in new files, not during the request submission process.
 */

import { ListItemIcon } from '@mui/material';
import { type JSX, useState } from 'react';
import { useDropzone } from 'react-dropzone';

import { DocumentApi } from 'Api/Document/DocumentApi';
import { IconButton } from 'Components/Buttons/IconButton';
import { OverflowItem, OverflowMenu } from 'Components/Buttons/OverflowMenu';
import { Colors } from 'Components/Colors';
import { Table, TableBody, TableCell, TableOverflowCell, TableRow } from 'Components/Table/Table/Table';
import { Text } from 'Components/Text/Text';
import { FileStateToolTip } from 'Components/Tooltips/FileStateToolTip';
import { VisualLabel } from 'Components/VisualLabel/VisualLabel';
import { ICON_DELETE_REMOVE, ICON_DOWNLOAD } from 'Config/Icons';
import { downloadDocument } from 'Helpers/FileUtils';
import { validateFileForUpload } from 'Helpers/InputValidation';
import { FileState, FileUpdates, SignedUploadResponse, UploadedFile } from 'Models/Files';

import styles from './FileManagementArea.module.css';

export const DEFAULT_LABEL_FOR_FILE_DRAG_AND_DROP_SINGLE_MODE = 'File';
export const DEFAULT_LABEL_FOR_EXISTING_FILES_SINGLE_MODE = 'Existing File';
export const DEFAULT_LABEL_FOR_FILE_DRAG_AND_DROP_MULTIPLE_MODE = 'Files';
export const DEFAULT_LABEL_FOR_EXISTING_FILES_MULTIPLE_MODE = 'Existing Files';

const FILE_MANAGEMENT_AREA_DRAG_AND_DROP_INPUT_ID = 'fileManagementArea';

/**
 * Generates a unique ID for one of many `FileManagementAreaWithSiblings` on a page, based on the index of the area on the page. This ID is used both to associate the drag-and-drop input element with its form field label, and to facilitate the management of multiple areas' state within the corresponding hook.
 *
 * Why don't we allow the developer to provide their own IDs?
 * * The main reason is that it's difficult to get right. The ID must be a valid ID for an HTML element, which means no whitespace characters (or else bizarre, difficult-to-debug behavior occurs). In practice, the unique ID a developer would choose would be likely to have whitespace, as it might be something like the user-defined section name for an IRQ section.
 * * * We could allow the user to supply IDs, and then sanitize them, but that would both obscure the resulting IDs and still potentially introduce conflicts. (For example, if the user has defined "CyberSecurity" and "Cyber Security" sections for the IRQ.)
 * * Secondarily, it's easier for developers this way. The only real con is that there's technically the possibility that a developer gives some other element the same ID as a generated one, but that's not really a new problem when any component the developer is using could be assigning IDs.
 */
const fileManagementAreaWithSiblingsDragAndDropInputId = (index: number) => `fileManagementArea${index}`;

type ManagedFilesForArea = { existingFiles: UploadedFile[]; existingFilesToDelete: string[]; newFiles: File[] };
type ManagedFilesForAllAreas = ManagedFilesForArea[];

export enum FileManagementAreaRestriction {
    NO_RESTRICTION,
    /**
     * An error will be thrown if:
     * * the user submits the form with neither an existing file nor a new file.
     * * the user submits the form with multiple (new or existing) files. This _should_ be impossible, since `FileDragAndDropSingleMode` prevents multiple files from being selected.
     * The file drag-and-drop input will enforce only a single file being associated with the entity. Look at `FileDragAndDrop` vs. `FileDragAndDropSingleMode` for more details.
     */
    EXACTLY_ONE_FILE,
    /**
     * An error will be thrown if the user submits the form with multiple (new or existing) files. This _should_ be impossible, since `FileDragAndDropSingleMode` prevents multiple files from being selected.
     * The file drag-and-drop input will enforce only a single file being associated with the entity. Look at `FileDragAndDrop` vs. `FileDragAndDropSingleMode` for more details.
     */
    UP_TO_ONE_FILE,
}

/**
 * Contains current file state for a `FileManagementArea`.
 * You should not need to use anything from an object that conforms to this interface. Just pass the object directly to `FileManagementArea`.
 */
export interface FileManagementAreaHookValues {
    existingFiles: UploadedFile[];
    newFiles: File[];
    onAddNewFiles: (files: File[]) => void;
    onRemoveNewFile: (file: File) => void;
    onRemoveExistingFile: (file: UploadedFile) => void;
    restriction: FileManagementAreaRestriction;
}

/**
 * Provides logic for a `FileManagementArea` that manages files associated with some entity (an issue, exception, etc.).
 * NOTE: You usually don't need to specify `SubmitApiRequestReturnType` (if you don't define it, it will default to `unknown`). Define it when the request for submitting a form returns a response that you need to make use of.
 *
 * @param documentApi The API used to upload files to S3 when the form is submitted.
 * @param existingFilesDefault The files currently associated with the entity at page load time.
 * @param restriction The restriction on the number of files that can be associated with the entity. Defaults to `FileManagementAreaRestriction.NO_RESTRICTION`.
 * @returns Values to be passed directly into the corresponding `FileManagementArea`, as well as a function that handles the "S3" dance when it comes time to submit the form.
 */
export const useFileManagementArea = <SubmitApiRequestReturnType,>(documentApi: DocumentApi, existingFilesDefault: UploadedFile[], restriction?: FileManagementAreaRestriction): readonly [(submitApiRequest: (fileUpdates: FileUpdates) => Promise<SubmitApiRequestReturnType>) => Promise<SubmitApiRequestReturnType>, FileManagementAreaHookValues] => {
    const [managedFiles, setManagedFiles] = useState<ManagedFilesForArea>({ existingFiles: existingFilesDefault, existingFilesToDelete: [], newFiles: [] });

    const _restriction = restriction ?? FileManagementAreaRestriction.NO_RESTRICTION;

    const onAddNewFiles = (newFiles: File[]) => {
        setManagedFiles((current) => {
            const currentFileNames = [...current.newFiles.map((file) => file.name), ...current.existingFiles.map((file) => file.filename)];
            const validNewFiles = newFiles.filter((file) => !currentFileNames.includes(file.name));
            const sortedValidFiles = [...validNewFiles, ...current.newFiles].sort((a, b) => {
                return a.name.localeCompare(b.name);
            });

            return { ...current, newFiles: sortedValidFiles };
        });
    };

    const onRemoveNewFile = (fileToRemove: File) => {
        setManagedFiles((current) => ({ ...current, newFiles: current.newFiles.filter((file) => file.name !== fileToRemove.name) }));
    };

    const onRemoveExistingFile = (fileToRemove: UploadedFile) => {
        setManagedFiles((current) => ({ ...current, existingFiles: current.existingFiles.filter((file) => file.file_id !== fileToRemove.file_id), existingFilesToDelete: [...current.existingFilesToDelete, fileToRemove.file_id] }));
    };

    const submitRequestWithManagedFiles = async (submitApiRequest: (fileUpdates: FileUpdates) => Promise<SubmitApiRequestReturnType>) => {
        const { apiRequestReturnValue, newlyUploadedFilesByArea } = await submitRequestWithFiles(documentApi, [managedFiles], (fileUpdates) => submitApiRequest(fileUpdates[0]), _restriction);

        setManagedFiles((current) => {
            const newExistingFiles = [...current.existingFiles, ...newlyUploadedFilesByArea[0]];
            return { existingFiles: newExistingFiles, existingFilesToDelete: [], newFiles: [] };
        });

        return apiRequestReturnValue;
    };

    return [submitRequestWithManagedFiles, { existingFiles: managedFiles.existingFiles, newFiles: managedFiles.newFiles, onAddNewFiles, onRemoveNewFile, onRemoveExistingFile, restriction: _restriction }];
};

/**
 * Contains current file state for a `FileManagementAreaWithSiblings`.
 * You should not need to use anything from an object that conforms to this interface. Just pass the object directly to `FileManagementAreaWithSiblings`.
 */
export interface FileManagementAreaWithSiblingsHookValues {
    managedFilesForAllAreas: ManagedFilesForAllAreas;
    onAddNewFiles: (fileManagementAreaIndex: number, files: File[]) => void;
    onRemoveNewFile: (fileManagementAreaIndex: number, file: File) => void;
    onRemoveExistingFile: (fileManagementAreaIndex: number, file: UploadedFile) => void;
    restriction: FileManagementAreaRestriction;
}

/**
 * Provides logic for multiple `FileManagementAreaWithSiblings`s that manage independent sets of files associated with some entity (an IRQ with multiple sections, a DDQ control with multiple questions, etc.).
 * NOTE: You usually don't need to specify `SubmitApiRequestReturnType` (if you don't define it, it will default to `unknown`). Define it when the request for submitting a form returns a response that you need to make use of.
 *
 * @param documentApi The API used to upload files to S3 when the form is submitted.
 * @param existingFilesDefault The files currently associated with the entity at page load time.
 * @param restriction The restriction on the number of files that can be associated with the entity. Defaults to `FileManagementAreaRestriction.NO_RESTRICTION`. The restriction applies to each file area. For example, if the restriction is `FileManagementAreaRestriction.EXACTLY_ONE_FILE`, each area must contain exactly one file.
 * @returns Values to be passed directly into each corresponding `FileManagementAreaWithSiblings`, as well as a function that handles the "S3" dance when it comes time to submit the form.
 */
export const useFileManagementAreas = <SubmitApiRequestReturnType,>(documentApi: DocumentApi, existingFilesDefault: UploadedFile[][], restriction?: FileManagementAreaRestriction): readonly [(submitApiRequest: (fileUpdatesByFileInputId: FileUpdates[]) => Promise<SubmitApiRequestReturnType>) => Promise<SubmitApiRequestReturnType>, FileManagementAreaWithSiblingsHookValues] => {
    const [managedFilesForAllAreas, setManagedFilesForAllAreas] = useState<ManagedFilesForAllAreas>(existingFilesDefault.map((existingFiles) => ({ existingFiles, existingFilesToDelete: [], newFiles: [] })));

    const _restriction = restriction ?? FileManagementAreaRestriction.NO_RESTRICTION;

    const onAddNewFiles = (fileManagementAreaIndex: number, newFiles: File[]) => {
        setManagedFilesForAllAreas((current) => {
            const newManagedFilesForAllAreas = [...current];
            const currentManagedFilesForArea = newManagedFilesForAllAreas[fileManagementAreaIndex];
            const currentFileNames = [...currentManagedFilesForArea.newFiles.map((file) => file.name), ...currentManagedFilesForArea.existingFiles.map((file) => file.filename)];

            const validNewFiles = newFiles.filter((file) => !currentFileNames.includes(file.name));
            const sortedValidFiles = [...validNewFiles, ...currentManagedFilesForArea.newFiles].sort((a, b) => {
                return a.name.localeCompare(b.name);
            });

            newManagedFilesForAllAreas[fileManagementAreaIndex] = { ...currentManagedFilesForArea, newFiles: sortedValidFiles };
            return newManagedFilesForAllAreas;
        });
    };

    const onRemoveNewFile = (fileManagementAreaIndex: number, fileToRemove: File) => {
        setManagedFilesForAllAreas((current) => {
            const newManagedFilesForAllAreas = [...current];
            const currentManagedFilesForArea = newManagedFilesForAllAreas[fileManagementAreaIndex];
            const filteredFiles = currentManagedFilesForArea.newFiles.filter((file) => file.name !== fileToRemove.name);
            newManagedFilesForAllAreas[fileManagementAreaIndex] = { ...currentManagedFilesForArea, newFiles: filteredFiles };
            return newManagedFilesForAllAreas;
        });
    };

    const onRemoveExistingFile = (fileManagementAreaIndex: number, fileToRemove: UploadedFile) => {
        setManagedFilesForAllAreas((current) => {
            const newManagedFilesForAllAreas = [...current];
            const currentManagedFilesForArea = newManagedFilesForAllAreas[fileManagementAreaIndex];
            const filteredFiles = currentManagedFilesForArea.existingFiles.filter((file) => file.file_id !== fileToRemove.file_id);
            const existingFilesToDelete = [...currentManagedFilesForArea.existingFilesToDelete, fileToRemove.file_id];
            newManagedFilesForAllAreas[fileManagementAreaIndex] = { ...currentManagedFilesForArea, existingFiles: filteredFiles, existingFilesToDelete };
            return newManagedFilesForAllAreas;
        });
    };

    const submitRequestWithManagedFiles = async (submitApiRequest: (fileUpdatesByFileInputId: FileUpdates[]) => Promise<SubmitApiRequestReturnType>) => {
        const { apiRequestReturnValue, newlyUploadedFilesByArea } = await submitRequestWithFiles(documentApi, managedFilesForAllAreas, submitApiRequest, _restriction);

        setManagedFilesForAllAreas((current) => {
            return current.map((area, index) => {
                const newExistingFiles = [...area.existingFiles, ...newlyUploadedFilesByArea[index]];
                return { existingFiles: newExistingFiles, existingFilesToDelete: [], newFiles: [] };
            });
        });

        return apiRequestReturnValue;
    };

    return [submitRequestWithManagedFiles, { managedFilesForAllAreas: managedFilesForAllAreas, onAddNewFiles, onRemoveNewFile, onRemoveExistingFile, restriction: _restriction }];
};

export interface FileManagementAreaProps {
    /**
     * If true, the user will not be able to interact with the area except to download existing files.
     * NOTE: If the entire enclosing form is disabled (for example, via `fieldset`), the minor bug of being unable to download existing files during the disabled state will occur. We could improve this by implementing our own form component that automatically handles tricky details like disabling some, but not all, form interactions at the proper times.
     */
    disabled: boolean;
    /**
     * Used to download existing files.
     */
    documentApi: DocumentApi;
    /**
     * Values obtained directly from the corresponding `useFileManagementArea` hook.
     */
    fileManagementHookValues: FileManagementAreaHookValues;

    /**
     * The label for the table of existing files. If not provided, "Existing File" or "Existing Files" will be used, based on whether `singleFileMode` is used.
     */
    existingFilesFormFieldLabel?: string;
    /**
     * The label for the file drag-and-drop input element. If not provided, "File" or "Files" will be used, based on whether `singleFileMode` is used.
     */
    fileDragAndDropFormFieldLabel?: string;
    /**
     * A tooltip that will be displayed above the file drag-and-drop input, horizontally aligned with the form field label.
     */
    tooltip?: JSX.Element;
}

/**
 * Renders a group of components that manage files associated with some entity (an issue, exception, etc.).
 * **Must** be used with the `useFileManagementArea` hook.
 */
export const FileManagementArea = (props: FileManagementAreaProps) => {
    const fileManagementAreaProps: InternalFileManagementAreaProps = {
        disabled: props.disabled,
        documentApi: props.documentApi,
        existingFiles: props.fileManagementHookValues.existingFiles,
        onAddNewFiles: props.fileManagementHookValues.onAddNewFiles,
        onRemoveNewFile: props.fileManagementHookValues.onRemoveNewFile,
        onRemoveExistingFile: props.fileManagementHookValues.onRemoveExistingFile,
        newFiles: props.fileManagementHookValues.newFiles,
        existingFilesFormFieldLabel: props.existingFilesFormFieldLabel,
        inputId: FILE_MANAGEMENT_AREA_DRAG_AND_DROP_INPUT_ID,
        fileDragAndDropFormFieldLabel: props.fileDragAndDropFormFieldLabel,
        singleFileMode: props.fileManagementHookValues.restriction === FileManagementAreaRestriction.EXACTLY_ONE_FILE || props.fileManagementHookValues.restriction === FileManagementAreaRestriction.UP_TO_ONE_FILE,
        tooltip: props.tooltip,
    };

    return <InternalFileManagementArea {...fileManagementAreaProps} />;
};

export interface FileManagementAreaWithSiblingsProps {
    /**
     * If true, the user will not be able to interact with the area except to download existing files.
     * NOTE: If the entire enclosing form is disabled (for example, via `fieldset`), the minor bug of being unable to download existing files during the disabled state will occur. We could improve this by implementing our own form component that automatically handles tricky details like disabling some, but not all, form interactions at the proper times.
     */
    disabled: boolean;
    /**
     * Used to download existing files.
     */
    documentApi: DocumentApi;
    /**
     * Values obtained directly from the corresponding `useFileManagementAreas` hook.
     */
    fileManagementHookValues: FileManagementAreaWithSiblingsHookValues;
    /**
     * The index of the area in relation to all file management areas being managed by the `useFileManagementAreas` hook. For example, if there are two `FileManagementAreaWithSiblings`s on the page, the first one should have a `siblingIndex` of 0, and the second one should have a `siblingIndex` of 1.
     */
    siblingIndex: number;

    /**
     * The label for the table of existing files. If not provided, "Existing File" or "Existing Files" will be used, based on whether `singleFileMode` is used.
     */
    existingFilesFormFieldLabel?: string;
    /**
     * The label for the file drag-and-drop input element. If not provided, "File" or "Files" will be used, based on whether `singleFileMode` is used.
     */
    fileDragAndDropFormFieldLabel?: string;
    /**
     * A tooltip that will be displayed above the file drag-and-drop input, horizontally aligned with the form field label.
     */
    tooltip?: JSX.Element;
}

/**
 * Renders a group of components that manage one of many independent sets of files associated with some entity (an IRQ with multiple sections, a DDQ control with multiple questions, etc.).
 * **Must** be used with the `useFileManagementAreas` hook.
 */
export const FileManagementAreaWithSiblings = (props: FileManagementAreaWithSiblingsProps) => {
    const fileManagementAreaProps: InternalFileManagementAreaProps = {
        disabled: props.disabled,
        documentApi: props.documentApi,
        existingFiles: props.fileManagementHookValues.managedFilesForAllAreas[props.siblingIndex].existingFiles,
        onAddNewFiles: (files) => props.fileManagementHookValues.onAddNewFiles(props.siblingIndex, files),
        onRemoveNewFile: (file) => props.fileManagementHookValues.onRemoveNewFile(props.siblingIndex, file),
        onRemoveExistingFile: (file) => props.fileManagementHookValues.onRemoveExistingFile(props.siblingIndex, file),
        newFiles: props.fileManagementHookValues.managedFilesForAllAreas[props.siblingIndex].newFiles,
        existingFilesFormFieldLabel: props.existingFilesFormFieldLabel,
        inputId: fileManagementAreaWithSiblingsDragAndDropInputId(props.siblingIndex),
        fileDragAndDropFormFieldLabel: props.fileDragAndDropFormFieldLabel,
        singleFileMode: props.fileManagementHookValues.restriction === FileManagementAreaRestriction.EXACTLY_ONE_FILE || props.fileManagementHookValues.restriction === FileManagementAreaRestriction.UP_TO_ONE_FILE,
        tooltip: props.tooltip,
    };

    return <InternalFileManagementArea {...fileManagementAreaProps} />;
};

interface InternalFileManagementAreaProps {
    disabled: boolean;
    documentApi: DocumentApi;
    existingFiles: UploadedFile[];
    inputId: string;
    onAddNewFiles: (files: File[]) => void;
    onRemoveNewFile: (file: File) => void;
    onRemoveExistingFile: (file: UploadedFile) => void;
    newFiles: File[];
    singleFileMode: boolean;
    tooltip?: JSX.Element;

    existingFilesFormFieldLabel?: string;
    fileDragAndDropFormFieldLabel?: string;
}

/**
 * Renders a file management area. This component is internal to this file and is not used directly: `FileManagementArea` and `FileManagementAreaWithSiblings` are exported to be used directly.
 * The point of this additional component is to DRY common code between the two versions of the file management area.
 */
const InternalFileManagementArea = (props: InternalFileManagementAreaProps) => {
    const defaultFileDragAndDropLabelText = props.singleFileMode ? DEFAULT_LABEL_FOR_FILE_DRAG_AND_DROP_SINGLE_MODE : DEFAULT_LABEL_FOR_FILE_DRAG_AND_DROP_MULTIPLE_MODE;
    const defaultExistingFilesLabelText = props.singleFileMode ? DEFAULT_LABEL_FOR_EXISTING_FILES_SINGLE_MODE : DEFAULT_LABEL_FOR_EXISTING_FILES_MULTIPLE_MODE;

    const fileDragAndDropLabelText = props.fileDragAndDropFormFieldLabel ?? defaultFileDragAndDropLabelText;
    const existingFilesLabelText = props.existingFilesFormFieldLabel ?? defaultExistingFilesLabelText;

    const fileDragAndDrop = (() => {
        if (props.singleFileMode) {
            const fileDragAndDropSingleModeProps: FileDragAndDropSingleModeProps = {
                labelText: fileDragAndDropLabelText,
                onSelectFile: (file) => {
                    if (props.newFiles.length > 0) {
                        props.onRemoveNewFile(props.newFiles[0]);
                    }

                    if (props.existingFiles.length > 0) {
                        props.onRemoveExistingFile(props.existingFiles[0]);
                    }

                    props.onAddNewFiles([file]);
                },
                onRemoveFile: () => props.onRemoveNewFile(props.newFiles[0]),
                file: props.newFiles[0],
                disabled: props.disabled,
                inputId: props.inputId,
                tooltip: props.tooltip,
            };
            return <FileDragAndDropSingleMode {...fileDragAndDropSingleModeProps} />;
        } else {
            const fileDragAndDropProps: FileDragAndDropProps = {
                labelText: fileDragAndDropLabelText,
                onAddFiles: props.onAddNewFiles,
                onRemoveFile: props.onRemoveNewFile,
                files: props.newFiles,
                disabled: props.disabled,
                inputId: props.inputId,
                tooltip: props.tooltip,
            };
            return <FileDragAndDrop {...fileDragAndDropProps} />;
        }
    })();

    return (
        <>
            {fileDragAndDrop}
            {/* TODO: Consider updating the file drag-and-drop to show both new and existing files within the dashed rectangle, if possible. I find the UI with two distinct sections and different ways of interacting with the files to be clunky. IMPORTANT: Some forms, such as Create Risk Review, rely on the fact that "existing files" is not shown, as it would not make sense; keep this in mind if/when the UI is changed. */}
            {props.existingFiles.length > 0 && (
                <>
                    <VisualLabel>{existingFilesLabelText}</VisualLabel>
                    <Table>
                        <TableBody>
                            {[...props.existingFiles]
                                .sort((fileA, fileB) => fileA.filename.localeCompare(fileB.filename))
                                .map((file) => {
                                    const overflowItems: OverflowItem[] = [];

                                    if (file.file_state === FileState.PASSED) {
                                        overflowItems.push({
                                            text: 'Download file',
                                            onClickAction: () => downloadDocument(props.documentApi, file),
                                            icon: ICON_DOWNLOAD,
                                        });
                                    }

                                    if (!props.disabled) {
                                        overflowItems.push({
                                            text: 'Delete file',
                                            onClickAction: () => props.onRemoveExistingFile(file),
                                            icon: ICON_DELETE_REMOVE,
                                        });
                                    }

                                    return (
                                        <TableRow key={file.file_id}>
                                            <TableCell>
                                                <div className={styles.fileNameAndState}>
                                                    <Text noStyles>{file.filename}</Text>
                                                    <FileStateToolTip fileState={file.file_state} />
                                                </div>
                                            </TableCell>
                                            <TableOverflowCell>
                                                {overflowItems.length > 0 && (
                                                    <div className={styles.overflowContainer}>
                                                        <OverflowMenu overflowItems={overflowItems} accessibilityTitle={`Open menu for ${file.filename}`} />
                                                    </div>
                                                )}
                                            </TableOverflowCell>
                                        </TableRow>
                                    );
                                })}
                        </TableBody>
                    </Table>
                </>
            )}
        </>
    );
};

/**
 * Handles the "S3" dance when submitting a form. Intended to be used when the user presses the submit button for a form with one or more file management areas.
 * Handles validation of all files.
 */
const submitRequestWithFiles = async <SubmitApiRequestReturnType,>(documentApi: DocumentApi, infoArray: ManagedFilesForArea[], submitApiRequest: (fileUpdates: FileUpdates[]) => Promise<SubmitApiRequestReturnType>, restriction: FileManagementAreaRestriction): Promise<{ apiRequestReturnValue: SubmitApiRequestReturnType; newlyUploadedFilesByArea: UploadedFile[][] }> => {
    switch (restriction) {
        case FileManagementAreaRestriction.NO_RESTRICTION:
            break;
        case FileManagementAreaRestriction.EXACTLY_ONE_FILE:
            infoArray.forEach((filesForArea) => {
                if (filesForArea.newFiles.length + filesForArea.existingFiles.length !== 1) {
                    // Note: this isn't a great error message for the user when multiple file management areas are on the screen, since it doesn't explain where the problem is. But, this is a temporary approach until we enhance file validation to occur at the time of updating files.
                    throw new Error('File is required.');
                }
            });
            break;
        case FileManagementAreaRestriction.UP_TO_ONE_FILE:
            infoArray.forEach((filesForArea) => {
                if (filesForArea.newFiles.length + filesForArea.existingFiles.length > 1) {
                    // Note: this isn't a great error message for the user when multiple file management areas are on the screen, since it doesn't explain where the problem is. But, this is a temporary approach until we enhance file validation to occur at the time of updating files.
                    throw new Error('Only a single file is allowed.');
                }
            });
            break;
    }

    for (const file of infoArray.flatMap((section) => section.newFiles)) {
        validateFileForUpload(file);
    }

    const fileUpdatesByArea: FileUpdates[] = infoArray.map((info) => ({ existing_files_to_delete: info.existingFilesToDelete, new_files: [] }));

    const pendingDocumentUploadsByFileManagementAreaIndex: Map<number, { signedUpload: SignedUploadResponse; file: File }[]> = new Map();

    try {
        for (const [index, fileInfo] of infoArray.entries()) {
            const pendingDocumentUploads: {
                signedUpload: SignedUploadResponse;
                file: File;
            }[] = [];

            for (const file of fileInfo.newFiles) {
                const signedUploadResponse = (await documentApi.getSignedUpload(file.name)).data;
                pendingDocumentUploads.push({ signedUpload: signedUploadResponse, file: file });
                fileUpdatesByArea[index].new_files.push({ filename: file.name, file_id: signedUploadResponse.file_id });
            }

            pendingDocumentUploadsByFileManagementAreaIndex.set(index, pendingDocumentUploads);
        }
    } catch (error) {
        console.log(error);
        throw new Error('Something went wrong when trying to upload files.');
    }

    const apiRequestReturnValue: SubmitApiRequestReturnType = await submitApiRequest(fileUpdatesByArea);

    const failedFileNames: string[] = [];
    // Upload each document to S3. If any error occurs, remember it for below, but continue trying to upload other documents.
    for (const pendingUpload of [...pendingDocumentUploadsByFileManagementAreaIndex.values()].flat()) {
        try {
            await documentApi.uploadDocument(pendingUpload.signedUpload.url, pendingUpload.signedUpload.fields, pendingUpload.file);
        } catch (error) {
            console.log(error);
            failedFileNames.push(pendingUpload.file.name);
        }
    }

    if (failedFileNames.length > 0) {
        throw new Error(`Something went wrong when trying to upload: ${failedFileNames.join(', ')}.`);
    }

    const newlyUploadedFilesByArea: UploadedFile[][] = fileUpdatesByArea.map((fileUpdates) => fileUpdates.new_files.map((file) => ({ ...file, file_state: FileState.PROCESSING })));

    return { apiRequestReturnValue, newlyUploadedFilesByArea };
};

interface BaseFileDragAndDropDropzoneProps {
    inputId: string;
    labelText: string;
    labelColor?: Colors;
    required?: boolean;
    tooltip?: JSX.Element;
    disabled?: boolean;
}

interface FileDragAndDropDropzoneProps extends BaseFileDragAndDropDropzoneProps {
    onAddFiles: (files: File[]) => void;
}

interface FileDragAndDropDropzoneSingleModeProps extends BaseFileDragAndDropDropzoneProps {
    onSelectFile: (file: File) => void;
}

const FileDragAndDropDropzone = ({ disabled = false, ...props }: FileDragAndDropDropzoneProps & { singleFileMode: boolean; hasSelectedFiles: boolean }): JSX.Element => {
    const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop: props.onAddFiles, disabled: disabled, multiple: props.singleFileMode !== true });
    const dragClassName = isDragActive ? styles.dropzoneDragActive : '';
    const disabledClassName = disabled ? styles.disabled : '';

    const promptText = (() => {
        if (props.singleFileMode) {
            if (props.hasSelectedFiles) {
                return 'Drag and drop a file, or click here to select a file. Selecting a new file will replace the current file.';
            } else {
                return 'Drag and drop a file, or click here to select a file. Only a single file can be selected.';
            }
        } else {
            return 'Drag and drop files, or click here to select files.';
        }
    })();

    return (
        <>
            <label htmlFor={props.inputId}>
                <VisualLabel color={props.labelColor} required={props.required} tooltip={props.tooltip}>
                    {props.labelText}
                </VisualLabel>
            </label>
            <div {...getRootProps({ className: `${styles.dropzone} ${dragClassName} ${disabledClassName}` })}>
                {/* Properties explicitly defined are not passed through by react-dropzone */}
                <input id={props.inputId} disabled={disabled} {...getInputProps()} />
                <Text color="darkGray">{promptText}</Text>
            </div>
        </>
    );
};

interface BaseDropzoneTableProps {
    hideRemoveNewDocumentButton?: boolean;
}

interface DropzoneTableProps extends BaseDropzoneTableProps {
    files: File[];
    onRemoveFile: (file: File) => void;
}

interface DropzoneTableSingleModeProps extends BaseDropzoneTableProps {
    file?: File;
    onRemoveFile: () => void;
}

const FileDragAndDropTable = ({ hideRemoveNewDocumentButton = false, ...props }: DropzoneTableProps): JSX.Element => {
    if (props.files.length === 0) {
        return <></>;
    }

    return (
        <>
            <Table>
                <TableBody>
                    {props.files.map((file, index) => {
                        return (
                            <TableRow key={index}>
                                <TableCell>
                                    <Text noStyles>{file.name}</Text>
                                </TableCell>
                                {!hideRemoveNewDocumentButton && (
                                    <TableOverflowCell>
                                        <div className={styles.overflowContainer}>
                                            <ListItemIcon
                                                sx={{
                                                    color: 'var(--hps-gray)',
                                                    display: 'flex',
                                                    justifyContent: 'flex-end',
                                                    minWidth: 0,
                                                    padding: '12px',
                                                }}
                                            >
                                                <IconButton aria-label={`Remove ${file.name}`} onClick={() => props.onRemoveFile(file)} fontAwesomeImage={ICON_DELETE_REMOVE} />
                                            </ListItemIcon>
                                        </div>
                                    </TableOverflowCell>
                                )}
                            </TableRow>
                        );
                    })}
                </TableBody>
            </Table>
        </>
    );
};

// TODO: Once the DDQ stops using this directly, do not export it.
export type FileDragAndDropProps = FileDragAndDropDropzoneProps & DropzoneTableProps;
type FileDragAndDropSingleModeProps = FileDragAndDropDropzoneSingleModeProps & DropzoneTableSingleModeProps;

/**
 * Renders an input that allows users to drag and drop files, or click to select files.
 * TODO: Once the DDQ stops using this directly, do not export it.
 */
export const FileDragAndDrop = (props: FileDragAndDropProps): JSX.Element => {
    const dropzoneProps = { singleFileMode: false, hasSelectedFiles: props.files.length > 0, ...props };

    return (
        <>
            <FileDragAndDropDropzone {...dropzoneProps} />
            <FileDragAndDropTable {...props} />
        </>
    );
};

/**
 * Renders/functions the same as `FileDragAndDrop`, but allows only a single file to be selected (if a file has been selected, selecting a second file will replace the first file).
 */
const FileDragAndDropSingleMode = (props: FileDragAndDropSingleModeProps): JSX.Element => {
    const dropzoneProps = { singleFileMode: true, hasSelectedFiles: props.file !== undefined, onAddFiles: (files: File[]) => props.onSelectFile(files[0]), ...props };
    const dropzoneTableProps = { files: props.file ? [props.file] : [], ...props };

    return (
        <>
            <FileDragAndDropDropzone {...dropzoneProps} />
            <FileDragAndDropTable {...dropzoneTableProps} />
        </>
    );
};
