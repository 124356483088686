import { IntegrationName, MetricName } from './ExternalIntegrations';
import { FileState, FileUpdates, UploadedFile } from './Files';
import { Effectiveness } from './OperationalControls';

export interface CreateManualEvidenceRequest {
    associated_controls: string[];
    evidence_comment?: string;
    evidence_effectiveness?: Effectiveness;
    external_url?: string;
    file_updates?: FileUpdates;
}

/**
 * Note that the values in this enum must correspond to the property names of ManualEvidence.
 * This is because the user sorting function uses index access based on these values.
 */
export enum ManualEvidenceSortFilterOptions {
    EVIDENCE_COMMENT = 'evidence_comment',
    EVIDENCE_EFFECTIVENESS = 'evidence_effectiveness',
    FILENAME = 'filename',
    LINK = 'external_url',
    METRIC_TIMESTAMP = 'metric_timestamp',
    SUBMITTER_SUBJECT = 'submitter_subject',
}

// This is the model for data that the back-end returns when the "Get Evidence" request is made.
export interface GetControlEvidenceResponse {
    automated: GetControlEvidenceIntegrationResponse[];
    manual: ManualEvidence[];
}
export interface GetControlEvidenceIntegrationResponse {
    integration_name: IntegrationName;
    metrics: GetControlEvidenceMetricResponse[];
}
export interface GetControlEvidenceMetricResponse {
    metric_name: MetricName;
    metric_values: GetControlEvidenceMetricValueResponse[];
}
export interface GetControlEvidenceMetricValueResponse {
    metric_timestamp: string;
    metric_value: { [index: string]: string };
}

export interface ManualEvidence {
    evidence_comment?: string;
    evidence_effectiveness?: number;
    external_url?: string;
    file?: UploadedFile;
    metric_timestamp: string;
    submitter_subject: string;
    [index: string]: string | undefined | FileState | number | UploadedFile;
}
