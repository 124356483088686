import { type JSX, useEffect, useState } from 'react';
import { Alert } from 'react-bootstrap';

import { DocumentApi } from 'Api/Document/DocumentApi';
import { TPRMApi } from 'Api/TPRM/TPRMApi';
import { PageCell } from 'Components/Containers/PageCell/PageCell';
import { ProgressBarIndicatorProps } from 'Components/Indicator/ProgressBarIndicator';
import { Placeholder } from 'Components/Placeholder/Placeholder';
import { Text } from 'Components/Text/Text';
import { controlTextToString } from 'Helpers/ControlFormatter/ControlFormatter';
import { UploadedFile } from 'Models/Files';
import { ControlEffectivenessProgressBarVariantAndPercentage, ControlText, Effectiveness, numberAsEffectiveness, numberAsEffectivenessString } from 'Models/OperationalControls';
import { Answer, MultipleSelectQuestionInstance, QuestionType, QuestionnaireReport, Service, ServiceAssessmentState, SingleSelectQuestionInstance, riskRatingAsString } from 'Models/TPRM';

import styles from './AssessmentAndReportTab.module.css';
import { AssessmentAndReportTabForm, AssessmentAndReportTabFormProps } from './Components/AssessmentAndReportTabForm';
import { ControlAssessmentAccordionControl, ControlAssessmentAccordionFramework, ControlAssessmentAccordionGroup, ControlAssessmentAccordionQuestion } from './ControlAssessmentAccordion/ControlAssessmentAccordion';

// TODO [TPRM - Final Review]: UIState is a tech-debt pattern. It introduces a pointless layer of abstraction and isn't implemented outside of TPRM.
interface UIState {
    thirdPartyId: string;
    serviceId: string;
    thirdPartyServiceTitle: string;
    effectivenessProgressBar: ProgressBarIndicatorProps;
    averageControlEffectiveness: string;
    report: Map<string, Framework>;
    inherentRisk: string;
    isNotInProgress: boolean;
}

export interface Framework {
    framework: string;
    frameworkName: string;
    groups: Map<string, Group>;
    effectiveness: Effectiveness;
}

export interface Group {
    framework: string;
    groupId: string;
    groupName: string;
    groupDescription?: string;
    controls: Map<string, Control>;
    displayText: string;
    effectiveness: Effectiveness;
}

export interface Control {
    framework: string;
    groupId: string;
    controlId: string;
    controlText: ControlText[];
    controlName?: string;
    questions: Map<number, Question>;
    displayText: string;
    effectiveness: Effectiveness;
}
export interface Question {
    _type: QuestionType;
    text: string;
    options?: string[];
    answerText?: Answer;
    answerIndex?: number;
    answerIndexes?: number[];
    answerDocuments: UploadedFile[];
}

interface FormFieldsState {
    additionalInformation?: string;
    overallEffectiveness?: number;
    residualRisk?: number;
    [index: string]: string | number | undefined;
}

export interface AssessmentAndReportTabProps {
    tprmApi: TPRMApi;
    documentApi: DocumentApi;
    serviceResponse: Service;
    questionnaireReport: QuestionnaireReport;
    onSaved: () => void;
}

/**
 * Renders the "Assessment" tab on the "Final Review" page for a vendor service.
 * Includes the form to submit the final review, plus a "Control Assessment" section that has an accordion full of controls and questions, with corresponding vendor answers and control effectiveness ratings.
 */
export const AssessmentAndReportTab = (props: AssessmentAndReportTabProps): JSX.Element => {
    const [uiState, setUiState] = useState<UIState>();
    const [formFieldState, setFormFieldState] = useState<FormFieldsState>();

    // TODO [TPRM - Final Review]: This should probably be a useMemo, if we need this pattern at all. See the above note about UIState being tech debt.
    useEffect(() => {
        const formState: FormFieldsState = {
            additionalInformation: props.serviceResponse.assessment_workflow_data.final_review_additional_information ?? '',
            overallEffectiveness: props.serviceResponse.assessment_workflow_data.final_review_control_effectiveness ?? 0,
            residualRisk: props.serviceResponse.assessment_workflow_data.final_review_residual_risk_score,
        };
        setFormFieldState(formState);

        const report = new Map(
            props.questionnaireReport.control_frameworks.map((frameworkResponse) => {
                const groups = new Map(
                    frameworkResponse.control_groups.map((groupResponse) => {
                        const controls = new Map(
                            groupResponse.controls.map((controlResponse) => {
                                const controlDisplayText = controlResponse.is_custom ? controlResponse.control_name! : `${controlResponse.control_id}. ${controlTextToString(controlResponse.control_text)}`;
                                const questions = new Map(
                                    controlResponse.questions.map((questionResponse, index) => {
                                        const question: Question = {
                                            _type: questionResponse._type,
                                            text: questionResponse.text,
                                            answerText: questionResponse.answer_text,
                                            answerDocuments: questionResponse.answer_documents,
                                        };
                                        if (question._type === QuestionType.MULTIPLE_SELECT) {
                                            question.answerIndexes = (questionResponse as MultipleSelectQuestionInstance).answer_indexes;
                                            question.options = (questionResponse as MultipleSelectQuestionInstance).options;
                                        } else if (question._type === QuestionType.SINGLE_SELECT) {
                                            question.answerIndex = (questionResponse as SingleSelectQuestionInstance).answer_index;
                                            question.options = (questionResponse as SingleSelectQuestionInstance).options;
                                        }
                                        return [index, question];
                                    })
                                );
                                const control: Control = {
                                    framework: controlResponse.control_framework,
                                    groupId: controlResponse.control_group_id,
                                    controlId: controlResponse.control_id,
                                    controlText: controlResponse.control_text,
                                    controlName: controlResponse.control_name,
                                    questions: questions,
                                    effectiveness: controlResponse.control_assessment_effectiveness ?? Effectiveness.INACTIVE,
                                    displayText: controlDisplayText,
                                };

                                return [controlResponse.control_id, control];
                            })
                        );
                        const groupDisplayText = groupResponse.is_custom ? groupResponse.control_group_name : `${groupResponse.control_group_id}. ${groupResponse.control_group_name}`;
                        const group: Group = {
                            framework: groupResponse.control_framework,
                            groupId: groupResponse.control_group_id,
                            groupName: groupResponse.control_group_name,
                            groupDescription: groupResponse.control_group_description,
                            controls: controls,
                            displayText: groupDisplayText,
                            effectiveness: groupResponse.control_group_effectiveness,
                        };

                        return [groupResponse.control_group_id, group];
                    })
                );

                const framework: Framework = {
                    framework: frameworkResponse.control_framework,
                    frameworkName: frameworkResponse.control_framework_name,
                    groups: groups,
                    effectiveness: frameworkResponse.control_framework_effectiveness,
                };

                return [frameworkResponse.control_framework, framework];
            })
        );

        const uiState: UIState = {
            thirdPartyId: props.serviceResponse.vendor_id,
            serviceId: props.serviceResponse.id,
            thirdPartyServiceTitle: `${props.serviceResponse.vendor_name} - ${props.serviceResponse.name}`,
            effectivenessProgressBar: {
                ...ControlEffectivenessProgressBarVariantAndPercentage(numberAsEffectiveness(props.questionnaireReport.average_control_effectiveness)),
                size: 'large',
            },
            averageControlEffectiveness: numberAsEffectivenessString(props.questionnaireReport.average_control_effectiveness),
            report: report,
            inherentRisk: riskRatingAsString(props.serviceResponse.assessment_workflow_data.irq_inherent_risk_score ?? 0),
            isNotInProgress: props.serviceResponse.assessment_workflow_data.state !== ServiceAssessmentState.PERFORMING_DUE_DILIGENCE,
        };
        setUiState(uiState);
    }, [props.serviceResponse, props.questionnaireReport]);

    if (formFieldState && uiState) {
        const assessmentAndReportTabFormProps: AssessmentAndReportTabFormProps = {
            defaultService: props.serviceResponse,
            disabled: uiState.isNotInProgress,
            documentApi: props.documentApi,
            onSaved: props.onSaved,
            tprmApi: props.tprmApi,
        };

        return (
            <>
                <PageCell>
                    {uiState.isNotInProgress && <Alert variant="warning">To make changes, first submit the inherent risk questionnaire.</Alert>}
                    <Text variant="Header2">Service Assessment</Text>
                    <hr />
                    <Text variant="Header3">This assessment will assign the following chosen effectiveness rating to the service.</Text>
                    <AssessmentAndReportTabForm {...assessmentAndReportTabFormProps} />
                </PageCell>
                <div className={styles.marginTop10}>
                    <PageCell>
                        <Text variant="Header2">{`Control Assessment${props.serviceResponse.assessment_workflow_setup.common_assessment_parent ? ' (from common assessment)' : ''}`}</Text>
                        <hr />
                        <div className={styles.accordionHeaderContainer}>
                            <Text variant="Text3" noStyles>
                                Framework/Control
                            </Text>
                            <div className={styles.accordionRightContent}>
                                <Text variant="Text3" noStyles>
                                    Effectiveness
                                </Text>
                            </div>
                        </div>
                        {Array.from(uiState.report.values()).map((framework: Framework, index: number) => (
                            <ControlAssessmentAccordionFramework key={index} framework={framework}>
                                {Array.from(framework.groups.values()).map((group: Group, index: number) => (
                                    <ControlAssessmentAccordionGroup key={index} group={group}>
                                        {Array.from(group.controls.values()).map((control: Control, index: number) => (
                                            <ControlAssessmentAccordionControl key={index} control={control}>
                                                {Array.from(control.questions.values()).map((question: Question, index: number) => (
                                                    <ControlAssessmentAccordionQuestion key={index} documentApi={props.documentApi} question={question} questionNumber={index} />
                                                ))}
                                            </ControlAssessmentAccordionControl>
                                        ))}
                                    </ControlAssessmentAccordionGroup>
                                ))}
                            </ControlAssessmentAccordionFramework>
                        ))}
                    </PageCell>
                </div>
            </>
        );
    } else {
        return <Placeholder />;
    }
};
