import moment from 'moment';
import { useEffect, useState } from 'react';

import { DashboardApi } from 'Api/Dashboards/DashboardApi';
import { ComplianceSummary, ControlStrengthResponse, ControlsSnapshot } from 'Models/Dashboards';

export const useControlStrengthQuery = (api: DashboardApi): [ControlStrengthResponse | undefined, Error | undefined] => {
    const [controlStrength, setControlStrength] = useState<ControlStrengthResponse>();
    const [error, setError] = useState<Error>();

    useEffect(() => {
        const fetchControlStrength = async (): Promise<void> => {
            try {
                const controlStrengthResponse = await api.getControlStrength();
                setControlStrength(controlStrengthResponse.data);
            } catch (error) {
                setError(error);
            }
        };

        fetchControlStrength();
    }, [api]);

    return [controlStrength, error];
};

export const useCurrentComplianceSummaryQuery = (api: DashboardApi): [ComplianceSummary[] | undefined, Error | undefined] => {
    const [complianceSummary, setComplianceSummary] = useState<ComplianceSummary[]>();
    const [error, setError] = useState<Error>();

    useEffect(() => {
        const fetchCurrentComplianceSummary = async (): Promise<void> => {
            try {
                const currentComplianceSummaries = await api.getCurrentComplianceSummary();
                setComplianceSummary(currentComplianceSummaries.data.sort((complianceSummaryA, complianceSummaryB) => complianceSummaryA.regulation.localeCompare(complianceSummaryB.regulation)));
            } catch (error) {
                setError(error);
            }
        };

        fetchCurrentComplianceSummary();
    }, [api]);

    return [complianceSummary, error];
};

export const useControlsHistoryQuery = (api: DashboardApi) => {
    const [controlsHistory, setControlsHistory] = useState<ControlsSnapshot[]>();
    const [startDate, setStartDate] = useState<string>(moment().subtract(3, 'months').startOf('month').format());
    const [endDate, setEndDate] = useState<string>(moment().format());
    const [controlHistoryError, setControlHistoryError] = useState<string>();

    useEffect(() => {
        const fetchControlHistory = async (): Promise<void> => {
            try {
                const controlHistory = await api.getControlsHistory(startDate, endDate);
                setControlsHistory(controlHistory.data);
            } catch (error) {
                setControlHistoryError(error.message);
            }
        };

        fetchControlHistory();
    }, [api, endDate, startDate]);

    const setControlStrengthStartDate = (startDate: string) => {
        setStartDate(startDate);
    };

    const setControlStrengthEndDate = (endDate: string) => {
        setEndDate(endDate);
    };

    return { controlsHistory, updateDateFilters: { setControlStrengthStartDate, setControlStrengthEndDate }, controlHistoryError };
};
