import { isUndefined } from 'lodash-es';
import moment from 'moment';
import { type JSX, useState } from 'react';
import { Alert, Form } from 'react-bootstrap';

import { DocumentApi } from 'Api/Document/DocumentApi';
import { TPRMApi } from 'Api/TPRM/TPRMApi';
import { Button } from 'Components/Buttons/Buttons';
import { FileManagementArea, useFileManagementArea } from 'Components/Files/FileManagementArea';
import { ChangeEventType, FormFieldSelect } from 'Components/FormField/FormFieldSelect/FormFieldSelect';
import { FormFieldTextArea } from 'Components/FormField/FormFieldTextArea/FormFieldTextArea';
import { ICON_SAVE } from 'Config/Icons';
import { jsDateToIso8601 } from 'Helpers/DateTimeUtils/DateTimeUtils';
import { EffectivenessSelectOptions } from 'Models/OperationalControls';
import { ScheduleFrequencyKeys } from 'Models/ScheduleFrequency';
import { RiskRatingSelectOptions, Service, UpdateServiceAssessmentRequest } from 'Models/TPRM';

import styles from './AssessmentAndReportTabForm.module.css';
import { SubmitThirdPartyServiceAssessmentConfirmationModal, SubmitThirdPartyServiceAssessmentConfirmationModalProps } from '../SubmitThirdPartyServiceAssessmentConfirmationModal/SubmitThirdPartyServiceAssessmentConfirmationModal';

export enum Modals {
    ConfirmationModal,
    None,
}

interface FormFieldsState {
    additionalInformation?: string;
    overallEffectiveness?: number;
    residualRisk?: number;
    [index: string]: string | number | undefined;
}

interface FormState {
    isSaving: boolean;
    isSubmitting: boolean;
    successMessage?: string;
    failureMessage?: string;
}

export interface AssessmentAndReportTabFormProps {
    defaultService: Service;
    disabled: boolean;
    documentApi: DocumentApi;
    onSaved: () => void;
    tprmApi: TPRMApi;
}

const calculateRelativeDueDate = (scheduleNumber: string, scheduleFrequency: ScheduleFrequencyKeys): Date => {
    let momentKey: 'days' | 'months' | 'years';
    switch (scheduleFrequency) {
        case 'DAYS':
            momentKey = 'days';
            break;
        case 'MONTHS':
            momentKey = 'months';
            break;
        case 'YEARS':
            momentKey = 'years';
            break;
    }

    return moment().add(scheduleNumber, momentKey).toDate();
};

/**
 * Renders the "Assessment" tab on the "Final Review" page for a vendor service.
 * Includes the form to submit the final review, plus a "Control Assessment" section that has an accordion full of controls and questions, with corresponding vendor answers and control effectiveness ratings.
 */
export const AssessmentAndReportTabForm = (props: AssessmentAndReportTabFormProps): JSX.Element => {
    const [formFieldState, setFormFieldState] = useState<FormFieldsState>({
        additionalInformation: props.defaultService.assessment_workflow_data.final_review_additional_information ?? '',
        overallEffectiveness: props.defaultService.assessment_workflow_data.final_review_control_effectiveness ?? 0,
        residualRisk: props.defaultService.assessment_workflow_data.final_review_residual_risk_score,
    });
    const [formState, setFormState] = useState<FormState>({ isSaving: false, isSubmitting: false });
    const [nextAssessmentDate, setNextAssessmentDate] = useState<Date | undefined>(props.defaultService.assessment_workflow_setup.schedule ? calculateRelativeDueDate(props.defaultService.assessment_workflow_setup.schedule.schedule_number.toString(), props.defaultService.assessment_workflow_setup.schedule.schedule_frequency) : undefined);
    const [submitRequestWithManagedFiles, fileManagementHookValues] = useFileManagementArea(props.documentApi, props.defaultService.assessment_workflow_data.final_review_documents);
    const [displayedModal, setDisplayedModal] = useState<Modals>(Modals.None);

    const handleChange = (event: React.FormEvent<HTMLInputElement>): void => {
        event.preventDefault();

        setFormFieldState({ ...formFieldState, [event.currentTarget.name]: event.currentTarget.value });
    };

    const handleSelectChange = (value: ChangeEventType, formFieldId: string): void => {
        setFormFieldState({ ...formFieldState, [formFieldId]: value as number });
    };

    const validateAndDisplayConfirmationModal = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        if (!formFieldState?.residualRisk) {
            setFormState({ isSaving: false, isSubmitting: false, failureMessage: 'Residual risk is required.' });
            return;
        } else {
            setFormState({ isSaving: false, isSubmitting: false, failureMessage: undefined });
        }

        setDisplayedModal(Modals.ConfirmationModal);
    };

    const submitAssessment = () => {
        setFormState({ isSaving: false, isSubmitting: true });
        setDisplayedModal(Modals.None);
        updateAssessment(true);
    };

    const handleSave = () => {
        setFormState({ isSaving: true, isSubmitting: false });
        updateAssessment(false);
    };

    const updateAssessment = async (isSubmit: boolean) => {
        try {
            await submitRequestWithManagedFiles(async (fileUpdates) => {
                const modifyServiceRequest: UpdateServiceAssessmentRequest = {
                    is_submit: isSubmit,
                    file_updates: fileUpdates,
                    assessment_additional_information: formFieldState?.additionalInformation,
                    assessment_control_effectiveness: formFieldState?.overallEffectiveness,
                    assessment_residual_risk_score: formFieldState?.residualRisk,
                    new_assessment_due_date: !isUndefined(nextAssessmentDate) && isSubmit ? jsDateToIso8601(nextAssessmentDate) : undefined,
                };

                await props.tprmApi.modifyServiceAssessment(modifyServiceRequest, props.defaultService.vendor_id, props.defaultService.id);
            });

            setFormState({ isSaving: false, isSubmitting: false, successMessage: 'Assessment updated.' });
            props.onSaved();
        } catch (error) {
            setFormState({ isSaving: false, isSubmitting: false, failureMessage: error.message });
        }
    };

    const confirmationModalProps: SubmitThirdPartyServiceAssessmentConfirmationModalProps = {
        hideModal: () => setDisplayedModal(Modals.None),
        onServiceAssessmentDueDateChanged: (dueDate?: Date) => setNextAssessmentDate(dueDate),
        serviceAssessmentDueDate: nextAssessmentDate,
        onConfirm: () => submitAssessment(),
        service: props.defaultService,
    };

    const formDisabled = props.disabled || formState.isSaving || formState.isSubmitting;

    return (
        <>
            {displayedModal === Modals.ConfirmationModal && <SubmitThirdPartyServiceAssessmentConfirmationModal {...confirmationModalProps} />}
            {formState.successMessage && <Alert variant="success">{formState.successMessage}</Alert>}
            {formState.failureMessage && <Alert variant="danger">{formState.failureMessage}</Alert>}
            <Form onSubmit={validateAndDisplayConfirmationModal}>
                <div className={styles.margin10}>
                    <FileManagementArea fileDragAndDropFormFieldLabel="Supporting Documentation" disabled={formDisabled} documentApi={props.documentApi} fileManagementHookValues={fileManagementHookValues} />
                </div>
                <fieldset disabled={formDisabled}>
                    <div className={styles.margin10}>
                        <FormFieldTextArea formFieldId="additionalInformation" handleChange={handleChange} formFieldLabel="Additional Information" value={formFieldState.additionalInformation} />
                    </div>
                    <div className={styles.margin10}>
                        <FormFieldSelect options={EffectivenessSelectOptions} handleChange={handleSelectChange} formFieldId={'overallEffectiveness'} selectedOption={formFieldState.overallEffectiveness} formFieldLabel={`Overall Control Effectiveness${props.defaultService.assessment_workflow_setup.common_assessment_parent ? ' (from common assessment)' : ''}`} />
                    </div>
                    <div className={styles.margin10}>
                        <FormFieldSelect required options={RiskRatingSelectOptions} handleChange={handleSelectChange} formFieldId={'residualRisk'} selectedOption={formFieldState.residualRisk} formFieldLabel="Residual Risk" />
                    </div>
                    <div className={styles.buttons}>
                        <Button variant="secondary" onClick={handleSave} fontAwesomeImage={ICON_SAVE} isLoading={formState.isSaving} loadingText="Saving...">
                            Save
                        </Button>
                        <Button variant="submit" isLoading={formState.isSubmitting} loadingText="Submitting...">
                            Submit
                        </Button>
                    </div>
                </fieldset>
            </Form>
        </>
    );
};
