import { useState } from 'react';
import { AccordionCollapse, Form } from 'react-bootstrap';

import { ActionsApi } from 'Api/Actions/ActionsApi';
import { DocumentApi } from 'Api/Document/DocumentApi';
import { Accordion } from 'Components/Accordion/Accordion';
import { AccordionToggle } from 'Components/Accordion/AccordionToggle/AccordionToggle';
import { Button } from 'Components/Buttons/Buttons';
import { useCachedData } from 'Components/Context/CachedDataContext';
import { FileManagementArea, useFileManagementArea } from 'Components/Files/FileManagementArea';
import { FormFieldDatePicker } from 'Components/FormField/FormFieldDatePicker/FormFieldDatePicker';
import { FormFieldMultiOptionSelect } from 'Components/FormField/FormFieldMultiOptionSelect/FormFieldMultiOptionSelect';
import { ChangeEventType, FormFieldSelect } from 'Components/FormField/FormFieldSelect/FormFieldSelect';
import { FormFieldText } from 'Components/FormField/FormFieldText/FormFieldText';
import { FormFieldTextArea } from 'Components/FormField/FormFieldTextArea/FormFieldTextArea';
import { FormFieldUserSelect } from 'Components/FormField/FormFieldUserSelect/FormFieldUserSelect';
import { ConfirmationModal } from 'Components/Modal/ConfirmationModal';
import { Text } from 'Components/Text/Text';
import { LinkButtonToast, TextToast } from 'Components/Toast/Toast';
import { ICON_DELETE_REMOVE } from 'Config/Icons';
import { ACTIONS, RISKS, RISK_REGISTER } from 'Config/Paths';
import { ACTIONS_OWNER, REFERENCE } from 'Config/Tooltips';
import { iso8601ToJsDate, jsDateToIso8601 } from 'Helpers/DateTimeUtils/DateTimeUtils';
import { isUserWithRiskRole } from 'Helpers/UserUtils';
import { Action, ActionStatus, ActionStatusOptions, CreateActionRequest, UpdateActionRequest } from 'Models/Actions';
import { ValidationError } from 'Models/ErrorTypes';
import { FileToBeUploaded, FileUpdates } from 'Models/Files';
import { RiskResponse } from 'Models/RiskRegister';
import { GroupOptionType, GroupedOptions } from 'Models/Types/GlobalType';
import { UserResponse } from 'Models/User';
import { RiskTab } from 'Pages/RiskRegister/RiskDetails/RiskDetails';
import { RiskMapping } from 'Pages/RiskRegister/RiskMapping/RiskMapping';

import styles from './ManageActionForm.module.css';
import { ActionsPageType } from '../ManageAction/ManageAction';

enum Modal {
    Delete,
    None,
}

enum SubmitState {
    None,
    Saving,
    Saved,
    Deleted,
}

export interface ManageActionFormProps {
    actionsApi: ActionsApi;
    defaultMappedRiskIds: string[];
    defaultActionId?: string;
    documentApi: DocumentApi;
    onActionDeleted: () => void;
    pageType: ActionsPageType;
    risks: RiskResponse[];
    tagOptions: GroupedOptions[];

    initialVersion?: Action;
    preselectedRisk?: RiskResponse;
}

interface FormFieldState {
    comments?: string;
    description?: string;
    documents?: FileToBeUploaded[];
    documentUpdates?: FileUpdates[];
    dueDate?: Date;
    owner?: UserResponse;
    reference?: string;
    status?: ActionStatus;
    tags?: string[];
    title?: string;
}

export const ManageActionForm = (props: ManageActionFormProps) => {
    const cachedData = useCachedData();
    const [displayedModal, setDisplayedModal] = useState(Modal.None);
    const [formFieldsState, setFormFieldsState] = useState<FormFieldState>({
        title: props.initialVersion?.title,
        description: props.initialVersion?.description,
        owner: cachedData.users.find((user) => user.cognito_subject === props.initialVersion?.owner),
        tags: props.initialVersion?.tags,
        documents: props.initialVersion?.documents,
        dueDate: props.initialVersion?.due_date ? iso8601ToJsDate(props.initialVersion.due_date) : undefined,
        comments: props.initialVersion?.comments,
        reference: props.initialVersion?.link ? props.initialVersion?.link : '',
        status: props.initialVersion?.status,
    });
    const [toastErrorMessage, setToastErrorMessage] = useState<string>();
    const [toastSuccessMessage, setToastSuccessMessage] = useState<string>();
    const [submitState, setSubmitState] = useState(SubmitState.None);
    const [actionId, setActionId] = useState<string | undefined>(props.defaultActionId);
    const [mappedRiskIds, setMappedRiskIds] = useState<string[]>(props.defaultMappedRiskIds);
    const [submitRequestWithManagedFiles, fileManagementHookValues] = useFileManagementArea(props.documentApi, props.initialVersion?.documents ?? []);

    const validateFormInput = () => {
        if (formFieldsState.title === undefined || formFieldsState.title.length === 0) {
            throw new ValidationError('Title is required.');
        }

        if (formFieldsState.description === undefined || formFieldsState.description.length === 0) {
            throw new ValidationError('Description is required.');
        }

        if (formFieldsState.owner === undefined) {
            throw new ValidationError('Owner is required.');
        }
    };

    const saveAction = async (event: React.FormEvent<HTMLFormElement>): Promise<void> => {
        event.preventDefault();
        setToastErrorMessage(undefined);
        setToastSuccessMessage(undefined);
        setSubmitState(SubmitState.Saving);

        try {
            validateFormInput();
            await submitRequestWithManagedFiles(async (fileUpdates) => {
                if (actionId && props.pageType === ActionsPageType.MANAGE) {
                    const updateActionRequest: UpdateActionRequest = {
                        title: formFieldsState.title!,
                        description: formFieldsState.description!,
                        status: formFieldsState.status!,
                        owner: formFieldsState.owner!.cognito_subject,
                        tags: formFieldsState.tags ? formFieldsState.tags : [],
                        due_date: formFieldsState.dueDate ? jsDateToIso8601(formFieldsState.dueDate) : undefined,
                        comments: formFieldsState.comments,
                        link: formFieldsState.reference !== '' ? formFieldsState.reference : undefined,
                        file_updates: fileUpdates,
                        associated_risks: mappedRiskIds && mappedRiskIds.length > 0 ? mappedRiskIds : [],
                    };
                    await props.actionsApi.updateAction(actionId, updateActionRequest);
                } else {
                    const createActionRequest: CreateActionRequest = {
                        title: formFieldsState.title!,
                        description: formFieldsState.description!,
                        owner: formFieldsState.owner!.cognito_subject,
                        tags: formFieldsState.tags ? formFieldsState.tags : [],
                        due_date: formFieldsState.dueDate ? jsDateToIso8601(formFieldsState.dueDate) : undefined,
                        comments: formFieldsState.comments,
                        link: formFieldsState.reference !== '' ? formFieldsState.reference : undefined,
                        file_updates: fileUpdates,
                        associated_risks: mappedRiskIds && mappedRiskIds.length > 0 ? mappedRiskIds : [],
                    };
                    const createActionResponse = await props.actionsApi.createAction(createActionRequest);
                    setActionId(createActionResponse.data);
                }
            });
            setSubmitState(SubmitState.Saved);

            let successMessage = 'Action saved.';

            if (mappedRiskIds && mappedRiskIds.length > 0) {
                if (mappedRiskIds.length > 1) {
                    successMessage += ' It may take a few seconds for the action to show up in the risk treatment plans.';
                } else {
                    successMessage += ' It may take a few seconds for the action to show up in the risk treatment plan.';
                }
            }
            setToastSuccessMessage(successMessage);
        } catch (error) {
            setToastErrorMessage(error.message);
            setSubmitState(SubmitState.None);
        }
    };

    const deleteAction = async (): Promise<string> => {
        await props.actionsApi.deleteAction(actionId!);
        setSubmitState(SubmitState.Deleted);
        props.onActionDeleted();
        return 'Action deleted.';
    };

    const handleChange = (event: React.FormEvent<HTMLInputElement>): void => {
        event.preventDefault();
        setFormFieldsState({ ...formFieldsState, [event.currentTarget.name]: event.currentTarget.value });
    };

    const handleChangeDueDate = (date: Date | undefined): void => {
        setFormFieldsState({ ...formFieldsState, dueDate: date });
    };

    const handleStatusSelectChange = (value: ChangeEventType): void => {
        setFormFieldsState({ ...formFieldsState, status: value as ActionStatus });
    };

    const handleSelectOwnerChange = (user: UserResponse): void => {
        setFormFieldsState({ ...formFieldsState, owner: user });
    };

    const formDisabled = submitState === SubmitState.Saved || submitState === SubmitState.Deleted;

    return (
        <>
            {displayedModal === Modal.Delete && props.initialVersion && (
                <ConfirmationModal operationType="delete" headerText="Delete Action" areYouSureText={`Are you sure you want to delete "${props.initialVersion.title}"?`} performOperation={deleteAction} hideModal={() => setDisplayedModal(Modal.None)}>
                    <Text>The action will be removed from all risk treatment plans that include it.</Text>
                </ConfirmationModal>
            )}
            {toastSuccessMessage && <LinkButtonToast variant="success" clearToast={() => setToastSuccessMessage(undefined)} text={toastSuccessMessage} linkButtonText={props.preselectedRisk ? 'View Treatment Plan' : 'View Action'} linkButtonTo={props.preselectedRisk ? `/${RISK_REGISTER}/${RISKS}/${props.preselectedRisk.id}#${RiskTab.TREATMENT_PLAN}` : `/${ACTIONS}/${actionId}`} />}
            {toastErrorMessage && <TextToast variant="failure" clearToast={() => setToastErrorMessage(undefined)} text={toastErrorMessage} />}

            <Form onSubmit={saveAction} noValidate>
                <div className={styles.formFieldsContainer}>
                    <fieldset disabled={formDisabled}>
                        <div className={styles.fieldContainer}>
                            <FormFieldText formFieldLabel="Title" formFieldId="title" handleChange={handleChange} value={formFieldsState.title ?? ''} required />
                        </div>
                        <div className={styles.fieldContainer}>
                            <FormFieldTextArea formFieldLabel="Description" formFieldId="description" handleChange={handleChange} value={formFieldsState.description ?? ''} required />
                        </div>
                        <div className={styles.fieldGroupContainer}>
                            <div className={styles.fieldContainer}>
                                <FormFieldUserSelect users={cachedData.users.filter(isUserWithRiskRole)} onUserSelected={handleSelectOwnerChange} formFieldId="owner" selectedUser={formFieldsState.owner} formFieldLabel="Owner" required tooltip={ACTIONS_OWNER} />
                            </div>
                            <div className={styles.fieldContainer}>
                                <FormFieldMultiOptionSelect
                                    defaultSelectedOptions={props.tagOptions
                                        .map((group) => group.options)
                                        .flat()
                                        .filter((option) => (formFieldsState.tags ?? []).includes(option.value as string))}
                                    formFieldLabel="Tags"
                                    formFieldId="tags"
                                    handleChange={(value: GroupOptionType[]) => setFormFieldsState({ ...formFieldsState, tags: value.map((tag) => tag.value as string) })}
                                    options={props.tagOptions}
                                    accessibilityLabel="tag selection"
                                />
                            </div>
                        </div>
                        <div className={styles.fieldGroupContainer}>
                            <div className={styles.fieldContainer}>
                                <FormFieldDatePicker formFieldLabel="Due Date" formFieldId="dueDate" dateFormat="MM/dd/yyyy" placeholder={'MM/DD/YYYY'} selected={formFieldsState.dueDate} handleChange={handleChangeDueDate} />
                            </div>
                            {props.pageType === ActionsPageType.MANAGE && (
                                <div className={styles.fieldContainer}>
                                    <FormFieldSelect required formFieldLabel="Status" formFieldId="status" handleChange={handleStatusSelectChange} options={ActionStatusOptions} selectedOption={formFieldsState.status} />
                                </div>
                            )}
                            <div className={styles.fieldContainer}>
                                <FormFieldText formFieldLabel="Reference" formFieldId="reference" handleChange={handleChange} value={formFieldsState.reference ?? ''} tooltip={REFERENCE} />
                            </div>
                        </div>
                        <div className={styles.fieldContainer}>
                            <FormFieldTextArea formFieldId="comments" formFieldLabel="Comments" handleChange={handleChange} value={formFieldsState.comments ?? ''} />
                        </div>
                    </fieldset>
                    <div className={styles.dragAndDrop}>
                        <FileManagementArea disabled={formDisabled} documentApi={props.documentApi} fileManagementHookValues={fileManagementHookValues} />
                    </div>
                </div>
                <fieldset disabled={formDisabled}>
                    <div className={styles.riskMapping}>
                        {/* Expand the accordion by default if the action starts with mapped risks, or if it is being created for a specific risk. */}
                        <Accordion defaultActiveKey={props.preselectedRisk || mappedRiskIds.length > 0 ? 'riskTreatments' : undefined}>
                            <div className={styles.accordionContainer}>
                                <AccordionToggle eventKey="riskTreatments" ariaLabelSuffix="Map to Risk Treatment Plan" />
                                <div className={styles.accordionHeaderContainer}>
                                    <Text variant="Header2">Map to Risk Treatment Plan</Text>
                                </div>
                            </div>
                            <hr />
                            <AccordionCollapse eventKey="riskTreatments">
                                <div className={styles.accordionCollapse}>
                                    <RiskMapping
                                        riskMappingItems={props.risks}
                                        handleRiskChange={(unmappedSelectedRisks) => {
                                            setMappedRiskIds(unmappedSelectedRisks);
                                        }}
                                        currentMappedRiskIdentifiers={mappedRiskIds}
                                    />
                                </div>
                            </AccordionCollapse>
                        </Accordion>
                    </div>
                    <div className={styles.buttonContainer}>
                        {props.pageType === ActionsPageType.MANAGE && (
                            <Button variant="danger" disabled={submitState !== SubmitState.None} onClick={() => setDisplayedModal(Modal.Delete)} fontAwesomeImage={ICON_DELETE_REMOVE}>
                                Delete
                            </Button>
                        )}
                        <div className={styles.submitButton}>
                            <Button variant="submit" disabled={submitState !== SubmitState.None} isLoading={submitState === SubmitState.Saving} loadingText="Submitting...">
                                Save
                            </Button>
                        </div>
                    </div>
                </fieldset>
            </Form>
        </>
    );
};
