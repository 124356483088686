import { type JSX, useEffect, useMemo, useState } from 'react';

import { DocumentApi } from 'Api/Document/DocumentApi';
import { RiskRegisterApi } from 'Api/RiskRegister/RiskRegisterApi';
import { TagsApi } from 'Api/Tags/TagsApi';
import { ChangeEventType, FormFieldSelect } from 'Components/FormField/FormFieldSelect/FormFieldSelect';
import { Breadcrumb, BreadcrumbLink, BreadcrumbText } from 'Components/Nav/Breadcrumb/Breadcrumb';
import { PageLayoutDefault } from 'Components/PageLayout/PageLayoutDefault';
import { Placeholder } from 'Components/Placeholder/Placeholder';
import { RiskTable } from 'Components/RiskRegister/RiskTable/RiskTable';
import { PrimaryTabs, Tab } from 'Components/Tabs/PrimaryTabs/PrimaryTabs';
import { Text } from 'Components/Text/Text';
import { RISK_REGISTER, RISK_REVIEW } from 'Config/Paths';
import { iso8601ToUsDateShort } from 'Helpers/DateTimeUtils/DateTimeUtils';
import { useDisplayableTagsLookup } from 'Hooks/Tags';
import { ResponseModel } from 'Models/ResponseModel';
import { RiskAssessment, RiskCategoryResponse, RiskResponse } from 'Models/RiskRegister';

import styles from './CreateRiskAssessment.module.css';
import { CreateRiskAssessmentForm, CreateRiskAssessmentFormProps } from '../Components/CreateRiskAssessmentForm';
import { RiskPeriodComparison } from '../RiskAssessmentListing/RiskPeriodComparison/RiskPeriodComparison';

export interface CreateRiskAssessmentProps {
    riskRegisterApi: RiskRegisterApi;
    documentApi: DocumentApi;
    tagsApi: TagsApi;
}

export const CreateRiskAssessment = (props: CreateRiskAssessmentProps): JSX.Element => {
    const [riskCategory, setRiskCategory] = useState<string>();
    const [riskCategoryOptions, setRiskCategoryOptions] = useState<RiskCategoryResponse[]>();
    const [pageErrorMessage, setPageErrorMessage] = useState<string>();
    const [riskAssessments, setRiskAssessments] = useState<RiskAssessment[]>();
    const [risks, setRisks] = useState<RiskResponse[]>();
    const getDisplayableTagsState = useDisplayableTagsLookup(props.tagsApi);

    const latestAssessmentsByCategory: Map<string, RiskAssessment> = useMemo(() => {
        const latestAssessmentsByCategory = new Map<string, RiskAssessment>();

        if (!riskAssessments) {
            return latestAssessmentsByCategory;
        }

        riskAssessments.forEach((assessment) => {
            if (!latestAssessmentsByCategory.has(assessment.category.id) || assessment.timestamp > latestAssessmentsByCategory.get(assessment.category.id)!.timestamp) {
                latestAssessmentsByCategory.set(assessment.category.id, assessment);
            }
        });

        return latestAssessmentsByCategory;
    }, [riskAssessments]);

    const latestAssessment = riskCategory === undefined ? undefined : latestAssessmentsByCategory.get(riskCategory);

    useEffect(() => {
        const getRisks = async (): Promise<void> => {
            try {
                const riskResponse: ResponseModel<RiskResponse[]> = await props.riskRegisterApi.getAllRisks();
                setRisks(riskResponse.data);
            } catch (error) {
                setPageErrorMessage(error.message);
            }
        };

        const getRiskAssessments = async (): Promise<void> => {
            try {
                const response = await props.riskRegisterApi.getAllRiskAssessments();
                setRiskAssessments(response.data);
            } catch (error) {
                setPageErrorMessage(error.message);
            }
        };

        const getRiskCategories = async (): Promise<void> => {
            try {
                const response = await props.riskRegisterApi.getRiskCategories();
                setRiskCategoryOptions(response.data);

                if (response.data.length === 1) {
                    setRiskCategory(response.data[0].id);
                }
            } catch (error) {
                setPageErrorMessage(error.message);
            }
        };

        getRisks();
        getRiskAssessments();
        getRiskCategories();
    }, [props.riskRegisterApi]);

    const subheaderText = () => {
        if (riskCategory && latestAssessment) {
            return `Last Review: ${iso8601ToUsDateShort(latestAssessment.timestamp)}`;
        } else {
            return 'Last Review: N/A';
        }
    };

    if (pageErrorMessage) {
        return <Text>{pageErrorMessage}</Text>;
    }

    if (riskCategoryOptions && risks && riskAssessments && getDisplayableTagsState.type === 'success') {
        const risksForCategory = riskCategory === undefined ? [] : risks.filter((risk) => risk.category.id === riskCategory);

        const createRiskAssessmentFormProps: CreateRiskAssessmentFormProps = {
            documentApi: props.documentApi,
            riskRegisterApi: props.riskRegisterApi,
            riskCategoryId: riskCategory,
        };

        return (
            <>
                <PageLayoutDefault
                    headerBreadcrumb={
                        <Breadcrumb textColor="blue">
                            <BreadcrumbLink link={`/${RISK_REGISTER}/${RISK_REVIEW}`}>Risk Reviews</BreadcrumbLink>
                            <BreadcrumbText>Create Risk Review</BreadcrumbText>
                        </Breadcrumb>
                    }
                    headerTitle="Create Risk Review"
                    headerDescription="Select a risk category to create a risk review."
                    body={[
                        {
                            content: (
                                <>
                                    <div className={styles.assessmentHeader}>
                                        <div>
                                            <Text variant="Header2">Create Risk Review</Text>
                                            <Text variant="Header4">{subheaderText()}</Text>
                                        </div>
                                        <div className={styles.categorySelect}>
                                            <FormFieldSelect formFieldLabel="Risk Category" required formFieldId="risk_category" handleChange={(value: ChangeEventType) => setRiskCategory(value as string)} options={riskCategoryOptions.map((option) => ({ label: option.title, value: option.id }))} selectedOption={riskCategory} tooltip="The classification/grouping of risks that you are assessing. Risk categories are defined in Settings." />
                                        </div>
                                    </div>
                                    <hr />
                                    <CreateRiskAssessmentForm {...createRiskAssessmentFormProps} />
                                </>
                            ),
                        },
                        {
                            content: riskCategory ? (
                                <PrimaryTabs defaultActiveTab="riskListing" removePadding transparent>
                                    <Tab eventKey="riskListing" title="Risk Listing">
                                        <RiskTable type="current risks" risks={risksForCategory} getDisplayableTags={getDisplayableTagsState.data} openLinksInNewTabs />
                                    </Tab>
                                    <Tab eventKey="riskPeriodComparison" title="Risk Period Comparison">
                                        <RiskPeriodComparison riskAssessments={riskAssessments} risks={risksForCategory} selectedRiskCategory={riskCategory} />
                                    </Tab>
                                </PrimaryTabs>
                            ) : (
                                <Text>Select a risk category to view a list of risks and corresponding rating changes.</Text>
                            ),
                        },
                    ]}
                />
            </>
        );
    } else return <Placeholder />;
};
