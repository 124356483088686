import type { JSX } from 'react';

import { Colors } from 'Components/Colors';

import styles from './Text.module.css';

export type TextVariant = 'Header1' | 'Header2' | 'Header3' | 'Header4' | 'Text1' | 'Text2' | 'Text3' | 'Text4';

/**
 * @param preserveLineBreaks - By default, line breaks are removed from text rendered by this element. Set this to true to preserve line breaks.
 */
export interface TextProps {
    children: React.ReactNode;
    color?: Colors;
    noStyles?: boolean;
    preserveLineBreaks?: boolean;
    toUppercase?: boolean;
    variant?: TextVariant;
}

/**
 * Use this component for all text within SummIT Security.
 *
 * Use a Header variant for headers and a Text variant for text...pretty straightforward. Higher numbers == less prominent styling.
 *
 * Use the noStyles={true} prop to set all paddings/margins to 0 and force the display to inline, otherwise the default styles for <h# /> and <p /> elements will be applied.
 *
 * The default configuration when no props are supplied is Text3 in blue.
 */
export const Text = (props: TextProps): JSX.Element => {
    const color = props.color ? props.color : 'blue';
    const preserveLineBreaks = props.preserveLineBreaks ? styles.preserveLineBreaks : '';
    const removeDefaultStyles = props.noStyles ? styles.removeDefaultStyles : '';
    const toUppercase = props.toUppercase ? styles.caps : '';

    switch (props.variant) {
        case 'Header1':
            return <h1 className={`${styles.header1} ${`${color}Font`} ${preserveLineBreaks} ${removeDefaultStyles} ${toUppercase}`}>{props.children}</h1>;
        case 'Header2':
            return <h2 className={`${styles.header2} ${`${color}Font`} ${preserveLineBreaks} ${removeDefaultStyles} ${toUppercase}`}>{props.children}</h2>;
        case 'Header3':
            return <h3 className={`${styles.header3} ${`${color}Font`} ${preserveLineBreaks} ${removeDefaultStyles} ${toUppercase}`}>{props.children}</h3>;
        case 'Header4':
            return <h4 className={`${styles.header4} ${`${color}Font`} ${preserveLineBreaks} ${removeDefaultStyles} ${toUppercase}`}>{props.children}</h4>;
        case 'Text1':
            return <p className={`${styles.text1} ${`${color}Font`} ${preserveLineBreaks} ${removeDefaultStyles} ${toUppercase}`}>{props.children}</p>;
        case 'Text2':
            return <p className={`${styles.text2} ${`${color}Font`} ${preserveLineBreaks} ${removeDefaultStyles} ${toUppercase}`}>{props.children}</p>;
        case 'Text3':
            return <p className={`${styles.text3} ${`${color}Font`} ${preserveLineBreaks} ${removeDefaultStyles} ${toUppercase}`}>{props.children}</p>;
        case 'Text4':
            return <p className={`${styles.text4} ${`${color}Font`} ${preserveLineBreaks} ${removeDefaultStyles} ${toUppercase}`}>{props.children}</p>;
        default:
            return <p className={`${styles.text3} ${`${color}Font`} ${preserveLineBreaks} ${removeDefaultStyles} ${toUppercase}`}>{props.children}</p>;
    }
};
