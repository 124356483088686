import { type JSX, useState } from 'react';
import { Alert, Form, Modal } from 'react-bootstrap';

import { TPRMApi } from 'Api/TPRM/TPRMApi';
import { Button } from 'Components/Buttons/Buttons';
import { IconButton } from 'Components/Buttons/IconButton';
import { FormFieldDatePicker } from 'Components/FormField/FormFieldDatePicker/FormFieldDatePicker';
import { ModalHeader } from 'Components/Modal/ModalHeader';
import { Text } from 'Components/Text/Text';
import { ICON_CLOSE, ICON_DELETE_REMOVE } from 'Config/Icons';
import { iso8601ToJsDate, jsDateToIso8601 } from 'Helpers/DateTimeUtils/DateTimeUtils';
import { ScheduleFrequency } from 'Models/ScheduleFrequency';
import { SaveThirdPartyServiceRequest, Service, riskRatingAsString } from 'Models/TPRM';

import styles from './ServiceAssessmentDueDateModal.module.css';

export interface ServiceAssessmentDueDateModalProps {
    hideModal: () => void;
    service: Service;
    serviceAssessmentDueDateSet: () => void;
    tprmApi: TPRMApi;
}

interface FormState {
    isLoading?: boolean;
    successMessage?: string;
    failureMessage?: string;
}

export const ServiceAssessmentDueDateModal = (props: ServiceAssessmentDueDateModalProps): JSX.Element => {
    const [formState, setFormState] = useState<FormState>({});
    const [dueDate, setDueDate] = useState<Date | undefined>(props.service.assessment_workflow_setup.due_date ? iso8601ToJsDate(props.service.assessment_workflow_setup.due_date) : undefined);

    const handleChangeDate = (date: Date): void => {
        setDueDate(date);
    };

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>): Promise<void> => {
        event.preventDefault();
        try {
            setFormState({ isLoading: true });
            const request: SaveThirdPartyServiceRequest = {
                name: props.service.name,
                description: props.service.description,
                vendor_service_manager_user_id: props.service.vendor_service_manager_user_id,
                delegates: props.service.delegates,
                vendor_contacts: props.service.vendor_contacts,
                responsible_organization: props.service.responsible_organization,
                assessment_due_date: dueDate ? jsDateToIso8601(dueDate) : null,
                irq_target_completion_date: props.service.assessment_workflow_setup.irq_target_completion_date,
            };
            await props.tprmApi.saveThirdPartyService(request, props.service.vendor_id, props.service.id);
            props.serviceAssessmentDueDateSet();
            setFormState({ successMessage: 'Due date saved.', isLoading: false });
        } catch (error) {
            handleRequestError(error);
        }
    };

    const handleRequestError = (error: Error) => {
        setFormState({ failureMessage: error.message, isLoading: false });
    };

    const getScheduleFrequencyText = (scheduleFrequency: ScheduleFrequency) => {
        switch (scheduleFrequency) {
            case ScheduleFrequency.DAYS:
                return 'day(s)';
            case ScheduleFrequency.MONTHS:
                return 'month(s)';
            case ScheduleFrequency.YEARS:
                return 'year(s)';
        }
    };

    const tooltip = 'If this field is left blank, then no assessment due date will be set.';

    return (
        <Modal show onHide={props.hideModal} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
            <Modal.Body className={'modalFromBody'}>
                {formState.successMessage && <Alert variant="success">{formState.successMessage}</Alert>}
                {formState.failureMessage && <Alert variant="danger">{formState.failureMessage}</Alert>}
                <Form noValidate onSubmit={handleSubmit}>
                    <ModalHeader text="Schedule Service Assessment" />

                    {props.service.assessment_workflow_setup.schedule && (
                        <div>
                            <Text>
                                Based on a {riskRatingAsString(props.service.inherent_risk_score ?? 0)} inherent risk rating, the frequency for conducting a service assessment of {props.service.name} is every {props.service.assessment_workflow_setup.schedule.schedule_number} {getScheduleFrequencyText(ScheduleFrequency[props.service.assessment_workflow_setup.schedule.schedule_frequency])}.
                            </Text>
                        </div>
                    )}
                    <div className={styles.datePicker}>
                        <FormFieldDatePicker dateFormat="MM/dd/yyyy" selected={dueDate} handleChange={handleChangeDate} formFieldId="dueDate" formFieldLabel="Due Date" placeholder={'MM/DD/YYYY'} invalidMessage={'Please enter a valid date (MM/DD/YYYY)'} tooltip={tooltip} />
                        <div className={styles.clearIcon}>
                            <IconButton aria-label="clear-date" onClick={() => setDueDate(undefined)} fontAwesomeImage={ICON_DELETE_REMOVE} />
                        </div>
                    </div>

                    <div className={'modalFormButtonContainer'}>
                        <Button variant="secondary" onClick={props.hideModal} fontAwesomeImage={ICON_CLOSE}>
                            Close
                        </Button>
                        <Button variant="submit" isLoading={formState.isLoading === true} loadingText="Saving...">
                            Save
                        </Button>
                    </div>
                </Form>
            </Modal.Body>
        </Modal>
    );
};
