import { type JSX, useMemo, useState } from 'react';
import { Form } from 'react-bootstrap';
import { useParams } from 'react-router';

import { ControlsApi } from 'Api/Controls/ControlsApi';
import { DocumentApi } from 'Api/Document/DocumentApi';
import { Button } from 'Components/Buttons/Buttons';
import { FileManagementArea, FileManagementAreaRestriction, useFileManagementArea } from 'Components/Files/FileManagementArea';
import { FormFieldSelect } from 'Components/FormField/FormFieldSelect/FormFieldSelect';
import { FormFieldText } from 'Components/FormField/FormFieldText/FormFieldText';
import { FormFieldTextArea } from 'Components/FormField/FormFieldTextArea/FormFieldTextArea';
import { ModalHeader } from 'Components/Modal/ModalHeader';
import { MultipleControlMapping, MultipleControlMappingProps } from 'Components/MultipleControlMapping/MultipleControlMapping';
import { LinkButtonToast, TextToast } from 'Components/Toast/Toast';
import { getOperationalControlIdentifierString } from 'Helpers/ControlFormatter/ControlFormatter';
import { validateUrl } from 'Helpers/InputValidation';
import { getFrameworkGroupControlURL } from 'Helpers/URLBuilder/URLBuilder';
import { CreateManualEvidenceRequest } from 'Models/ControlEvidence';
import { ValidationError } from 'Models/ErrorTypes';
import { EffectivenessSelectOptions, OperationalControl } from 'Models/OperationalControls';

import styles from './UploadControlEvidenceForm.module.css';

export interface UploadControlEvidenceFormProps {
    controlsApi: ControlsApi;
    documentApi: DocumentApi;
    controls: OperationalControl[];
    initialAssociatedControl: OperationalControl;
}

export interface UrlParams {
    controlFramework: string;
    controlGroupId: string;
    controlId: string;
}

interface FormState {
    isAddingEvidence: boolean;
    evidenceAdded: boolean;
    successMessage?: string;
    failureMessage?: string;
}

export const UploadControlEvidenceForm = (props: UploadControlEvidenceFormProps): JSX.Element => {
    const { controlFramework, controlGroupId, controlId } = useParams<keyof UrlParams>() as UrlParams;
    const controlIdentifier = getOperationalControlIdentifierString(controlFramework, controlGroupId, controlId);
    const [submitRequestWithManagedFiles, fileManagementHookValues] = useFileManagementArea(props.documentApi, [], FileManagementAreaRestriction.UP_TO_ONE_FILE);

    const [formState, setFormState] = useState<FormState>({ isAddingEvidence: false, evidenceAdded: false });
    const [comment, setComment] = useState<string>();
    const [effectiveness, setEffectiveness] = useState<number | undefined>();
    const [externalUrl, setExternalUrl] = useState<string>();
    const [controlsToAssociate, setControlsToAssociate] = useState<string[]>([controlIdentifier]);

    const multipleControlMappingProps: MultipleControlMappingProps | undefined = useMemo(
        () => ({
            controls: props.controls,
            handleControlChange: (controls: string[]) => setControlsToAssociate(controls),
            currentMappedControlIdentifiers: controlsToAssociate,
        }),
        [props.controls, controlsToAssociate]
    );

    const addEvidence = async (event: React.FormEvent<HTMLFormElement>): Promise<void> => {
        event.preventDefault();
        setFormState({ isAddingEvidence: true, evidenceAdded: false, successMessage: undefined, failureMessage: undefined });

        try {
            validateRequest();

            await submitRequestWithManagedFiles(async (fileUpdates) => {
                const request: CreateManualEvidenceRequest = {
                    associated_controls: controlsToAssociate,
                    evidence_comment: comment,
                    evidence_effectiveness: effectiveness,
                    external_url: externalUrl && externalUrl.length > 0 ? externalUrl : undefined,
                    file_updates: fileUpdates,
                };

                await props.controlsApi.addEvidence(controlFramework, controlGroupId, controlId, request);
            });

            setFormState({ successMessage: 'Evidence uploaded.', failureMessage: undefined, evidenceAdded: true, isAddingEvidence: false });
        } catch (error) {
            setFormState({ successMessage: undefined, failureMessage: error.message, evidenceAdded: false, isAddingEvidence: false });
        }
    };

    const validateRequest = (): void => {
        if (externalUrl && externalUrl.length > 0) {
            const validateUrlResult = validateUrl(externalUrl);
            if (validateUrlResult['valid'] !== true) {
                throw new ValidationError(validateUrlResult['message']);
            }
        }
    };

    return (
        <>
            {formState.successMessage && <LinkButtonToast variant="success" clearToast={() => setFormState({ isAddingEvidence: false, successMessage: undefined, evidenceAdded: formState.evidenceAdded })} linkButtonText={'Return to control'} linkButtonTo={`${getFrameworkGroupControlURL(props.initialAssociatedControl.identifier)}#evidence`} text={formState.successMessage} />}
            {formState.failureMessage && <TextToast clearToast={() => setFormState({ isAddingEvidence: false, failureMessage: undefined, evidenceAdded: formState.evidenceAdded })} text={formState.failureMessage} variant="failure" />}
            <Form noValidate onSubmit={addEvidence}>
                <FileManagementArea disabled={false} documentApi={props.documentApi} fileManagementHookValues={fileManagementHookValues} />
                <div>
                    <FormFieldTextArea value={comment} handleChange={(event: React.FormEvent<HTMLInputElement>): void => setComment(event.currentTarget.value)} formFieldId="comment" formFieldLabel="Comment" />
                </div>
                <div>
                    <FormFieldText handleChange={(event: React.FormEvent<HTMLInputElement>): void => setExternalUrl(event.currentTarget.value)} formFieldId="externalUrl" formFieldLabel="Link" tooltip={`An optional hyperlink to the evidence.`} value={externalUrl ?? ''} />
                </div>
                <div className={styles.effectivenessContainer}>
                    <FormFieldSelect disabled={!(comment || fileManagementHookValues.newFiles.length > 0)} options={EffectivenessSelectOptions} handleChange={(value) => setEffectiveness(value as number)} formFieldId="effectiveness" selectedOption={effectiveness} formFieldLabel="Effectiveness" tooltip={'Effectiveness of the control based on this evidence. This will not set the effectiveness of the control but will inform the reviewer in determining the control effectiveness'} />
                </div>
                <ModalHeader text="Map Evidence to Controls" />
                <MultipleControlMapping {...multipleControlMappingProps} />
                <div className={styles.submitButton}>
                    <Button variant="submit" disabled={formState.evidenceAdded} isLoading={formState.isAddingEvidence} loadingText="Saving...">
                        Save
                    </Button>
                </div>
            </Form>
        </>
    );
};
