import { type JSX, useEffect, useState } from 'react';
import { useParams } from 'react-router';

import { DocumentApi } from 'Api/Document/DocumentApi';
import { TPRMApi } from 'Api/TPRM/TPRMApi';
import { useCachedData } from 'Components/Context/CachedDataContext';
import { Breadcrumb, BreadcrumbLink, BreadcrumbText } from 'Components/Nav/Breadcrumb/Breadcrumb';
import { PageLayoutDefault } from 'Components/PageLayout/PageLayoutDefault';
import { Placeholder } from 'Components/Placeholder/Placeholder';
import { Text } from 'Components/Text/Text';
import { FOLDERS, THIRD_PARTIES, TPRM } from 'Config/Paths';
import { ResponseModel } from 'Models/ResponseModel';
import { FolderTypes, FolderVersion, ThirdPartyResponse } from 'Models/TPRM';
import { OptionType } from 'Models/Types/GlobalType';

import { ManageFolderForm, ManageFolderFormProps } from '../Components/ManageFolderForm';

interface UrlParams {
    third_party_id: string;
    folder_id: string;
}

export enum TprmFolderPageType {
    CREATE_NEW,
    ADD_VERSION,
    MANAGE,
}

export interface AddNewFolderVersionProps {
    tprmApi: TPRMApi;
    documentApi: DocumentApi;
    pageType: TprmFolderPageType;
}

export const ManageFolders = (props: AddNewFolderVersionProps) => {
    const { third_party_id, folder_id } = useParams<keyof UrlParams>() as UrlParams;
    const versionExistsAlready = folder_id !== undefined;
    const [errorMessage, setErrorMessage] = useState<string>();
    const [thirdPartyResponse, setThirdPartyResponse] = useState<ThirdPartyResponse>();
    const [folderTypes, setFolderTypes] = useState<FolderTypes>();
    const cachedData = useCachedData();
    const [currentVersion, setCurrentVersion] = useState<FolderVersion | null | undefined>(versionExistsAlready ? undefined : null);
    const [folderTypeOptions, setFolderTypeOptions] = useState<OptionType[]>();

    useEffect(() => {
        const getFolderTypes = async (): Promise<void> => {
            try {
                const response: ResponseModel<FolderTypes> = await props.tprmApi.getAllFolderTypes();
                setFolderTypes(response.data);
            } catch (error) {
                setErrorMessage(error.message);
            }
        };
        const getThirdPartyDetails = async (): Promise<void> => {
            try {
                const detailedThirdPartyResponse = await props.tprmApi.getThirdPartyDetails(third_party_id);
                setThirdPartyResponse(detailedThirdPartyResponse.data);
            } catch (error) {
                setErrorMessage(error.message);
            }
        };
        getFolderTypes();
        getThirdPartyDetails();
    }, [props.tprmApi, third_party_id]);

    useEffect(() => {
        if (cachedData.users === undefined) {
            return;
        }

        if (!versionExistsAlready) {
            setCurrentVersion(null);
            return;
        }

        const getEffectiveVersion = async (): Promise<void> => {
            try {
                const response = await props.tprmApi.getAllFolderVersions(third_party_id, folder_id);
                const currentVersion = response.data.current_version;
                setCurrentVersion(currentVersion);
            } catch (error) {
                setErrorMessage(error.message);
            }
        };
        getEffectiveVersion();
    }, [props.tprmApi, third_party_id, folder_id, cachedData.users, versionExistsAlready]);

    /**
     * Constructs an array of Folder Type options.
     * The Folder Type options remain undefined (causing the page to show the placeholder) until both the current version (if it exists) and the client's configured Type options are fetched.
     *
     * The client's configured Types are always selectable.
     * The current Type of the Folder (if an existing Folder is being managed); if no Types are configured at the client level, then the existing Type is the only option.
     */
    useEffect(() => {
        if (currentVersion === undefined || folderTypes === undefined) {
            return;
        }

        const currentFolderType = currentVersion?.type;
        const clientFolderTypes = folderTypes ? folderTypes.type_set : [];
        let choices: string[];

        if (currentFolderType) {
            choices = clientFolderTypes.includes(currentFolderType) ? clientFolderTypes : [currentFolderType, ...clientFolderTypes];
        } else {
            choices = clientFolderTypes;
        }

        setFolderTypeOptions(choices.map((folderType) => ({ value: folderType, label: folderType })).sort((a, b) => (a.label > b.label ? 1 : -1)));
    }, [currentVersion, folderTypes]);

    const buildBreadcrumb = (thirdPartyName: string, currentVersion?: FolderVersion | null): JSX.Element => {
        if (folder_id && currentVersion) {
            if (props.pageType === TprmFolderPageType.ADD_VERSION) {
                return (
                    <Breadcrumb textColor="blue">
                        <BreadcrumbLink link={`/${TPRM}/${THIRD_PARTIES}`}>{`${thirdPartyName}`}</BreadcrumbLink>
                        <BreadcrumbLink link={`/${TPRM}/${THIRD_PARTIES}/${third_party_id}/${FOLDERS}`}>Third-Party Documents</BreadcrumbLink>
                        <BreadcrumbLink link={`/${TPRM}/${THIRD_PARTIES}/${third_party_id}/${FOLDERS}/${folder_id}`}>{currentVersion.name}</BreadcrumbLink>
                        <BreadcrumbText>Create New Folder Version</BreadcrumbText>
                    </Breadcrumb>
                );
            } else {
                return (
                    <Breadcrumb textColor="blue">
                        <BreadcrumbLink link={`/${TPRM}/${THIRD_PARTIES}`}>{`${thirdPartyName}`}</BreadcrumbLink>
                        <BreadcrumbLink link={`/${TPRM}/${THIRD_PARTIES}/${third_party_id}/${FOLDERS}/${folder_id}`}>{currentVersion.name}</BreadcrumbLink>
                        <BreadcrumbText>Manage Folder</BreadcrumbText>
                    </Breadcrumb>
                );
            }
        } else {
            return (
                <Breadcrumb textColor="blue">
                    <BreadcrumbLink link={`/${TPRM}/${THIRD_PARTIES}`}>{`${thirdPartyName}`}</BreadcrumbLink>
                    <BreadcrumbLink link={`/${TPRM}/${THIRD_PARTIES}/${third_party_id}/${FOLDERS}`}>Third-Party Documents</BreadcrumbLink>
                    <BreadcrumbText>Create New Folder</BreadcrumbText>
                </Breadcrumb>
            );
        }
    };

    const getPageTitle = (currentVersion?: FolderVersion | null): string => {
        if (folder_id && currentVersion) {
            if (props.pageType === TprmFolderPageType.ADD_VERSION) {
                return 'Create New Folder Version';
            } else {
                return 'Manage Folder';
            }
        } else {
            return 'Create New Folder';
        }
    };

    if (errorMessage) {
        return <Text>{errorMessage}</Text>;
    } else if (!(thirdPartyResponse && cachedData.users && folderTypeOptions && currentVersion !== undefined)) {
        return <Placeholder />;
    } else if (folderTypeOptions.length === 0) {
        return <Text>At least one folder type must be defined in "Settings" before a folder can be created.</Text>;
    } else {
        const manageFolderFormProps: ManageFolderFormProps = {
            documentApi: props.documentApi,
            folderTypeOptions: folderTypeOptions,
            pageType: props.pageType,
            thirdParty: thirdPartyResponse,
            tprmApi: props.tprmApi,
            currentVersion: currentVersion ?? undefined,
        };

        return (
            <PageLayoutDefault
                headerBreadcrumb={buildBreadcrumb(thirdPartyResponse.name, currentVersion)}
                headerTitle={getPageTitle(currentVersion)}
                body={[
                    {
                        title: 'Folder Details',
                        content: <ManageFolderForm {...manageFolderFormProps} />,
                    },
                ]}
            />
        );
    }
};
