import { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router';

import { DocumentApi } from 'Api/Document/DocumentApi';
import { TPRMApi } from 'Api/TPRM/TPRMApi';
import { PageBackground } from 'Components/Containers/PageBackground/PageBackground';
import { PageContent } from 'Components/Containers/PageContent/PageContent';
import { Breadcrumb, BreadcrumbLink, BreadcrumbText } from 'Components/Nav/Breadcrumb/Breadcrumb';
import { PageLayoutDefault } from 'Components/PageLayout/PageLayoutDefault';
import { Placeholder } from 'Components/Placeholder/Placeholder';
import { Text } from 'Components/Text/Text';
import { GENERIC_ERROR_MESSAGE, UNAUTHORIZED_MESSAGE } from 'Config/Errors';
import { DASHBOARDS, SERVICES, THIRD_PARTIES, TPRM } from 'Config/Paths';
import { isForbiddenResponseError } from 'Helpers/Auth/ResponseUtil';
import { Navigator } from 'Helpers/Navigator';
import { InherentRiskQuestionnaireResponse, RiskRating, Service } from 'Models/TPRM';

import { InherentRiskQuestionnaireForm, InherentRiskQuestionnaireFormProps } from './Components/InherentRiskQuestionnaireForm';
import styles from './InherentRiskQuestionnaire.module.css';

export interface UrlParams {
    service_id: string;
    third_party_id: string;
}

export enum Modals {
    ClearIRQModal,
    ConfirmSubmitIrqModal,
    None,
}

export interface InherentRiskQuestionnaireProps {
    documentApi: DocumentApi;
    tprmApi: TPRMApi;
    navigator: Navigator;
}

type ActiveInherentRisks = Exclude<RiskRating, RiskRating.INACTIVE>;
export type DDQQuestionCount = Record<ActiveInherentRisks, number>;

export const InherentRiskQuestionnaire = (props: InherentRiskQuestionnaireProps) => {
    const params = useParams<keyof UrlParams>() as UrlParams;
    const [loadingErrorOccurred, setLoadingErrorOccurred] = useState(false);
    const [tprmAccessDenied, setTprmAccessDenied] = useState<boolean>();
    const [serviceResponse, setServiceResponse] = useState<Service>();
    const [thirdPartyServiceTitle, setThirdPartyServiceTitle] = useState<string>();
    const [questionnaire, setQuestionnaire] = useState<InherentRiskQuestionnaireResponse>();
    const [dueDiligenceQuestionCount, setDueDiligenceQuestionCount] = useState<DDQQuestionCount>();

    const getServiceAndQuestionnaire = useCallback(async (): Promise<void> => {
        try {
            const service = (await props.tprmApi.getServiceDetails(params.third_party_id, params.service_id)).data;
            setServiceResponse(service);
            setThirdPartyServiceTitle(`${service.vendor_name} - ${service.name}`);

            const questionnaire = (await props.tprmApi.getInherentRiskQuestionnaire(params.third_party_id, params.service_id)).data!;
            setQuestionnaire(questionnaire);

            const dueDiligenceQuestionnaireConfiguration = (await props.tprmApi.getQuestionnaireConfiguration()).data;

            const questionCountMap = { [RiskRating.LOW]: 0, [RiskRating.LOW_MODERATE]: 0, [RiskRating.MODERATE]: 0, [RiskRating.MODERATE_HIGH]: 0, [RiskRating.HIGH]: 0 };

            const questionCountByRiskRating = dueDiligenceQuestionnaireConfiguration.control_frameworks
                .flatMap((framework) => framework.control_groups.flatMap((controlGroup) => controlGroup.controls.flatMap((control) => control.questions.flatMap((question) => question.mapped_risk_ratings))))
                .reduce((acc, curr) => {
                    if (curr !== RiskRating.INACTIVE) {
                        acc[curr]++;
                    }
                    return acc;
                }, questionCountMap);

            setDueDiligenceQuestionCount(questionCountByRiskRating);
        } catch (error) {
            if (isForbiddenResponseError(error)) {
                console.log(error);
                setTprmAccessDenied(true);
            } else {
                setLoadingErrorOccurred(true);
            }
        }
    }, [params.third_party_id, params.service_id, props.tprmApi]);

    useEffect(() => {
        getServiceAndQuestionnaire();
    }, [getServiceAndQuestionnaire]);

    if (tprmAccessDenied) {
        return (
            <PageBackground color="white">
                <PageContent>
                    <div className={styles.zeroStateContainer}>
                        <Text>{UNAUTHORIZED_MESSAGE}</Text>
                    </div>
                </PageContent>
            </PageBackground>
        );
    }

    if (loadingErrorOccurred) {
        return <Text>{GENERIC_ERROR_MESSAGE}</Text>;
    }

    if (!(questionnaire && serviceResponse && thirdPartyServiceTitle && dueDiligenceQuestionCount)) {
        return <Placeholder />;
    }

    const inherentRiskQuestionnaireFormProps: InherentRiskQuestionnaireFormProps = {
        ddqQuestionCount: dueDiligenceQuestionCount,
        defaultQuestionnaire: questionnaire,
        documentApi: props.documentApi,
        onFormSubmitted: getServiceAndQuestionnaire,
        serviceResponse,
        tprmApi: props.tprmApi,
    };

    return (
        <PageLayoutDefault
            headerBreadcrumb={
                <Breadcrumb textColor="blue">
                    <BreadcrumbLink link={`/${TPRM}/${SERVICES}`}>Third-Party Risk Management</BreadcrumbLink>
                    <BreadcrumbLink link={`/${TPRM}/${THIRD_PARTIES}/${serviceResponse.vendor_id}/${SERVICES}/${serviceResponse.id}/${DASHBOARDS}`}>{thirdPartyServiceTitle}</BreadcrumbLink>
                    <BreadcrumbText>Inherent Risk Questionnaire</BreadcrumbText>
                </Breadcrumb>
            }
            headerTitle="Inherent Risk Questionnaire"
            headerDescription="The recommended inherent risk rating will be updated as questions are answered. The inherent risk rating drives prioritization of controls to reduce organizational risk. Questions are based on the inherent risk questionnaire configuration at the time when this third-party service's assessment workflow was started, and therefore may differ from the current inherent risk questionnaire configuration."
            body={[
                {
                    content: <InherentRiskQuestionnaireForm {...inherentRiskQuestionnaireFormProps} />,
                },
            ]}
        />
    );
};
