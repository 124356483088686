import { type JSX, useEffect, useState } from 'react';

import { ArtificialIntelligenceApi } from 'Api/ArtificialIntelligence/ArtificialIntelligenceApi';
import { ComplianceRequirementsApi } from 'Api/ComplianceRequirements/ComplianceRequirementsApi';
import { DocumentApi } from 'Api/Document/DocumentApi';
import { Breadcrumb, BreadcrumbLink, BreadcrumbText } from 'Components/Nav/Breadcrumb/Breadcrumb';
import { PageLayoutDefault } from 'Components/PageLayout/PageLayoutDefault';
import { Placeholder } from 'Components/Placeholder/Placeholder';
import { LinkButtonToast, TextToast } from 'Components/Toast/Toast';
import { GOVERNANCE } from 'Config/Paths';
import { Regulation } from 'Models/ComplianceRequirements';

import { ArtificialIntelligenceGovernanceForm } from './ArtificialIntelligenceGovernanceForm';
import { AI_TAB_KEY } from '../GovernanceLibrary/GovernanceLibrary';

export interface ArtificialIntelligenceGovernanceProps {
    artificialIntelligenceApi: ArtificialIntelligenceApi;
    complianceRequirementsApi: ComplianceRequirementsApi;
    documentApi: DocumentApi;
}

export const ArtificialIntelligenceGovernance = (props: ArtificialIntelligenceGovernanceProps): JSX.Element => {
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [failureMessage, setFailureMessage] = useState<string>();
    const [successMessage, setSuccessMessage] = useState<string>();
    const [regulations, setRegulations] = useState<Regulation[]>([]);

    /** Get a list of available Regulations so they can be rendered as selectable options in the form. */
    useEffect(() => {
        const getAvailableRegulations = async (): Promise<void> => {
            try {
                const availableRegulationResponse = await props.complianceRequirementsApi.getAvailableRegulations();
                setRegulations(availableRegulationResponse.data);
            } catch (error) {
                setFailureMessage(error.message);
            } finally {
                setIsLoading(false);
            }
        };
        getAvailableRegulations();
    }, [props.complianceRequirementsApi]);

    if (isLoading) {
        return <Placeholder />;
    } else {
        return (
            <>
                {failureMessage && <TextToast clearToast={() => setFailureMessage(undefined)} text={failureMessage} variant="failure" />}
                {successMessage && <LinkButtonToast variant="success" clearToast={() => setSuccessMessage(undefined)} linkButtonText={'Return to Governance Integration'} linkButtonTo={`/${GOVERNANCE}#${AI_TAB_KEY}`} text={successMessage} />}
                <PageLayoutDefault
                    headerBreadcrumb={
                        <Breadcrumb textColor="blue">
                            <BreadcrumbLink link={`/${GOVERNANCE}#${AI_TAB_KEY}`}>Governance Integration</BreadcrumbLink>
                            <BreadcrumbText>SummIT Guide™ Policy Generator</BreadcrumbText>
                        </Breadcrumb>
                    }
                    headerTitle="SummIT Guide™ Policy Generator"
                    headerDescription="Submitting this form will generate a new governance document using SummIT Guide™. SummIT Guide™ will utilize the information you provide to tailor the document it creates to your organization's specific needs. The process will take several minutes, and once complete the new document will appear in the table on the previous page."
                    body={[
                        {
                            content: <ArtificialIntelligenceGovernanceForm artificialIntelligenceApi={props.artificialIntelligenceApi} regulations={regulations} documentApi={props.documentApi} setFailureMessage={setFailureMessage} setSuccessMessage={setSuccessMessage} />,
                        },
                    ]}
                />
            </>
        );
    }
};
