/*
	FrameworkDashboard.tsx -- Main Framework dashboard for operational controls.
*/
import { type JSX, useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router';

import { AlertsLimitsApi } from 'Api/AlertsLimits/AlertsLimitsApi';
import { ControlsApi } from 'Api/Controls/ControlsApi';
import { PageHeaderTabs } from 'Components/Containers/PageHeaderTabs/PageHeaderTabs';
import { RBACContext, Role, userHasAuthorizedRole } from 'Components/Context/RBACContext';
import { Placeholder } from 'Components/Placeholder/Placeholder';
import { Tab } from 'Components/Tabs/PrimaryTabs/PrimaryTabs';
import { Text } from 'Components/Text/Text';
import { UNAUTHORIZED_MESSAGE } from 'Config/Errors';
import { ControlFrameworkResponse } from 'Models/OperationalControls';

import styles from './FrameworkDashboard.module.css';
import { FrameworkSummary, FrameworkSummaryProps } from './FrameworkSummary/FrameworkSummary';

export interface FrameworkDashboardProps {
    controlsApi: ControlsApi;
    alertsLimitsApi: AlertsLimitsApi;
}

export const FrameworkDashboard = (props: FrameworkDashboardProps) => {
    const rbacContext = useContext(RBACContext);
    const location = useLocation();
    const navigate = useNavigate();
    const [controlFrameworks, setControlFrameworks] = useState<ControlFrameworkResponse[]>();

    useEffect(() => {
        const getControlFrameworks = async (): Promise<void> => {
            try {
                const controlFrameworkResponse = await props.controlsApi.getControlFrameworks();
                const controlFrameworks = controlFrameworkResponse.data;

                // Ensure the "Custom Controls" Framework is the last tab.
                const customControlsIndex = controlFrameworks.findIndex((framework) => framework.is_custom);
                if (customControlsIndex !== -1) {
                    const removedCustomFramework = controlFrameworks.splice(customControlsIndex, 1)[0];
                    controlFrameworks.push(removedCustomFramework);
                }

                setControlFrameworks(controlFrameworks);
            } catch (error) {
                handleRequestError(error);
            }
        };

        if (rbacContext && userHasAuthorizedRole(rbacContext.roles, [Role.ADMIN, Role.CONTROL_USER])) {
            getControlFrameworks();
        }
    }, [props.controlsApi, rbacContext]);

    const handleRequestError = (err: Error): void => {
        console.error('Error: ', err);
    };

    const getFrameworkSummaries = (controlFrameworks: ControlFrameworkResponse[]): JSX.Element[] => {
        const frameworkSummaries: JSX.Element[] = [];
        controlFrameworks.forEach((framework, index) => {
            const frameworkSummaryProps: FrameworkSummaryProps = {
                framework: framework,
                controlsApi: props.controlsApi,
                alertsLimitsApi: props.alertsLimitsApi,
            };

            frameworkSummaries.push(
                <Tab eventKey={framework.control_framework} title={framework.control_framework} key={index} unmountOnExit={true}>
                    <FrameworkSummary {...frameworkSummaryProps} />
                </Tab>
            );
        });

        return frameworkSummaries;
    };

    const onTabIndexChanged = (selectedTabIndex?: string): void => {
        if (controlFrameworks) {
            const framework = controlFrameworks.find((framework) => framework.control_framework === selectedTabIndex);
            if (framework) {
                const encodedFrameworkName = encodeURIComponent(framework.control_framework);
                navigate(`${location.pathname}#${encodedFrameworkName}`, { replace: true });
            }
        }
    };

    const getDefaultTabIndex = (controlFrameworks: ControlFrameworkResponse[]): string => {
        if (location.hash && location.hash.length > 0) {
            let strippedHash = location.hash.substring(1);
            strippedHash = decodeURIComponent(strippedHash);
            for (const [, framework] of controlFrameworks.entries()) {
                if (strippedHash === framework.control_framework) {
                    return framework.control_framework;
                }
            }
        }

        return controlFrameworks[0].control_framework;
    };

    if (rbacContext && userHasAuthorizedRole(rbacContext.roles, [Role.ADMIN, Role.CONTROL_USER])) {
        if (controlFrameworks && controlFrameworks.length > 0) {
            return (
                <PageHeaderTabs title="Operational Controls" defaultActiveTab={getDefaultTabIndex(controlFrameworks)} onSelect={onTabIndexChanged}>
                    {getFrameworkSummaries(controlFrameworks)}
                </PageHeaderTabs>
            );
        } else if (controlFrameworks && controlFrameworks.length === 0) {
            return (
                <div className={styles.allControlsDisabledContainer}>
                    <Text>There are no enabled control frameworks.</Text>
                </div>
            );
        }
    } else {
        return <Text>{UNAUTHORIZED_MESSAGE}</Text>;
    }

    return <Placeholder />;
};
