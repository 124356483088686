import moment from 'moment';
import type { JSX } from 'react';

import { SegmentedProgressBarChart } from 'Components/BaseCharts/SegmentedProgressBarChart';
import { StackedAreaChart } from 'Components/BaseCharts/StackedAreaChart';
import { CSSColors } from 'Components/Colors';
import { Text } from 'Components/Text/Text';
import { ComplianceHistoryFFIECCAT, ComplianceSummary } from 'Models/Dashboards';

import styles from './FFIECRequirementSummary.module.css';

export interface FFIECRequirementSummaryProps {
    complianceSummarySnapshots: ComplianceHistoryFFIECCAT;
    handleChartClick: (item: string) => void;
    handleProgressBarClicked: (requirementType: string) => void;
}

export const FFIECRequirementSummary = (props: FFIECRequirementSummaryProps): JSX.Element => {
    const lightBlue = CSSColors.LIGHT_BLUE;
    const yellow = CSSColors.YELLOW;
    const gray = CSSColors.GRAY;
    const darkGray = CSSColors.DARK_GRAY;

    // The API returns 5 months of historic data recorded by batch job, while the last month is current data formatted as a snapshot.
    // The last snapshot is current data, and like all the other (true) snapshots, it is tailored to the maturity level selected by the user (specifically for this component).
    // This current snapshot must be used, rather than the summary returned for use elsewhere on the dashboard, as the latter is tailored to the target maturity selected in Company Settings.
    const currentSnapshot = props.complianceSummarySnapshots.periodic_summaries[props.complianceSummarySnapshots.periodic_summaries.length - 1];
    const complianceSummary: ComplianceSummary = {
        regulation: currentSnapshot.summary.regulation,
        met_count: currentSnapshot.summary.met_count,
        not_met_count: currentSnapshot.summary.not_met_count,
        not_mapped_count: currentSnapshot.summary.not_mapped_count,
        awaiting_assessments_count: currentSnapshot.summary.awaiting_assessments_count,
    };

    const totalRequirements = complianceSummary.awaiting_assessments_count + complianceSummary.met_count + complianceSummary.not_mapped_count + complianceSummary.not_met_count;
    const totalNotMet = complianceSummary.awaiting_assessments_count + complianceSummary.not_mapped_count + complianceSummary.not_met_count;

    const requirementMaximum = Math.ceil(totalRequirements / 50) * 50;

    return (
        <div className={styles.container}>
            <div className={styles.requirements}>
                <div className={styles.requirementsMet}>
                    <Text color="white" variant="Text2" noStyles>
                        Requirements Met
                    </Text>
                    <div className={styles.assessmentNumber}>{`${((props.complianceSummarySnapshots.current_met_requirements_count / props.complianceSummarySnapshots.current_total_requirements_count) * 100).toFixed(1)}%`}</div>
                    <SegmentedProgressBarChart
                        name={complianceSummary.regulation}
                        data={[
                            { value: complianceSummary.met_count, name: 'Met', color: lightBlue },
                            { value: totalNotMet, name: 'Not Met', color: darkGray },
                        ]}
                        chartDimensions={{ width: '100%', height: 15 }}
                    />
                    <div className={styles.requirementsMetBreakdown}>
                        <Text color="darkGray" variant="Text2" noStyles>
                            {complianceSummary.met_count} of {totalRequirements}
                        </Text>
                    </div>
                </div>
                <div className={styles.requirementBreakdown}>
                    <Text variant="Text2" color="white">
                        Requirement Breakdown
                    </Text>
                    <SegmentedProgressBarChart
                        name={complianceSummary.regulation}
                        data={[
                            { value: complianceSummary.met_count, name: 'Met', color: lightBlue },
                            { value: complianceSummary.not_met_count, name: 'Unmet', color: yellow },
                            { value: complianceSummary.awaiting_assessments_count, name: 'Not Assessed', color: gray },
                            { value: complianceSummary.not_mapped_count, name: 'Not Mapped', color: darkGray },
                        ]}
                        onChartClick={props.handleProgressBarClicked}
                        chartDimensions={{ width: '100%', height: 50 }}
                    />
                </div>
            </div>
            <div className={styles.requirementGroups}>
                {props.complianceSummarySnapshots.current_domain_statuses.map((domain) => (
                    <div key={domain.name} className={styles.group}>
                        <Text color="white" variant="Text3" noStyles>
                            {domain.name}
                        </Text>
                        <div className={styles.assessmentNumber}>{((domain.met_requirements_count / domain.total_requirements_count) * 100).toFixed(1)}%</div>
                        <SegmentedProgressBarChart
                            name={domain.name}
                            data={[
                                { value: domain.met_requirements_count, name: 'Met', color: lightBlue },
                                { value: domain.total_requirements_count - domain.met_requirements_count, name: 'Not Met', color: darkGray },
                            ]}
                            chartDimensions={{ width: '100%', height: 15 }}
                        />
                        <div className={styles.requirementsMetBreakdown}>
                            <Text color="darkGray" variant="Text2" noStyles>
                                {domain.met_requirements_count} of {domain.total_requirements_count}
                            </Text>
                        </div>
                    </div>
                ))}
            </div>
            <div>
                <StackedAreaChart
                    onChartClick={props.handleChartClick}
                    xAxisLabels={props.complianceSummarySnapshots.periodic_summaries.map(
                        (snapshot) =>
                            `${moment()
                                .month(snapshot.month.month - 1)
                                .format('MMM')
                                .toUpperCase()} ${moment().year(snapshot.month.year).format('YY')}`
                    )}
                    lines={[
                        { data: props.complianceSummarySnapshots.periodic_summaries.map((snapshot) => snapshot.summary.met_count), name: 'Met', color: lightBlue },
                        { data: props.complianceSummarySnapshots.periodic_summaries.map((snapshot) => snapshot.summary.awaiting_assessments_count), name: 'Not Assessed', color: gray },
                        { data: props.complianceSummarySnapshots.periodic_summaries.map((snapshot) => snapshot.summary.not_met_count), name: 'Unmet', color: yellow },
                        { data: props.complianceSummarySnapshots.periodic_summaries.map((snapshot) => snapshot.summary.not_mapped_count), name: 'Not Mapped', color: darkGray },
                    ]}
                    yAxisInfo={{ name: 'Number of Requirements', position: 'left', min: 0, max: requirementMaximum, minInterval: 1 }}
                />
            </div>
        </div>
    );
};
