import { DocumentApi } from 'Api/Document/DocumentApi';
import { FileDownloadResponse, UploadedFile } from 'Models/Files';

/**
 * Downloads a file from S3, using a signed URL from the backend API.
 * This function _must_ be used within a try-catch (and it _must_ be `await`-ed), as errors may occur when communicating with S3 or our backend API.
 */
export const downloadDocument = async (documentApi: DocumentApi, file: UploadedFile): Promise<void> => {
    const response = await documentApi.getSignedDownload(file.file_id);
    const signedDownloadResponse = response.data;
    const link = document.createElement('a');
    link.href = signedDownloadResponse.url;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
};

/**
 * Downloads a file directly from the backend to be saved in the user's downloads directory.
 * This function is only used in rare cases, when retrieving files generated by or directly served by the backend code. If you want to retrieve files from the backend file store, you probably want to use `downloadDocument` or `downloadText` instead.
 * @param encoding - Indicates whether the file content should be treated as binary or plaintext.
 * @param response - The raw content and name of the file.
 */
export const saveFileFromApi = (encoding: 'binary' | 'plaintext', response: FileDownloadResponse): void => {
    let content: string | Uint8Array;

    if (encoding === 'binary') {
        content = new Uint8Array(
            atob(response.file_content)
                .split('')
                .map((char) => char.charCodeAt(0))
        );
    } else {
        content = atob(response.file_content);
    }

    const blobUrl = window.URL.createObjectURL(new Blob([content]));

    const link = document.createElement('a');
    link.href = blobUrl;
    link.download = response.file_name;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
};
