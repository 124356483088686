import { type JSX, useEffect, useMemo, useState } from 'react';
import { useLocation, useParams } from 'react-router';

import { ActionsApi } from 'Api/Actions/ActionsApi';
import { DocumentApi } from 'Api/Document/DocumentApi';
import { RiskRegisterApi } from 'Api/RiskRegister/RiskRegisterApi';
import { TagsApi } from 'Api/Tags/TagsApi';
import { useCachedData } from 'Components/Context/CachedDataContext';
import { Breadcrumb, BreadcrumbLink, BreadcrumbText } from 'Components/Nav/Breadcrumb/Breadcrumb';
import { PageLayoutDefault } from 'Components/PageLayout/PageLayoutDefault';
import { Placeholder } from 'Components/Placeholder/Placeholder';
import { Text } from 'Components/Text/Text';
import { ACTIONS, RISKS, RISK_REGISTER } from 'Config/Paths';
import { useSortedCategorizedTagsOptions } from 'Hooks/Tags';
import { Action } from 'Models/Actions';
import { RiskResponse } from 'Models/RiskRegister';
import { RiskTab } from 'Pages/RiskRegister/RiskDetails/RiskDetails';

import { ManageActionForm, ManageActionFormProps } from '../Components/ManageActionForm';

interface UrlParams {
    action_id?: string;
}

export enum ActionsPageType {
    CREATE_NEW,
    MANAGE,
}

export interface ManageActionProps {
    documentApi: DocumentApi;
    actionsApi: ActionsApi;
    pageType: ActionsPageType;
    tagsApi: TagsApi;
    riskRegisterApi: RiskRegisterApi;
}

export const ManageAction = (props: ManageActionProps) => {
    const { action_id } = useParams<keyof UrlParams>() as UrlParams;
    const location = useLocation();
    const query = new URLSearchParams(location.search);
    const riskIdQueryParam = query.get('riskId');

    const cachedData = useCachedData();
    const [pageLoadErrorMessage, setPageLoadErrorMessage] = useState<string>();
    const tagOptionsState = useSortedCategorizedTagsOptions(props.tagsApi);
    const [initialVersion, setInitialVersion] = useState<Action>();
    const [actionWasDeleted, setActionWasDeleted] = useState<boolean>(false);
    const [risks, setRisks] = useState<RiskResponse[]>();
    const [defaultMappedRiskIds, setDefaultMappedRiskIds] = useState<string[] | undefined>(() => {
        if (props.pageType === ActionsPageType.MANAGE) {
            return undefined;
        } else {
            if (riskIdQueryParam) {
                return [riskIdQueryParam];
            } else {
                return [];
            }
        }
    });

    useEffect(() => {
        if (cachedData.users === undefined) {
            return;
        }

        if (props.pageType === ActionsPageType.CREATE_NEW) {
            return;
        }

        const getInitialVersion = async (action_id: string): Promise<void> => {
            try {
                const response = await props.actionsApi.getActionDetails(action_id);
                const currentVersion = response.data;
                setInitialVersion(currentVersion);
                setDefaultMappedRiskIds(currentVersion.associated_risks.map((risk) => risk.id));
            } catch (error) {
                setPageLoadErrorMessage(error.message);
            }
        };
        if (props.pageType === ActionsPageType.MANAGE && action_id) {
            getInitialVersion(action_id);
        }
    }, [action_id, props.actionsApi, props.pageType, cachedData.users]);

    useEffect(() => {
        const getRiskMappingItems = async (): Promise<void> => {
            try {
                const response = await props.riskRegisterApi.getAllRisks();
                setRisks(response.data);
            } catch (error) {
                setPageLoadErrorMessage(error.message);
            }
        };
        getRiskMappingItems();
    }, [props.riskRegisterApi]);

    const preselectedRisk = useMemo(() => {
        if (riskIdQueryParam) {
            if (risks) {
                return risks.find((risk) => risk.id === riskIdQueryParam);
            } else {
                return undefined;
            }
        } else {
            return null;
        }
    }, [riskIdQueryParam, risks]);

    if (pageLoadErrorMessage) {
        return <Text>{pageLoadErrorMessage}</Text>;
    } else if (tagOptionsState.type === 'failure') {
        return <Text>{tagOptionsState.message}</Text>;
    } else if (!(cachedData.users && risks && defaultMappedRiskIds && tagOptionsState.type === 'success' && preselectedRisk !== undefined)) {
        return <Placeholder />;
    } else {
        const tagOptions = tagOptionsState.data;

        const buildBreadcrumb = (): JSX.Element => {
            if (action_id && initialVersion && props.pageType === ActionsPageType.MANAGE) {
                return (
                    <Breadcrumb textColor="blue">
                        <BreadcrumbLink link={`/${ACTIONS}`}>Action Planning</BreadcrumbLink>
                        {actionWasDeleted ? <BreadcrumbText>{initialVersion.title}</BreadcrumbText> : <BreadcrumbLink link={`/${ACTIONS}/${action_id}`}>{initialVersion.title}</BreadcrumbLink>}
                        <BreadcrumbText>Manage Action</BreadcrumbText>
                    </Breadcrumb>
                );
            } else if (preselectedRisk) {
                return (
                    <Breadcrumb textColor="blue">
                        <BreadcrumbLink link={`/${RISK_REGISTER}/${RISKS}`}>Risk Listing</BreadcrumbLink>
                        <BreadcrumbLink link={`/${RISK_REGISTER}/${RISKS}/${preselectedRisk.id}#${RiskTab.TREATMENT_PLAN}`}>{preselectedRisk.title}</BreadcrumbLink>
                        <BreadcrumbText>Create New Action</BreadcrumbText>
                    </Breadcrumb>
                );
            } else {
                return (
                    <Breadcrumb textColor="blue">
                        <BreadcrumbLink link={`/${ACTIONS}`}>Action Planning</BreadcrumbLink>
                        <BreadcrumbText>Create New Action</BreadcrumbText>
                    </Breadcrumb>
                );
            }
        };

        const getHeaderTitle = (): string => {
            if (action_id && initialVersion && props.pageType === ActionsPageType.MANAGE) {
                return 'Manage Action';
            } else {
                return 'Create New Action';
            }
        };

        const manageActionFormProps: ManageActionFormProps = {
            actionsApi: props.actionsApi,
            defaultMappedRiskIds: defaultMappedRiskIds,
            defaultActionId: props.pageType === ActionsPageType.MANAGE ? action_id : undefined,
            documentApi: props.documentApi,
            onActionDeleted: () => setActionWasDeleted(true),
            pageType: props.pageType,
            risks: risks,
            tagOptions: tagOptions,

            initialVersion: initialVersion,
            preselectedRisk: preselectedRisk ?? undefined,
        };

        return (
            <PageLayoutDefault
                headerBreadcrumb={buildBreadcrumb()}
                headerTitle={getHeaderTitle()}
                body={[
                    {
                        content: <ManageActionForm {...manageActionFormProps} />,
                    },
                ]}
            />
        );
    }
};
