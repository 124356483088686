import { type JSX, useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router';

import { ControlsApi } from 'Api/Controls/ControlsApi';
import { DocumentApi } from 'Api/Document/DocumentApi';
import { GovernanceApi } from 'Api/Governance/GovernanceApi';
import { Breadcrumb, BreadcrumbLink, BreadcrumbText } from 'Components/Nav/Breadcrumb/Breadcrumb';
import { PageLayoutDefault } from 'Components/PageLayout/PageLayoutDefault';
import { Placeholder } from 'Components/Placeholder/Placeholder';
import { Text } from 'Components/Text/Text';
import { GENERIC_ERROR_MESSAGE } from 'Config/Errors';
import { GOVERNANCE } from 'Config/Paths';
import { getFrameworkGroupControlParts } from 'Helpers/ControlFormatter/ControlFormatter';
import { getFrameworkGroupControlURL } from 'Helpers/URLBuilder/URLBuilder';
import { useControlMappingItems } from 'Hooks/ControlMapping';
import { GovernanceVersion } from 'Models/Governance';

import { AddGovernanceVersionForm, AddGovernanceVersionFormProps } from '../Components/AddGovernanceVersionForm';

export interface AddGovernanceVersionProps {
    documentApi: DocumentApi;
    controlsApi: ControlsApi;
    governanceApi: GovernanceApi;
}

export interface UrlParams {
    governanceId?: string;
}

/**
 * Effectively functions as a "manage" page for a specific "governance"--a policy, standard, or procedure.
 * Unlike most entities in the app, governances are versioned and immutable, so this page is used to create a new version for a specific governance: if the governance doesn't exist yet, then an initial version is created; otherwise, a new version of the existing governance is created.
 */
export const AddGovernanceVersion = (props: AddGovernanceVersionProps): JSX.Element => {
    const { governanceId } = useParams<keyof UrlParams>() as UrlParams;
    const versionExistsAlready = governanceId !== undefined;

    const location = useLocation();
    const query = new URLSearchParams(location.search);
    const controlIdQueryParam = query.get('controlId');

    const [loadingErrorOccurred, setLoadingErrorOccurred] = useState<boolean>(false);

    // If the API returns an effective version, this will be a GovernanceVersion; if the API returns a 204, this will be null. Until the API returns a response, this will be undefined.
    const [initialEffectiveVersion, setInitialEffectiveVersion] = useState<GovernanceVersion | null | undefined>(versionExistsAlready ? undefined : null);

    const [initialAssociatedControls, setInitialAssociatedControls] = useState<string[]>([]);
    const [controlMappingItems, controlMappingItemsError, controlNavigatedFrom] = useControlMappingItems(props.controlsApi, controlIdQueryParam ? controlIdQueryParam : undefined);

    useEffect(() => {
        const getActiveVersion = async (): Promise<void> => {
            if (!versionExistsAlready) {
                setInitialEffectiveVersion(null);
                return;
            }

            try {
                const effectiveVersion = (await props.governanceApi.getEffectiveVersionForGovernance(governanceId)).data;

                if (!effectiveVersion) {
                    setInitialEffectiveVersion(null);
                    return;
                }

                if (effectiveVersion.associated_controls) {
                    setInitialAssociatedControls(effectiveVersion.associated_controls.map((control) => control.identifier));
                }

                setInitialEffectiveVersion(effectiveVersion);
            } catch (error) {
                console.log(error);
                setLoadingErrorOccurred(true);
            }
        };

        getActiveVersion();
    }, [governanceId, props.documentApi, props.governanceApi, versionExistsAlready]);

    const getBreadcrumb = (): JSX.Element => {
        if (controlNavigatedFrom) {
            const { controlFramework, controlGroupId, controlId } = getFrameworkGroupControlParts(controlNavigatedFrom);
            return (
                <Breadcrumb textColor="blue">
                    <BreadcrumbLink link={getFrameworkGroupControlURL(controlFramework)}>{controlFramework}</BreadcrumbLink>
                    <BreadcrumbLink link={getFrameworkGroupControlURL(`${controlFramework}#${controlGroupId}`)}>{controlNavigatedFrom.metadata.control_group_name}</BreadcrumbLink>
                    <BreadcrumbLink link={`${getFrameworkGroupControlURL(controlNavigatedFrom.identifier)}#governance`}>{controlNavigatedFrom.metadata.is_custom ? controlNavigatedFrom.metadata.control_name : controlId}</BreadcrumbLink>
                    <BreadcrumbText>{initialEffectiveVersion?.title ?? 'Add New Version'}</BreadcrumbText>
                </Breadcrumb>
            );
        } else {
            return (
                <Breadcrumb textColor="blue">
                    <BreadcrumbLink link={`/${GOVERNANCE}`}>Governance Integration</BreadcrumbLink>
                    {governanceId && initialEffectiveVersion?.title && <BreadcrumbLink link={`/${GOVERNANCE}/${governanceId}`}>{initialEffectiveVersion.title}</BreadcrumbLink>}
                    <BreadcrumbText>Add New Version</BreadcrumbText>
                </Breadcrumb>
            );
        }
    };

    if (controlMappingItemsError) {
        return <Text>{controlMappingItemsError.message}</Text>;
    } else if (loadingErrorOccurred) {
        return <Text>{GENERIC_ERROR_MESSAGE}</Text>;
    } else if (controlMappingItems! && initialEffectiveVersion !== undefined) {
        const addGovernanceVersionFormProps: AddGovernanceVersionFormProps = {
            controlsApi: props.controlsApi,
            controlMappingItems: controlMappingItems!,
            defaultMappedControls: controlIdQueryParam ? [controlIdQueryParam] : initialAssociatedControls,
            documentApi: props.documentApi,
            governanceApi: props.governanceApi,
            controlNavigatedFrom: controlNavigatedFrom,
            existingGovernanceId: governanceId,
            initialEffectiveVersion: initialEffectiveVersion ?? undefined,
        };

        return (
            <PageLayoutDefault
                headerBreadcrumb={getBreadcrumb()}
                headerTitle={initialEffectiveVersion ? initialEffectiveVersion.title : 'Add New Version'}
                body={[
                    {
                        content: <AddGovernanceVersionForm {...addGovernanceVersionFormProps} />,
                    },
                ]}
            />
        );
    } else return <Placeholder />;
};
