/**
 * Throughout this feature, the following initialisms are used to eliminate verbose variable and function names:
 * CA == ConductAssessment
 */

import { faRobot } from '@fortawesome/free-solid-svg-icons';
import { produce } from 'immer';
import { cloneDeep } from 'lodash-es';
import { type JSX, Reducer, useCallback, useEffect, useReducer, useState } from 'react';
import { useParams } from 'react-router';

import { ArtificialIntelligenceApi } from 'Api/ArtificialIntelligence/ArtificialIntelligenceApi';
import { ComplianceRequirementsApi } from 'Api/ComplianceRequirements/ComplianceRequirementsApi';
import { ControlsApi } from 'Api/Controls/ControlsApi';
import { Button } from 'Components/Buttons/Buttons';
import { clientHasAuthorizedLicense, useCachedData } from 'Components/Context/CachedDataContext';
import { Breadcrumb, BreadcrumbLink, BreadcrumbText } from 'Components/Nav/Breadcrumb/Breadcrumb';
import { PageLayoutDefault } from 'Components/PageLayout/PageLayoutDefault';
import { Placeholder } from 'Components/Placeholder/Placeholder';
import { Text } from 'Components/Text/Text';
import { LinkButtonToast, TextToast } from 'Components/Toast/Toast';
import { CONTROLS, FRAMEWORKS, GROUPS, OPERATIONAL_CONTROLS } from 'Config/Paths';
import { AuthHandler } from 'Helpers/Auth/AuthHandler';
import { getFrameworkGroupControlURL } from 'Helpers/URLBuilder/URLBuilder';
import { License } from 'Models/ClientDetailsModel';
import { ComplianceRequirementForControl, ComplianceRequirementForControlResponse } from 'Models/ComplianceRequirements';
import { AssessmentRole, AssessmentState, Comment, ControlText as ControlTextModel, Effectiveness, determineUserAssessmentRoles } from 'Models/OperationalControls';
import { ControlText } from 'Pages/ControlDetails/ControlText/ControlText';

import styles from './ConductAssessment.module.css';
import { CurrentAssessment } from './CurrentAssessment/CurrentAssessment';
import { CurrentAssessmentOwnerCommentHistory } from './CurrentAssessmentOwnerCommentHistory/CurrentAssessmentOwnerCommentHistory';
import { PreviousAssessment } from './PreviousAssessment/PreviousAssessment';

/**
 * ConductAssessment inputs.
 */
export interface UrlParams {
    controlFramework: string;
    controlGroupId: string;
    controlId: string;
}
export interface ConductAssessmentProps {
    artificialIntelligenceApi: ArtificialIntelligenceApi;
    authHandler: AuthHandler;
    controlsApi: ControlsApi;
    complianceRequirementsApi: ComplianceRequirementsApi;
}

/**
 * Definitions for ConductAssessment state.
 */
export interface CAState {
    controlDetails: ControlDetailsState;
    currentAssessment: CurrentAssessmentState;
    previousAssessment: PreviousAssessmentState;
    userInterface: UserInterfaceState;
}
export interface ControlDetailsState {
    assessmentRoles: Set<AssessmentRole>;
    controlGroupName?: string;
    controlName?: string;
    controlText?: any;
    hasReviewer: boolean;
}
export interface CurrentAssessmentState {
    effectiveness?: Effectiveness;
    effectivenessReference?: Effectiveness;
    dueDate?: string;
    ownerComment?: string;
    ownerCommentHistory: Comment[];
    ownerCommentReference?: string;
    complianceRequirements: ComplianceRequirementForControlResponse[];
    complianceRequirementsReference: ComplianceRequirementForControlResponse[];
    reviewerComment?: string;
    reviewerCommentReference?: string;
    startedInProgress: boolean;
    startedInReview: boolean;
    workflowState: AssessmentState;
    [index: string]: boolean | string | undefined | Comment[] | Effectiveness | ComplianceRequirementForControlResponse[];
}
export interface PreviousAssessmentState {
    approvedTimestamp?: string;
    effectiveness?: Effectiveness;
    ownerComment?: string;
    complianceRequirements: ComplianceRequirementForControl[];
    reviewerComment?: string;
    [index: string]: string | undefined | Effectiveness | ComplianceRequirementForControl[];
}
export interface UserInterfaceState {
    failureMessage?: string;
    formDisabled: boolean;
    isLoading: boolean;
    isSaving: boolean;
    isSubmitting: boolean;
    successMessage?: string;
    [index: string]: boolean | string | undefined;
}

/**
 * Definitions for actions that affect ConductAssessment state.
 * CAStateActionLoad*: Populates state when the page loads.
 * CAStateActionUpdate*: Updates state based on user input.
 */
export enum CAStateActionType {
    LoadControlDetails,
    LoadCurrentAssessment,
    LoadPreviousAssessment,
    LoadComplianceRequirements,
    UpdateFormField,
    UpdateUserInterfaceDisabled,
    UpdateUserInterfaceMessages,
    UpdateUserInterfaceProcessing,
}
export type CAStateAction = CAStateActionLoadControlDetails | CAStateActionLoadCurrentAssessment | CAStateActionLoadPreviousAssessment | CAStateActionLoadComplianceRequirements | CAStateActionUpdateFormField | CAStateActionUpdateUserInterfaceDisabled | CAStateActionUpdateUserInterfaceMessages | CAStateActionUpdateUserInterfaceProcessing;
export interface CAStateActionLoadControlDetails {
    type: CAStateActionType;
    payload: {
        assessmentRoles: Set<AssessmentRole>;
        controlGroupName?: string;
        controlName?: string;
        controlText?: ControlTextModel[];
        controlReviewer?: string;
    };
}
export interface CAStateActionLoadCurrentAssessment {
    type: CAStateActionType;
    payload: {
        effectiveness?: Effectiveness;
        dueDate?: string;
        ownerComment?: string;
        ownerCommentHistory: Comment[];
        reviewerComment?: string;
        startedInProgress: boolean;
        startedInReview: boolean;
        workflowState: AssessmentState;
    };
}
export interface CAStateActionLoadPreviousAssessment {
    type: CAStateActionType;
    payload: {
        approvedTimestamp?: string;
        effectiveness?: Effectiveness;
        ownerComment?: string;
        complianceRequirements: ComplianceRequirementForControl[];
        reviewerComment?: string;
    };
}
export interface CAStateActionLoadComplianceRequirements {
    type: CAStateActionType;
    payload: {
        complianceRequirements: ComplianceRequirementForControlResponse[];
    };
}
export interface CAStateActionUpdateFormField {
    type: CAStateActionType;
    payload: {
        formFieldId: string;
        value?: number | string | ComplianceRequirementForControlResponse[];
    };
}
export interface CAStateActionUpdateUserInterfaceDisabled {
    type: CAStateActionType;
    payload: {
        formDisabled: boolean;
    };
}
export interface CAStateActionUpdateUserInterfaceMessages {
    type: CAStateActionType;
    payload: {
        failureMessage?: string;
        successMessage?: string;
    };
}
export interface CAStateActionUpdateUserInterfaceProcessing {
    type: CAStateActionType;
    payload: {
        isLoading: boolean;
        isSaving: boolean;
        isSubmitting: boolean;
    };
}

/**
 * Define the reducer function that handles state changes.
 */
const reducer: Reducer<CAState, CAStateAction> = (state: CAState, action: CAStateAction): CAState => {
    switch (action.type) {
        case CAStateActionType.LoadControlDetails: {
            const actionLoadControlDetails = action as CAStateActionLoadControlDetails;
            const payload = actionLoadControlDetails.payload;

            const newState = produce(state, (draft: CAState) => {
                draft.controlDetails.assessmentRoles = payload.assessmentRoles;
                draft.controlDetails.controlGroupName = payload.controlGroupName;
                draft.controlDetails.controlName = payload.controlName;
                draft.controlDetails.controlText = payload.controlText;
                draft.controlDetails.hasReviewer = payload.controlReviewer !== undefined;
            });
            return newState;
        }
        case CAStateActionType.LoadCurrentAssessment: {
            const actionLoadCurrentAssessment = action as CAStateActionLoadCurrentAssessment;
            const payload = actionLoadCurrentAssessment.payload;

            const newState = produce(state, (draft: CAState) => {
                draft.currentAssessment.effectiveness = payload.effectiveness;
                draft.currentAssessment.effectivenessReference = payload.effectiveness;
                draft.currentAssessment.dueDate = payload.dueDate;
                draft.currentAssessment.ownerComment = payload.ownerComment;
                draft.currentAssessment.ownerCommentHistory = payload.ownerCommentHistory;
                draft.currentAssessment.ownerCommentReference = payload.ownerComment;
                draft.currentAssessment.reviewerComment = payload.reviewerComment;
                draft.currentAssessment.reviewerCommentReference = payload.reviewerComment;
                draft.currentAssessment.startedInProgress = payload.startedInProgress;
                draft.currentAssessment.startedInReview = payload.startedInReview;
                draft.currentAssessment.workflowState = payload.workflowState;
            });
            return newState;
        }
        case CAStateActionType.LoadPreviousAssessment: {
            const actionLoadPreviousAssessment = action as CAStateActionLoadPreviousAssessment;
            const payload = actionLoadPreviousAssessment.payload;

            const newState = produce(state, (draft: CAState) => {
                draft.previousAssessment.approvedTimestamp = payload.approvedTimestamp;
                draft.previousAssessment.effectiveness = payload.effectiveness;
                draft.previousAssessment.ownerComment = payload.ownerComment;
                draft.previousAssessment.complianceRequirements = payload.complianceRequirements;
                draft.previousAssessment.reviewerComment = payload.reviewerComment;
            });
            return newState;
        }
        case CAStateActionType.LoadComplianceRequirements: {
            const actionLoadComplianceRequirements = action as CAStateActionLoadComplianceRequirements;
            const payload = actionLoadComplianceRequirements.payload;

            const newState = produce(state, (draft: CAState) => {
                draft.currentAssessment.complianceRequirements = payload.complianceRequirements;
                draft.currentAssessment.complianceRequirementsReference = payload.complianceRequirements;
            });
            return newState;
        }
        case CAStateActionType.UpdateFormField: {
            const actionUpdateFormField = action as CAStateActionUpdateFormField;
            const payload = actionUpdateFormField.payload;

            const newState = produce(state, (draft: CAState): void => {
                draft['currentAssessment'][payload.formFieldId] = payload.value;
            });
            return newState;
        }
        case CAStateActionType.UpdateUserInterfaceDisabled: {
            const actionUpdateUserInterfaceDisabled = action as CAStateActionUpdateUserInterfaceDisabled;
            const payload = actionUpdateUserInterfaceDisabled.payload;

            const newState = produce(state, (draft: CAState): void => {
                draft.userInterface.formDisabled = payload.formDisabled;
            });
            return newState;
        }
        case CAStateActionType.UpdateUserInterfaceMessages: {
            const actionUpdateUserInterfaceMessages = action as CAStateActionUpdateUserInterfaceMessages;
            const payload = actionUpdateUserInterfaceMessages.payload;

            const newState = produce(state, (draft: CAState): void => {
                draft.userInterface.failureMessage = payload.failureMessage;
                draft.userInterface.successMessage = payload.successMessage;
            });
            return newState;
        }
        case CAStateActionType.UpdateUserInterfaceProcessing: {
            const actionUpdateUserInterfaceProcessing = action as CAStateActionUpdateUserInterfaceProcessing;
            const payload = actionUpdateUserInterfaceProcessing.payload;

            const newState = produce(state, (draft: CAState): void => {
                draft.userInterface.isLoading = payload.isLoading;
                draft.userInterface.isSaving = payload.isSaving;
                draft.userInterface.isSubmitting = payload.isSubmitting;
            });
            return newState;
        }
    }
};

export const ConductAssessment = (props: ConductAssessmentProps): JSX.Element => {
    const cachedData = useCachedData();
    const params = useParams<keyof UrlParams>() as UrlParams;
    /**
     * Initialize ConductAssessment state and the reducer function that modifies it.
     */
    const initialCAState = {
        controlDetails: {
            assessmentRoles: new Set<AssessmentRole>(),
            hasReviewer: false,
        },
        currentAssessment: {
            ownerCommentHistory: [],
            complianceRequirements: [],
            complianceRequirementsReference: [],
            startedInProgress: false,
            startedInReview: false,
            workflowState: AssessmentState.IN_PROGRESS,
        },
        previousAssessment: {
            complianceRequirements: [],
        },
        userInterface: {
            formDisabled: false,
            isLoading: true,
            isSaving: false,
            isSubmitting: false,
        },
    };
    const [caState, dispatchCAStateChange] = useReducer<CAState, [CAStateAction]>(reducer, initialCAState);

    const handleRequestError = useCallback((error: Error): void => {
        const action: CAStateAction = {
            type: CAStateActionType.UpdateUserInterfaceMessages,
            payload: {
                failureMessage: error.message,
                successMessage: undefined,
            },
        };
        dispatchCAStateChange(action);
    }, []);

    /**
     * Define a function to retrieve current assessment details.
     */
    const loadCurrentAssessment = useCallback(async (): Promise<void> => {
        try {
            const assessmentDetailsResponse = await props.controlsApi.getAssessmentDetails(params.controlFramework, params.controlGroupId, params.controlId);
            const assessmentDetails = assessmentDetailsResponse.data;

            let ownerComment;
            if (assessmentDetails.owner_comments && assessmentDetails.owner_comments.length > 0) {
                ownerComment = assessmentDetails.owner_comments[assessmentDetails.owner_comments.length - 1].text;
            }

            const action: CAStateAction = {
                type: CAStateActionType.LoadCurrentAssessment,
                payload: {
                    effectiveness: assessmentDetails.assessment_effectiveness,
                    dueDate: assessmentDetails.assessment_due_date,
                    ownerComment: ownerComment,
                    ownerCommentHistory: assessmentDetails.owner_comments ? assessmentDetails.owner_comments : [],
                    reviewerComment: assessmentDetails.reviewer_comment?.text,
                    startedInProgress: assessmentDetails.assessment_state === AssessmentState.IN_PROGRESS,
                    startedInReview: assessmentDetails.assessment_state === AssessmentState.UNDER_REVIEW,
                    workflowState: assessmentDetails.assessment_state,
                },
            };
            dispatchCAStateChange(action);
        } catch (error) {
            handleRequestError(error);
            const action: CAStateAction = {
                type: CAStateActionType.UpdateUserInterfaceDisabled,
                payload: {
                    formDisabled: true,
                },
            };
            dispatchCAStateChange(action);
        }
    }, [props.controlsApi, params.controlFramework, params.controlGroupId, params.controlId, handleRequestError]);

    /**
     * Define a function to retrieve current Compliance Requirement statuses.
     */
    const loadComplianceRequirements = useCallback(async (): Promise<void> => {
        try {
            const complianceRequirementsResponse = await props.complianceRequirementsApi.getComplianceRequirementsForControlAssessment(params.controlFramework, params.controlGroupId, params.controlId);
            const complianceRequirements = complianceRequirementsResponse.data;

            const action: CAStateAction = {
                type: CAStateActionType.LoadComplianceRequirements,
                payload: {
                    complianceRequirements: complianceRequirements,
                },
            };
            dispatchCAStateChange(action);
        } catch (error) {
            handleRequestError(error);
        }
    }, [props.complianceRequirementsApi, params.controlFramework, params.controlGroupId, params.controlId, handleRequestError]);

    /**
     * Initialize state for a subset of control details relevant to ConductAssessment.
     */
    useEffect(() => {
        const loadControlDetails = async () => {
            try {
                const controlDetailsResponse = await props.controlsApi.getControlDetails(params.controlFramework, params.controlGroupId, params.controlId);
                const controlDetails = controlDetailsResponse.data;

                const action: CAStateAction = {
                    type: CAStateActionType.LoadControlDetails,
                    payload: {
                        assessmentRoles: determineUserAssessmentRoles(props.authHandler.getAuthenticatedUserSubject(), props.authHandler.getUserRoles(), controlDetails.configuration.review_configuration?.owner_subject, controlDetails.configuration.review_configuration?.reviewer_subject),
                        controlGroupName: controlDetails.metadata.control_group_name,
                        controlName: controlDetails.metadata.control_name,
                        controlText: controlDetails.metadata.control_text,
                        controlReviewer: controlDetails.configuration.review_configuration?.reviewer_subject,
                    },
                };
                dispatchCAStateChange(action);
            } catch (error) {
                handleRequestError(error);
            }
        };

        loadControlDetails();
    }, [props.authHandler, props.controlsApi, params.controlFramework, params.controlGroupId, params.controlId, handleRequestError]);

    /**
     * Initialize state for current assessment details.
     */
    useEffect(() => {
        loadCurrentAssessment();
    }, [loadCurrentAssessment]);

    /**
     * Initialize state for the current assessment's Compliance Requirements.
     */
    useEffect(() => {
        loadComplianceRequirements();
    }, [loadComplianceRequirements]);

    /**
     * Initialize state for previous assessment details.
     */
    useEffect(() => {
        const loadPreviousAssessment = async () => {
            try {
                const assessmentHistoryResponse = await props.controlsApi.getAssessmentHistory(params.controlFramework, params.controlGroupId, params.controlId);
                const assessmentHistory = assessmentHistoryResponse.data;

                if (assessmentHistory.length > 0) {
                    const previousAssessment = assessmentHistory[assessmentHistory.length - 1];

                    const approvedTimestamp: string | undefined = previousAssessment.timestamp;
                    const effectiveness: Effectiveness | undefined = previousAssessment.assessment.assessment_effectiveness;
                    let ownerComment: string | undefined = undefined;
                    let complianceRequirements: ComplianceRequirementForControl[] = [];
                    let reviewerComment: string | undefined = undefined;

                    if (previousAssessment.assessment.owner_comments && previousAssessment.assessment.owner_comments.length > 0) {
                        ownerComment = previousAssessment.assessment.owner_comments[previousAssessment.assessment.owner_comments.length - 1].text;
                    }

                    if (previousAssessment.attached_requirements && previousAssessment.attached_requirements.length > 0) {
                        complianceRequirements = previousAssessment.attached_requirements;
                    }

                    if (previousAssessment.assessment.reviewer_comment) {
                        reviewerComment = previousAssessment.assessment.reviewer_comment.text;
                    }

                    const action: CAStateAction = {
                        type: CAStateActionType.LoadPreviousAssessment,
                        payload: {
                            approvedTimestamp: approvedTimestamp,
                            effectiveness: effectiveness,
                            ownerComment: ownerComment,
                            complianceRequirements: complianceRequirements,
                            reviewerComment: reviewerComment,
                        },
                    };
                    dispatchCAStateChange(action);
                }
            } catch (error) {
                handleRequestError(error);
            } finally {
                const action: CAStateAction = {
                    type: CAStateActionType.UpdateUserInterfaceProcessing,
                    payload: {
                        isLoading: false,
                        isSaving: false,
                        isSubmitting: false,
                    },
                };
                dispatchCAStateChange(action);
            }
        };

        loadPreviousAssessment();
    }, [props.controlsApi, params.controlFramework, params.controlGroupId, params.controlId, handleRequestError]);

    /**
     * Copy values from the previous assessment to the current assessment.
     */
    const copyToCurrent = () => {
        let action: CAStateAction;
        if (caState.currentAssessment.workflowState === AssessmentState.IN_PROGRESS) {
            if ((caState.controlDetails.assessmentRoles.has(AssessmentRole.OWNER) || caState.controlDetails.assessmentRoles.has(AssessmentRole.ADMIN)) && caState.previousAssessment.ownerComment !== undefined) {
                action = {
                    type: CAStateActionType.UpdateFormField,
                    payload: {
                        formFieldId: 'ownerComment',
                        value: caState.previousAssessment.ownerComment,
                    },
                };
                dispatchCAStateChange(action);
            }
        } else if (caState.currentAssessment.workflowState === AssessmentState.UNDER_REVIEW) {
            if (caState.controlDetails.assessmentRoles.size === 1 && caState.controlDetails.assessmentRoles.has(AssessmentRole.OWNER) && caState.previousAssessment.ownerComment !== undefined) {
                action = {
                    type: CAStateActionType.UpdateFormField,
                    payload: {
                        formFieldId: 'ownerComment',
                        value: caState.previousAssessment.ownerComment,
                    },
                };
                dispatchCAStateChange(action);
            }

            if ((caState.controlDetails.assessmentRoles.has(AssessmentRole.REVIEWER) || caState.controlDetails.assessmentRoles.has(AssessmentRole.ADMIN)) && caState.previousAssessment.reviewerComment !== undefined) {
                action = {
                    type: CAStateActionType.UpdateFormField,
                    payload: {
                        formFieldId: 'reviewerComment',
                        value: caState.previousAssessment.reviewerComment,
                    },
                };
                dispatchCAStateChange(action);
            }
        }

        const tempComplianceRequirements: ComplianceRequirementForControlResponse[] = [];
        let tempComplianceRequirement: ComplianceRequirementForControlResponse;
        for (let i = 0; i < caState.currentAssessment.complianceRequirements.length; i++) {
            tempComplianceRequirement = cloneDeep(caState.currentAssessment.complianceRequirements[i]);
            for (let j = 0; j < caState.previousAssessment.complianceRequirements.length; j++) {
                if (caState.currentAssessment.complianceRequirements[i].regulation === caState.previousAssessment.complianceRequirements[j].regulation && caState.currentAssessment.complianceRequirements[i].identifier === caState.previousAssessment.complianceRequirements[j].identifier && caState.previousAssessment.complianceRequirements[j].status !== undefined) {
                    tempComplianceRequirement.status = caState.previousAssessment.complianceRequirements[j].status;
                }
            }
            tempComplianceRequirements.push(tempComplianceRequirement);
        }
        action = {
            type: CAStateActionType.UpdateFormField,
            payload: {
                formFieldId: 'complianceRequirements',
                value: tempComplianceRequirements,
            },
        };
        dispatchCAStateChange(action);

        if (caState.previousAssessment.effectiveness !== undefined) {
            action = {
                type: CAStateActionType.UpdateFormField,
                payload: {
                    formFieldId: 'effectiveness',
                    value: caState.previousAssessment.effectiveness,
                },
            };
            dispatchCAStateChange(action);
        }
    };

    /**
     * Render the ConductAssessment component.
     */
    if (caState.userInterface.isLoading === false) {
        return (
            <>
                {caState.userInterface.successMessage && (
                    <LinkButtonToast
                        variant="success"
                        clearToast={() => {
                            const action: CAStateAction = {
                                type: CAStateActionType.UpdateUserInterfaceMessages,
                                payload: {
                                    failureMessage: undefined,
                                    successMessage: undefined,
                                },
                            };
                            dispatchCAStateChange(action);
                        }}
                        linkButtonText="Return to control"
                        linkButtonTo={`/${OPERATIONAL_CONTROLS}/${FRAMEWORKS}/${params.controlFramework}/${GROUPS}/${params.controlGroupId}/${CONTROLS}/${params.controlId}#assessment`}
                        text={caState.userInterface.successMessage}
                    />
                )}
                {caState.userInterface.failureMessage && (
                    <TextToast
                        variant="failure"
                        clearToast={() => {
                            const action: CAStateAction = {
                                type: CAStateActionType.UpdateUserInterfaceMessages,
                                payload: {
                                    failureMessage: undefined,
                                    successMessage: undefined,
                                },
                            };
                            dispatchCAStateChange(action);
                        }}
                        text={caState.userInterface.failureMessage}
                    />
                )}
                <PageLayoutDefault
                    headerBreadcrumb={
                        caState.controlDetails &&
                        caState.controlDetails.controlGroupName && (
                            <Breadcrumb textColor="blue">
                                <BreadcrumbLink link={getFrameworkGroupControlURL(params.controlFramework)}>{params.controlFramework}</BreadcrumbLink>
                                <BreadcrumbLink link={getFrameworkGroupControlURL(`${params.controlFramework}#${params.controlGroupId}`)}>{caState.controlDetails.controlGroupName}</BreadcrumbLink>
                                <BreadcrumbLink link={`${getFrameworkGroupControlURL(`${params.controlFramework}#${params.controlGroupId}#${params.controlId}`)}#assessment`}>{caState.controlDetails.controlName ? caState.controlDetails.controlName : params.controlId}</BreadcrumbLink>
                                <BreadcrumbText>Assessment</BreadcrumbText>
                            </Breadcrumb>
                        )
                    }
                    headerTitle="Control Assessment"
                    headerDescription={caState.controlDetails.controlText && <ControlText color="darkBlue" controlText={caState.controlDetails.controlText} textVariant="Header3" />}
                    body={[
                        {
                            content: (
                                <div className={styles.alignHorizontal}>
                                    <CurrentAssessment controlsApi={props.controlsApi} dispatchCAStateChange={dispatchCAStateChange} handleRequestError={handleRequestError} loadCurrentAssessment={loadCurrentAssessment} loadComplianceRequirements={loadComplianceRequirements} caState={caState} controlFramework={params.controlFramework} controlGroupId={params.controlGroupId} controlId={params.controlId} hasReviewer={caState.controlDetails.hasReviewer} />
                                    <div className={styles.verticalRule} />
                                    <PreviousAssessment copyToCurrent={copyToCurrent} approvedTimestamp={caState.previousAssessment.approvedTimestamp} ownerComment={caState.previousAssessment.ownerComment} complianceRequirements={caState.previousAssessment.complianceRequirements} effectiveness={caState.previousAssessment.effectiveness} reviewerComment={caState.previousAssessment.reviewerComment} formDisabled={caState.userInterface.formDisabled} isSaving={caState.userInterface.isSaving} isSubmitting={caState.userInterface.isSubmitting} />
                                </div>
                            ),
                        },
                        {
                            content: <CurrentAssessmentOwnerCommentHistory handleRequestError={handleRequestError} ownerCommentHistory={caState.currentAssessment.ownerCommentHistory} />,
                        },
                        ...(clientHasAuthorizedLicense([License.AI_OPERATIONAL_CONTROL_ASSESSMENT], cachedData.clientDetails.licenses)
                            ? [
                                  {
                                      content: <AIAssessment artificialIntelligenceApi={props.artificialIntelligenceApi} controlFramework={params.controlFramework} controlGroupId={params.controlGroupId} controlId={params.controlId} />,
                                  },
                              ]
                            : []),
                    ]}
                />
            </>
        );
    } else {
        return <Placeholder />;
    }
};

interface AIAssessmentProps {
    artificialIntelligenceApi: ArtificialIntelligenceApi;
    controlFramework: string;
    controlGroupId: string;
    controlId: string;
}

const AIAssessment = (props: AIAssessmentProps) => {
    const [requestState, setRequestState] = useState<'idle' | 'loading' | 'submitting' | 'submitted'>('loading');
    const [currentAssessment, setCurrentAssessment] = useState<string>();
    const [successMessage, setSuccessMessage] = useState<string>();
    const [failureMessage, setFailureMessage] = useState<string>();

    useEffect(() => {
        const getAssessment = async (): Promise<void> => {
            try {
                const response = await props.artificialIntelligenceApi.getAiAssessment(props.controlFramework, props.controlGroupId, props.controlId);
                setCurrentAssessment(response.data.assessment ?? 'No AI assessment generated yet.');
                setRequestState('idle');
            } catch (error) {
                setFailureMessage(error.message);
            }
        };

        getAssessment();
    }, [props.artificialIntelligenceApi, props.controlFramework, props.controlGroupId, props.controlId]);

    const generateAssessment = async (): Promise<void> => {
        try {
            setRequestState('submitting');
            setSuccessMessage(undefined);
            setFailureMessage(undefined);
            await props.artificialIntelligenceApi.generateAiAssessment(props.controlFramework, props.controlGroupId, props.controlId);
            setRequestState('submitted');
            setSuccessMessage('Request submitted.');
            setCurrentAssessment('Generating assessment...');
        } catch (error) {
            setFailureMessage(error.message);
            setRequestState('idle');
        }
    };

    if (currentAssessment === undefined) {
        return <Text>Loading...</Text>;
    }

    return (
        <>
            {successMessage && <TextToast variant="success" clearToast={() => setSuccessMessage(undefined)} text={successMessage} />}
            {failureMessage && <TextToast variant="failure" clearToast={() => setFailureMessage(undefined)} text={failureMessage} />}
            <Button variant="secondary" disabled={requestState !== 'idle'} onClick={generateAssessment} isLoading={requestState === 'submitting'} loadingText="Generating..." fontAwesomeImage={faRobot}>
                Generate AI Assessment
            </Button>
            <div className={styles.aiAssessmentText}>
                <Text>{currentAssessment}</Text>
            </div>
        </>
    );
};
