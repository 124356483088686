import { Skeleton } from '@mui/material';
import moment from 'moment';
import { type JSX, useMemo, useState } from 'react';

import { DashboardApi } from 'Api/Dashboards/DashboardApi';
import { StackedBarAndLineChart } from 'Components/BaseCharts/StackedBarAndLineChart';
import { ChartAxis } from 'Components/BaseCharts/charts.common';
import { CSSColors } from 'Components/Colors';
import { FormFieldDateOptionSelect } from 'Components/FormField/FormFieldDateOptionSelect/FormFieldDateOptionSelect';
import { ChangeEventType, FormFieldSelectDark } from 'Components/FormField/FormFieldSelectDark/FormFieldSelectDark';
import { Text } from 'Components/Text/Text';
import { DashboardRiskOptions, DateOptionSelectOptions, DefaultDateRangeSelectOptions, TprmDashboardRiskType, isValidDashboardRiskType, isValidDateOption } from 'Models/Dashboards';
import { effectivenessAsString } from 'Models/OperationalControls';
import { riskRatingAsString } from 'Models/TPRM';
import { CustomDateRange, CustomDateRangeModal, CustomDateRangeUpdateCallback } from 'Pages/Dashboards/Components/CustomDateRangeModal/CustomDateRangeModal';

import { SelectedServiceDetails, ServicesDisplayModal } from '../ServicesDisplayModal/ServicesDisplayModal';
import { AssessmentsBarChartDataRow, buildAssessmentsBarChartByMonth, getMonths, getPercentageCompletedOnTime, onlyMostRecentAssessmentsPerMonth } from '../TPRMDashboard.helpers';
import { getDatesFromPreset, useCompletedRiskAssessmentsQuery, useDateFilter } from '../TPRMDashboard.hooks';
import styles from '../TPRMDashboard.module.css';

enum Modals {
    NONE,
    DISPLAY_SERVICES,
    CUSTOM_DATE,
}

interface ServiceDataItem {
    monthKey: string;
    riskScore: number | undefined;
    completedOnTime: boolean;
    serviceId: string;
    serviceName: string;
    thirdPartyId: string;
    thirdPartyName: string;
}

interface CompletedAssessmentsChartData {
    assessmentDataRows: AssessmentsBarChartDataRow[];
    xAxisLabels: string[];
    yAxisInfo: ChartAxis[];
    line: AssessmentsBarChartDataRow;
    servicesData: ServiceDataItem[];
}

interface CompletedAssessmentsChartProps {
    dashboardApi: DashboardApi;
}

export const TPRMCompletedAssessmentsChart = (props: CompletedAssessmentsChartProps): JSX.Element => {
    const [modalState, setModalState] = useState<Modals>(Modals.NONE);
    const [servicesModalSubtitle, setServicesModalSubtitle] = useState<string>();
    const [selectedServices, setSelectedServices] = useState<SelectedServiceDetails[]>();
    const [selectedDates] = useState<{ start: Date; end: Date }>(getDatesFromPreset(DateOptionSelectOptions.NEXT_QUARTER));
    const [riskType, setRiskType] = useState<TprmDashboardRiskType>(TprmDashboardRiskType.RESIDUAL);
    const {
        dateFilter,
        updateDateFilter: { setPreset, setDateRange },
    } = useDateFilter(DateOptionSelectOptions.PREVIOUS_QUARTER);
    const { completedRiskAssessments, completedRiskAssessmentsError } = useCompletedRiskAssessmentsQuery(props.dashboardApi, dateFilter);

    const completedAssessmentsChartData = useMemo<CompletedAssessmentsChartData | undefined>(() => {
        if (completedRiskAssessments) {
            const completedAssessmentsData = onlyMostRecentAssessmentsPerMonth(completedRiskAssessments).map((assessment) => ({
                monthKey: moment(assessment.completed_time).format('MMM YY'),
                riskScore: (() => {
                    switch (riskType) {
                        case TprmDashboardRiskType.INHERENT:
                            return assessment.inherent_risk_score;
                        case TprmDashboardRiskType.CONTROL_EFFECTIVENESS:
                            return assessment.control_effectiveness;
                        case TprmDashboardRiskType.RESIDUAL:
                        default:
                            return assessment.residual_risk_score;
                    }
                })(),
                completedOnTime: assessment.completed_on_time,
                serviceId: assessment.service_id,
                serviceName: assessment.service_name,
                thirdPartyId: assessment.third_party_id,
                thirdPartyName: assessment.third_party_name,
            }));

            const months = getMonths(dateFilter.start, dateFilter.end);

            const assessmentDataRows = buildAssessmentsBarChartByMonth(completedAssessmentsData, riskType, months);
            const yAxisInfo: ChartAxis[] = [
                { name: 'Number of Assessments', position: 'left', minInterval: 1 },
                { name: 'Percent Completed on Time', position: 'right', minInterval: 1, max: 100 },
            ];

            return { assessmentDataRows, xAxisLabels: months.map((month) => month.toUpperCase()), yAxisInfo, line: { name: 'Percent Completed on Time', data: getPercentageCompletedOnTime(completedAssessmentsData, months), color: CSSColors.LIGHT_BLUE }, servicesData: completedAssessmentsData };
        }
    }, [completedRiskAssessments, riskType, dateFilter]);

    const handleChartClick = (item: string): void => {
        const clickTargetDetails = item.split(' | ');
        const targetRating = clickTargetDetails[1];
        const targetDate = clickTargetDetails[0];

        if (targetRating && targetRating !== 'Percent Completed on Time' && targetDate && completedRiskAssessments && completedAssessmentsChartData?.servicesData) {
            setServicesModalSubtitle(`${targetRating} - ${targetDate}`);
            setModalState(Modals.DISPLAY_SERVICES);
            const selectedServices = completedAssessmentsChartData.servicesData
                .filter((service) => {
                    if (service.monthKey.toUpperCase() === targetDate) {
                        if (riskType === TprmDashboardRiskType.CONTROL_EFFECTIVENESS) {
                            if (service.riskScore !== undefined && effectivenessAsString(service.riskScore) === targetRating) {
                                return service;
                            } else {
                                return null;
                            }
                        } else if (service.riskScore !== undefined && riskRatingAsString(service.riskScore) === targetRating) {
                            return service;
                        } else {
                            return null;
                        }
                    } else {
                        return null;
                    }
                })
                .map((service) => ({
                    serviceId: service.serviceId,
                    serviceName: service.serviceName,
                    thirdPartyId: service.thirdPartyId,
                    thirdPartyName: service.thirdPartyName,
                }));
            setSelectedServices(selectedServices);
        }
    };

    const handleServicesModalClose = () => {
        setModalState(Modals.NONE);
        setServicesModalSubtitle(undefined);
        setSelectedServices(undefined);
    };

    const handleRiskTypeChange = (value: ChangeEventType, formFieldId: string) => {
        if (value && isValidDashboardRiskType(value)) {
            setRiskType(value);
        }
    };

    const handleSelectCustomDateRange: CustomDateRangeUpdateCallback = (customDateRange: CustomDateRange) => {
        setDateRange(customDateRange);
    };

    const handleDateFilterChange = (value: ChangeEventType, formFieldId: string) => {
        if (value && isValidDateOption(value)) {
            if (value === DateOptionSelectOptions.CUSTOM) {
                setModalState(Modals.CUSTOM_DATE);
            }

            setPreset(value);
        }
    };

    if (completedRiskAssessmentsError) {
        return (
            <div className={styles.cellHeader}>
                <Text variant="Header2" color="white">
                    Completed Assessments
                </Text>
                <Text color="white">{completedRiskAssessmentsError}</Text>
            </div>
        );
    }

    return (
        <>
            {modalState === Modals.CUSTOM_DATE && <CustomDateRangeModal startDate={selectedDates.start} endDate={selectedDates.end} datesRestriction="pastDatesOnly" hideModal={() => setModalState(Modals.NONE)} setCustomDateRange={handleSelectCustomDateRange} />}
            {modalState === Modals.DISPLAY_SERVICES && servicesModalSubtitle && selectedServices && <ServicesDisplayModal hideModal={handleServicesModalClose} subtitle={servicesModalSubtitle} selectedServices={selectedServices} />}
            <div className={styles.cellHeader}>
                <Text variant="Header2" color="white">
                    Completed Assessments
                </Text>
                <div className={styles.filters}>
                    <FormFieldSelectDark formFieldId="riskType" formFieldLabel="Risk Type" options={DashboardRiskOptions} selectedOption={riskType} handleChange={handleRiskTypeChange} />
                    <FormFieldDateOptionSelect formFieldId="date" options={DefaultDateRangeSelectOptions} selectedOption={dateFilter.preset} handleChange={handleDateFilterChange} />
                </div>
            </div>
            {completedAssessmentsChartData ? <StackedBarAndLineChart onChartClick={handleChartClick} xAxisLabels={completedAssessmentsChartData.xAxisLabels} bars={completedAssessmentsChartData.assessmentDataRows} yAxisInfo={completedAssessmentsChartData.yAxisInfo} line={completedAssessmentsChartData.line} /> : <Skeleton variant="rounded" width={'100%'} height={300} />}
        </>
    );
};
