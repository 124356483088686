import { type JSX, useCallback, useEffect, useState } from 'react';

import { ArtificialIntelligenceApi } from 'Api/ArtificialIntelligence/ArtificialIntelligenceApi';
import { DocumentApi } from 'Api/Document/DocumentApi';
import { Link } from 'Components/Buttons/Buttons';
import { OverflowMenu, OverflowMenuProps } from 'Components/Buttons/OverflowMenu';
import { useCachedData } from 'Components/Context/CachedDataContext';
import { UploadedFileAndState } from 'Components/Files/UploadedFileAndState';
import { ConfirmationModal } from 'Components/Modal/ConfirmationModal';
import { Placeholder } from 'Components/Placeholder/Placeholder';
import { SortDirection, SortableTableHeader, SortableTableHeaderProps } from 'Components/Table/SortableTableHeader/SortableTableHeader';
import { Table, TableBody, TableCellDefaultText, TableOverflowCell, TableRow } from 'Components/Table/Table/Table';
import { Text } from 'Components/Text/Text';
import { GENERIC_ERROR_MESSAGE } from 'Config/Errors';
import { ICON_ADD_CREATE, ICON_DELETE_REMOVE, ICON_DOWNLOAD } from 'Config/Icons';
import { ARTIFICIAL_INTELLIGENCE, CREATE, GOVERNANCE } from 'Config/Paths';
import { iso8601ToUsDateLong } from 'Helpers/DateTimeUtils/DateTimeUtils';
import { downloadDocument } from 'Helpers/FileUtils';
import { getUserNameFromSubject } from 'Helpers/UserUtils';
import { AiGovernance } from 'Models/ArtificialIntelligence';
import { FileState, UploadedFile } from 'Models/Files';
import { getHumanReadableGovernanceType } from 'Models/Governance';

import styles from './ArtificialIntelligenceGovernanceTab.module.css';

enum Modal {
    Delete,
    None,
}

enum SortFilterOptions {
    CREATED_BY = 'created_by',
    CREATED_TIMESTAMP = 'created_timestamp',
    DESCRIPTION = 'description',
    INPUT_FILE_NAME = 'input_file_name',
    REGULATION = 'regulation',
    TITLE = 'title',
}

export interface ArtificialIntelligenceGovernanceTabProps {
    artificialIntelligenceApi: ArtificialIntelligenceApi;
    documentApi: DocumentApi;
}

export const ArtificialIntelligenceGovernanceTab = (props: ArtificialIntelligenceGovernanceTabProps): JSX.Element => {
    const cachedData = useCachedData();

    const [allAiGovernance, setAllAiGovernance] = useState<AiGovernance[]>();
    const [displayedModal, setDisplayedModal] = useState(Modal.None);
    const [loadingErrorOccurred, setLoadingErrorOccurred] = useState<boolean>(false);
    const [selectedAiGovernance, setSelectedAiGovernance] = useState<AiGovernance>();
    const [sortColumnAndDirection, setSortColumnAndDirection] = useState<{ column: string; direction: SortDirection }>({ column: SortFilterOptions.CREATED_TIMESTAMP, direction: SortDirection.DESC });

    /** Retrieve a list of AI Governance documents. */
    const aiGovernanceGetAll = useCallback(async () => {
        try {
            const response = await props.artificialIntelligenceApi.aiGovernanceGetAll();
            setAllAiGovernance(response.data);
        } catch (error) {
            console.error(error);
            setLoadingErrorOccurred(true);
        }
    }, [props.artificialIntelligenceApi]);

    useEffect(() => {
        aiGovernanceGetAll();
    }, [aiGovernanceGetAll]);

    /** Delete an AI Governance document. */
    const aiGovernanceDelete = async (aiGovernance: AiGovernance): Promise<string> => {
        await props.artificialIntelligenceApi.aiGovernanceDelete(aiGovernance);
        await aiGovernanceGetAll();
        return `Deleted ${aiGovernance.title}`;
    };

    /** Download an AI Governance document. */
    const aiGovernanceDownload = async (file: UploadedFile): Promise<void> => {
        try {
            downloadDocument(props.documentApi, file);
        } catch (error) {
            console.error(error);
        }
    };

    /** Given an array of aiGovernance, sort them according to the sortColumnAndDirection state.*/
    const applySorting = (aiGovernanceArray: AiGovernance[]): AiGovernance[] => {
        let comparisonResult = 0;
        return aiGovernanceArray.sort((a, b) => {
            switch (sortColumnAndDirection.column) {
                case SortFilterOptions.CREATED_TIMESTAMP:
                    comparisonResult = a.created_timestamp.localeCompare(b.created_timestamp);
                    break;
                case SortFilterOptions.DESCRIPTION:
                    comparisonResult = a.description.localeCompare(b.description);
                    break;
                case SortFilterOptions.INPUT_FILE_NAME:
                    comparisonResult = (a.input_file?.filename ?? '').localeCompare(b.input_file?.filename ?? '');
                    break;
                case SortFilterOptions.REGULATION:
                    comparisonResult = (a.regulation?.name ?? '').localeCompare(b.regulation?.name ?? '');
                    break;
                case SortFilterOptions.TITLE:
                    comparisonResult = a.type.localeCompare(b.type);
                    break;
                default:
                    comparisonResult = 0;
                    break;
            }
            return sortColumnAndDirection.direction === SortDirection.ASC ? comparisonResult : -comparisonResult;
        });
    };

    /** Build the table header row. */
    const sortableTableProps: SortableTableHeaderProps = {
        headers: [
            { dataKey: SortFilterOptions.TITLE, label: 'Title' },
            { dataKey: SortFilterOptions.CREATED_TIMESTAMP, label: 'Created Timestamp' },
            { dataKey: SortFilterOptions.CREATED_BY, label: 'Created By' },
            { dataKey: SortFilterOptions.INPUT_FILE_NAME, label: 'Reference Document' },
            { dataKey: SortFilterOptions.REGULATION, label: 'Regulation' },
            { dataKey: SortFilterOptions.DESCRIPTION, label: 'Description' },
        ],
        setSortColumnAndDirection: (column: string, direction: SortDirection): void => {
            setSortColumnAndDirection({ column: column as SortFilterOptions, direction: direction });
        },
        currentSort: sortColumnAndDirection.column,
        currentSortDirection: sortColumnAndDirection.direction,
        tableIncludesOverflowMenu: true,
    };

    /** Given an AI Governance entity, render a table row.*/
    const renderTableRow = (aiGovernance: AiGovernance): JSX.Element => {
        return (
            <TableRow key={aiGovernance.id}>
                <TableCellDefaultText>
                    {aiGovernance.output_file ? (
                        <UploadedFileAndState unclickable textVariant="Text2" title={aiGovernance.title} documentApi={props.documentApi} file={aiGovernance.output_file} />
                    ) : (
                        <Text noStyles variant="Text2">
                            {aiGovernance.title}
                        </Text>
                    )}
                    <Text variant="Text3" noStyles color="darkGray">
                        {getHumanReadableGovernanceType(aiGovernance.type)}
                    </Text>
                </TableCellDefaultText>
                <TableCellDefaultText>
                    <Text noStyles>{iso8601ToUsDateLong(aiGovernance.created_timestamp)}</Text>
                </TableCellDefaultText>
                <TableCellDefaultText>
                    <Text noStyles>{getUserNameFromSubject(aiGovernance.created_by, cachedData.users)}</Text>
                </TableCellDefaultText>
                <TableCellDefaultText>
                    <Text noStyles>{aiGovernance.input_file ? aiGovernance.input_file.filename : ''}</Text>
                </TableCellDefaultText>
                <TableCellDefaultText>
                    <Text noStyles>{aiGovernance.regulation ? aiGovernance.regulation.name : ''}</Text>
                </TableCellDefaultText>
                <TableCellDefaultText>
                    <Text noStyles>{aiGovernance.description}</Text>
                </TableCellDefaultText>
                <TableOverflowCell>
                    <OverflowMenu {...overflowMenuProps(aiGovernance)} />
                </TableOverflowCell>
            </TableRow>
        );
    };

    /** Render an overflow menu for a given AI Governance entity. */
    const overflowMenuProps = (aiGovernance: AiGovernance): OverflowMenuProps => {
        const overflowMenuItems = [];
        if (aiGovernance.input_file && aiGovernance.input_file.file_state === FileState.PASSED) {
            overflowMenuItems.push({
                text: 'Download Reference Document',
                onClickAction: () => aiGovernanceDownload(aiGovernance.input_file!),
                icon: ICON_DOWNLOAD,
            });
        }
        if (aiGovernance.output_file && aiGovernance.output_file.file_state === FileState.PASSED) {
            overflowMenuItems.push({
                text: 'Download Generated Document',
                onClickAction: () => aiGovernanceDownload(aiGovernance.output_file!),
                icon: ICON_DOWNLOAD,
            });
            overflowMenuItems.push({
                text: 'Delete Document',
                onClickAction: (): void => {
                    setDisplayedModal(Modal.Delete);
                    setSelectedAiGovernance(aiGovernance);
                },
                icon: ICON_DELETE_REMOVE,
            });
        }

        const overflowItemsProp: OverflowMenuProps = {
            overflowItems: overflowMenuItems,
            accessibilityTitle: `${aiGovernance.title} Menu`,
        };

        // If neither the input_file nor output_file are available for download yet, disable the overflow menu.
        if (overflowMenuItems.length === 0) {
            overflowItemsProp.disabled = true;
        }

        return overflowItemsProp;
    };

    if (loadingErrorOccurred) {
        return <Text>{GENERIC_ERROR_MESSAGE}</Text>;
    }

    if (allAiGovernance) {
        return (
            <>
                {displayedModal === Modal.Delete && selectedAiGovernance && (
                    <ConfirmationModal operationType="delete" headerText="Delete Document" areYouSureText="Are you sure you want to delete this document?" performOperation={() => aiGovernanceDelete(selectedAiGovernance)} hideModal={() => setDisplayedModal(Modal.None)}>
                        <Text>{selectedAiGovernance.title}</Text>
                    </ConfirmationModal>
                )}
                <div className={styles.button}>
                    <Link variant="primaryButton" to={`/${ARTIFICIAL_INTELLIGENCE}/${GOVERNANCE}/${CREATE}`} fontAwesomeImage={ICON_ADD_CREATE}>
                        Generate New
                    </Link>
                </div>
                <Table>
                    <SortableTableHeader {...sortableTableProps} />
                    <TableBody>{applySorting(allAiGovernance).map(renderTableRow)}</TableBody>
                </Table>
            </>
        );
    } else {
        return <Placeholder />;
    }
};
