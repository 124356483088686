import { type JSX, useState } from 'react';
import { Form } from 'react-bootstrap';

import { DocumentApi } from 'Api/Document/DocumentApi';
import { RiskRegisterApi } from 'Api/RiskRegister/RiskRegisterApi';
import { Button } from 'Components/Buttons/Buttons';
import { FileManagementArea, useFileManagementArea } from 'Components/Files/FileManagementArea';
import { FormFieldText } from 'Components/FormField/FormFieldText/FormFieldText';
import { FormFieldTextArea } from 'Components/FormField/FormFieldTextArea/FormFieldTextArea';
import { LinkButtonToast, TextToast } from 'Components/Toast/Toast';
import { FormFieldTooltip } from 'Components/Tooltips/FormFieldTooltip';
import { RISK_REGISTER, RISK_REVIEW } from 'Config/Paths';
import { REFERENCE, RISK_MANAGEMENT_RISK_REVIEW_ADDITIONAL_DOCUMENTS } from 'Config/Tooltips';
import { SubmitRiskAssessmentRequest } from 'Models/RiskRegister';

import styles from './CreateRiskAssessmentForm.module.css';

type SubmitState = { type: 'none' } | { type: 'submitting' } | { type: 'success'; message?: string } | { type: 'failure'; message?: string };

export interface CreateRiskAssessmentFormProps {
    documentApi: DocumentApi;
    riskRegisterApi: RiskRegisterApi;

    riskCategoryId?: string;
}

export const CreateRiskAssessmentForm = (props: CreateRiskAssessmentFormProps): JSX.Element => {
    const [comments, setComments] = useState<string>();
    const [reference, setReference] = useState<string>();
    const [submitRequestWithManagedFiles, fileManagementHookValues] = useFileManagementArea(props.documentApi, []);
    const [submitState, setSubmitState] = useState<SubmitState>({ type: 'none' });

    const createAssessment = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        try {
            if (props.riskCategoryId !== undefined) {
                setSubmitState({ type: 'submitting' });
                await submitRequestWithManagedFiles(async (fileUpdates) => {
                    const submitRiskAssessmentRequest: SubmitRiskAssessmentRequest = {
                        category: props.riskCategoryId!,
                        comments: comments,
                        file_updates: fileUpdates,
                        link: reference,
                    };
                    await props.riskRegisterApi.submitRiskAssessment(submitRiskAssessmentRequest);
                });
                setSubmitState({ type: 'success', message: 'Review submitted.' });
            }
        } catch (error) {
            setSubmitState({ type: 'failure', message: error.message });
        }
    };

    const disabled = props.riskCategoryId === undefined;

    return (
        <>
            {submitState.type === 'success' && submitState.message && <LinkButtonToast variant={submitState.type} clearToast={() => setSubmitState({ type: 'none' })} text={submitState.message} linkButtonText="View Review Listing" linkButtonTo={`/${RISK_REGISTER}/${RISK_REVIEW}`} />}
            {submitState.type === 'failure' && submitState.message && <TextToast variant={submitState.type} clearToast={() => setSubmitState({ type: 'none' })} text={submitState.message} />}
            <Form noValidate onSubmit={createAssessment}>
                <fieldset disabled={disabled}>
                    <div className={styles.fieldContainer}>
                        <FormFieldTextArea formFieldLabel="Comments" formFieldId="comments" handleChange={(event: React.FormEvent<HTMLInputElement>) => setComments(event.currentTarget.value)} value={comments} tooltip="Any information relevant to your assessment of the current state of risks in the selected category." />
                    </div>
                    <div className={styles.fieldContainer}>
                        <FormFieldText handleChange={(event: React.FormEvent<HTMLInputElement>) => setReference(event.currentTarget.value)} formFieldId="reference" formFieldLabel="Reference" value={reference ?? ''} tooltip={REFERENCE} />
                    </div>
                    <div className={styles.fieldContainer}>
                        <FileManagementArea fileDragAndDropFormFieldLabel="Additional Documents" disabled={disabled} documentApi={props.documentApi} fileManagementHookValues={fileManagementHookValues} tooltip={<FormFieldTooltip text={RISK_MANAGEMENT_RISK_REVIEW_ADDITIONAL_DOCUMENTS} />} />
                    </div>
                    <div className={styles.buttonContainer}>
                        <Button variant="submit" isLoading={submitState.type === 'submitting'} loadingText="Submitting...">
                            Submit
                        </Button>
                    </div>
                </fieldset>
            </Form>
        </>
    );
};
