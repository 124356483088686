import { ActionStatus, TreatmentPlanStatus } from './Actions';
import { FileUpdates, UploadedFile } from './Files';
import { Effectiveness, OperationalControl } from './OperationalControls';
import { DisplayableTag } from './Tags';

export interface AssociatedAction {
    id: string;
    title: string;
    description: string;
    status: ActionStatus;
    owner: string;
    last_updated: string;
    due_date?: string;
    [key: string]: any;
}

export interface RiskResponse {
    id: string;
    title: string;
    description: string;
    last_updated_by: string;
    category: RiskCategory;
    tags: string[];
    scores_comment?: string;
    owner?: string;
    reference?: string;
    strategy?: RiskStrategy;
    control_environment_effectiveness: number;
    treatment_plan_description?: string;
    treatment_plan_status: TreatmentPlanStatus;
    inherent_risk_probability?: number;
    inherent_risk_impact?: number;
    total_inherent_risk?: number;
    current_risk_probability?: number;
    current_risk_impact?: number;
    total_current_risk?: number;
    target_risk_probability?: number;
    target_risk_impact?: number;
    total_target_risk?: number;
    associated_actions: AssociatedAction[];
    controls: RiskControlMapping[];
}

export interface RiskRatingHistoryResponse {
    timestamp: string;
    updated_by: string;
    inherent_risk_probability?: number;
    inherent_risk_impact?: number;
    total_inherent_risk?: number;
    current_risk_probability?: number;
    current_risk_impact?: number;
    total_current_risk?: number;
    target_risk_probability?: number;
    target_risk_impact?: number;
    total_target_risk?: number;
    scores_comment?: string;
    control_environment_effectiveness?: number;
}

export interface RiskHistory {
    timestamp: string;
    id: string;
    title: string;
    description: string;
    last_updated_by: string;
    category: RiskCategory;
    tags: DisplayableTag[];
    scores_comment?: string;
    owner?: string;
    reference?: string;
    strategy?: RiskStrategy;
    control_environment_effectiveness: number;
    treatment_plan_description?: string;
    treatment_plan_status: TreatmentPlanStatus;
    inherent_risk_probability?: number;
    inherent_risk_impact?: number;
    total_inherent_risk?: number;
    current_risk_probability?: number;
    current_risk_impact?: number;
    total_current_risk?: number;
    target_risk_probability?: number;
    target_risk_impact?: number;
    total_target_risk?: number;
    associated_actions: AssociatedAction[];
    controls: {
        control: string;
        weight: number;
        effectiveness: Effectiveness;
        last_assessed: string;
    }[];
}

export interface WeightedControl {
    control: string;
    weight: number;
}

export interface RiskControlMapping {
    weight: number;
    control: OperationalControl;
}

export interface SaveRiskRequest {
    title: string;
    description: string;
    category: string;
    tags: string[];
    owner?: string;
    strategy?: string;
    reference?: string;
    treatment_plan_description?: string;
    associated_actions: string[];
    controls: WeightedControl[];
}

export interface UpdateRiskScoresRequest {
    scores_comment?: string;
    inherent_risk_probability?: number;
    inherent_risk_impact?: number;
    current_risk_probability?: number;
    current_risk_impact?: number;
    target_risk_probability?: number;
    target_risk_impact?: number;
}

export interface RiskCategory {
    title: string;
    id: string;
}

export interface RiskCategoryRequest {
    title: string;
    id?: string;
    in_use?: boolean;
}

export interface RiskCategoryResponse extends RiskCategory {
    in_use: boolean;
}

export interface RiskStrategyRequest {
    title: string;
    id?: string;
}

export type RiskStrategy = Required<RiskStrategyRequest>;

export interface SubmitRiskAssessmentRequest {
    category: string;
    comments?: string;
    file_updates?: FileUpdates;
    link?: string; // Within the UI this has been renamed to "reference" and its use has been expanded to anything, not just URLs, but the attribute on domain objects and DTOs is still "link" to avoid a data migration.
}

export interface RiskAssessment {
    category: RiskCategory;
    timestamp: string;
    risk_histories: RiskHistory[];
    submitted_by: string;
    average_inherent_risk: number;
    average_control_environment_effectiveness: number;
    average_current_risk: number;
    average_target_risk: number;
    total_inherent_risk: number;
    total_current_risk: number;
    total_target_risk: number;
    documents?: UploadedFile[];
    comments?: string;
    link?: string; // Within the UI this has been renamed to "reference" and its use has been expanded to anything, not just URLs, but the attribute on domain objects and DTOs is still "link" to avoid a data migration.
}

type impactOrProbability = number | string | undefined;

export const formatAverageWeightedEffectiveness = (value: number): string => {
    return value.toFixed(2);
};

export const formatImpactOrProbability = (value: impactOrProbability): string => {
    if (value !== undefined) {
        return Number(value).toFixed(1);
    }

    return '-';
};

export const formatTotalImpactOrProbability = (value: impactOrProbability): string => {
    if (value !== undefined) {
        return Number(value).toFixed(2);
    }

    return '-';
};

export const calculateAndFormatTotalImpactOrProbability = (impact: impactOrProbability, probability: impactOrProbability): string => {
    if (impact !== undefined && probability !== undefined) {
        const total = Number(impact) * Number(probability);
        return Number(total).toFixed(2);
    }

    return '-';
};

export const WARNING_MESSAGE_INCOMPLETE_CONTROL_DISTRIBUTION = 'The total control distribution is less than 100%.';
