import { type JSX } from 'react';
import { useParams } from 'react-router';

import { ControlsApi } from 'Api/Controls/ControlsApi';
import { DocumentApi } from 'Api/Document/DocumentApi';
import { Link } from 'Components/Buttons/Buttons';
import { Breadcrumb, BreadcrumbLink, BreadcrumbText } from 'Components/Nav/Breadcrumb/Breadcrumb';
import { PageLayoutDefault } from 'Components/PageLayout/PageLayoutDefault';
import { Placeholder } from 'Components/Placeholder/Placeholder';
import { Text } from 'Components/Text/Text';
import { CONFIGURATION } from 'Config/Paths';
import { getOperationalControlIdentifierString } from 'Helpers/ControlFormatter/ControlFormatter';
import { getFrameworkGroupControlURL } from 'Helpers/URLBuilder/URLBuilder';
import { useControlMappingItems } from 'Hooks/ControlMapping';

import { UploadControlEvidenceForm, UploadControlEvidenceFormProps } from './Components/UploadControlEvidenceForm';
import styles from './UploadControlEvidence.module.css';

export interface UploadControlEvidenceProps {
    controlsApi: ControlsApi;
    documentApi: DocumentApi;
}

export interface UrlParams {
    controlFramework: string;
    controlGroupId: string;
    controlId: string;
}

export const UploadControlEvidence = (props: UploadControlEvidenceProps): JSX.Element => {
    const { controlFramework, controlGroupId, controlId } = useParams<keyof UrlParams>() as UrlParams;
    const controlIdentifier = getOperationalControlIdentifierString(controlFramework, controlGroupId, controlId);
    const [controlMappingItems, controlMappingItemsError, initialAssociatedControl] = useControlMappingItems(props.controlsApi, controlIdentifier);

    if (controlMappingItemsError) {
        return <Text>{controlMappingItemsError.message}</Text>;
    } else if (controlMappingItems && controlMappingItems.length === 0) {
        return (
            <div className={styles.textButtonContainer}>
                <Text noStyles>You must enable a control to upload control evidence.</Text>;
                <Link size="sm" to={`/${CONFIGURATION}#companySettings`}>
                    Enable Controls
                </Link>
            </div>
        );
    } else if (!(initialAssociatedControl && controlMappingItems)) {
        return <Placeholder />;
    } else {
        const uploadControlEvidenceFormProps: UploadControlEvidenceFormProps = {
            controlsApi: props.controlsApi,
            documentApi: props.documentApi,
            controls: controlMappingItems,
            initialAssociatedControl: initialAssociatedControl,
        };

        return (
            <PageLayoutDefault
                headerBreadcrumb={
                    <Breadcrumb textColor="blue">
                        <BreadcrumbLink link={getFrameworkGroupControlURL(controlFramework)}>{controlFramework}</BreadcrumbLink>
                        <BreadcrumbLink link={getFrameworkGroupControlURL(`${controlFramework}#${controlGroupId}`)}>{initialAssociatedControl!.metadata.control_group_name}</BreadcrumbLink>
                        <BreadcrumbLink link={`${getFrameworkGroupControlURL(`${controlFramework}#${controlGroupId}#${controlId}`)}#evidence`}>{initialAssociatedControl.metadata.is_custom && initialAssociatedControl.metadata.control_name ? initialAssociatedControl.metadata.control_name : controlId}</BreadcrumbLink>
                        <BreadcrumbText>Evidence</BreadcrumbText>
                    </Breadcrumb>
                }
                headerTitle="Submit Evidence"
                body={[
                    {
                        title: 'Evidence Details',
                        content: <UploadControlEvidenceForm {...uploadControlEvidenceFormProps} />,
                    },
                ]}
            />
        );
    }
};
