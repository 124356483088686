import { ASSESSMENTS, CLEAR, COMMON, CONFIGURATION, CONTROLS, CURRENT, DDQ, DOCUMENTS, FOLDERS, FRAMEWORKS, GROUPS, IRQ, NOTIFICATIONS, POTENTIAL_PARENTS, QUESTIONS, REPORTS, RESTART, SCHEDULE, SERVICES, START, SUMMARY, THIRD_PARTIES, TPRM, VERSIONS } from 'Config/Paths';
import { ResponseModel } from 'Models/ResponseModel';
import { ControlFrameworkInstanceResponse, CreateControlConfigurationRequest, CreateControlFrameworkConfigurationRequest, CreateControlGroupConfigurationRequest, CreateOrUpdateFolderRequest, CreateThirdPartyRequest, FolderAllVersions, FolderTypes, FolderVersion, InherentRiskQuestionnaireConfiguration, InherentRiskQuestionnaireResponse, QuestionnaireAggregationResponse, QuestionnaireConfigurationResponse, QuestionnaireGroupControlsResponse, QuestionnaireReport, ResetAndNotifyThirdPartyRequest, RiskWorkflowArchive, SaveOrSubmitInherentRiskQuestionnaireRequest, SaveThirdPartyServiceRequest, ScheduleV2, Service, ServiceAssessmentSchedule, ServiceSummary, StartAssessmentWorkflowRequest, ThirdPartyResponse, ThirdPartyResponseWithServices, UpdateCommonAssessmentRequest, UpdateConfigurationRequest, UpdateControlInstanceQuestionsRequest, UpdateControlInstanceRequest, UpdateServiceAssessmentRequest, UpdateThirdPartyRequest } from 'Models/TPRM';
import { restApiDelete, restApiGet, restApiPost } from 'RestApiConnector';

import { TPRMApi } from './TPRMApi';

export class AWSTPRMApi implements TPRMApi {
    createThirdParty = (createThirdPartyRequest: CreateThirdPartyRequest): Promise<ResponseModel<void>> => {
        return restApiPost(`/${TPRM}/${THIRD_PARTIES}`, createThirdPartyRequest);
    };

    deleteThirdParty = (thirdPartyId: string): Promise<ResponseModel<void>> => {
        return restApiDelete(`/${TPRM}/${THIRD_PARTIES}/${thirdPartyId}`);
    };

    /** @returns A list of all Third Parties and their Services. The list is sorted by Third-Party name. */
    getThirdParties = (): Promise<ResponseModel<ThirdPartyResponseWithServices[]>> => {
        return restApiGet(`/${TPRM}/${THIRD_PARTIES}`, {}).then((response): ResponseModel<ThirdPartyResponseWithServices[]> => {
            return {
                ...response,
                data: response.data.sort((thirdPartyA: ThirdPartyResponseWithServices, thirdPartyB: ThirdPartyResponseWithServices) => thirdPartyA.name.localeCompare(thirdPartyB.name)),
            };
        });
    };

    getThirdPartyDetails = (thirdPartyId: string): Promise<ResponseModel<ThirdPartyResponse>> => {
        return restApiGet(`/${TPRM}/${THIRD_PARTIES}/${thirdPartyId}`, {});
    };

    updateThirdParty = (updateThirdPartyRequest: UpdateThirdPartyRequest, thirdPartyId: string): Promise<ResponseModel<void>> => {
        return restApiPost(`/${TPRM}/${THIRD_PARTIES}/${thirdPartyId}`, updateThirdPartyRequest);
    };

    saveThirdPartyService = (request: SaveThirdPartyServiceRequest, thirdPartyId: string, serviceId?: string): Promise<ResponseModel<Service>> => {
        if (serviceId !== undefined) {
            // Modify an existing Third-Party Service.
            return restApiPost(`/${TPRM}/${THIRD_PARTIES}/${thirdPartyId}/${SERVICES}/${serviceId}`, request);
        } else {
            // Create a new Third-Party Service.
            return restApiPost(`/${TPRM}/${THIRD_PARTIES}/${thirdPartyId}/${SERVICES}`, request);
        }
    };

    startNewAssessmentWorkflow = (thirdPartyId: string, serviceId: string, request: StartAssessmentWorkflowRequest): Promise<ResponseModel<void>> => {
        return restApiPost(`/${TPRM}/${THIRD_PARTIES}/${thirdPartyId}/${SERVICES}/${serviceId}/${ASSESSMENTS}/${START}`, request);
    };

    restartAssessmentWorkflow = (thirdPartyId: string, serviceId: string, request: StartAssessmentWorkflowRequest): Promise<ResponseModel<void>> => {
        return restApiPost(`/${TPRM}/${THIRD_PARTIES}/${thirdPartyId}/${SERVICES}/${serviceId}/${ASSESSMENTS}/${RESTART}`, request);
    };

    clearAssessmentWorkflow = (thirdPartyId: string, serviceId: string): Promise<ResponseModel<void>> => {
        return restApiPost(`/${TPRM}/${THIRD_PARTIES}/${thirdPartyId}/${SERVICES}/${serviceId}/${ASSESSMENTS}/${CLEAR}`, {});
    };

    modifyServiceAssessment = (serviceRequest: UpdateServiceAssessmentRequest, vendorId: string, serviceId: string): Promise<ResponseModel<void>> => {
        return restApiPost(`/${TPRM}/${THIRD_PARTIES}/${vendorId}/${SERVICES}/${serviceId}/${ASSESSMENTS}`, serviceRequest);
    };

    updateCommonAssessment = (request: UpdateCommonAssessmentRequest): Promise<ResponseModel<void>> => {
        return restApiPost(`/${TPRM}/${ASSESSMENTS}/${COMMON}`, request);
    };

    deleteService = (thirdPartyId: string, serviceId: string): Promise<ResponseModel<void>> => {
        return restApiDelete(`/${TPRM}/${THIRD_PARTIES}/${thirdPartyId}/${SERVICES}/${serviceId}`);
    };

    getServiceDetails = (vendor_id: string, service_id: string): Promise<ResponseModel<Service>> => {
        return restApiGet(`/${TPRM}/${THIRD_PARTIES}/${vendor_id}/${SERVICES}/${service_id}`);
    };

    getInherentRiskQuestionnaireConfiguration = (): Promise<ResponseModel<InherentRiskQuestionnaireConfiguration>> => {
        return restApiGet(`/${TPRM}/${IRQ}/${CONFIGURATION}`);
    };

    setInherentRiskQuestionnaireConfiguration = (configuration: InherentRiskQuestionnaireConfiguration): Promise<ResponseModel<void>> => {
        return restApiPost(`/${TPRM}/${IRQ}/${CONFIGURATION}`, configuration);
    };

    getInherentRiskQuestionnaire = (vendor_id: string, service_id: string): Promise<ResponseModel<InherentRiskQuestionnaireResponse | undefined>> => {
        return restApiGet(`/${TPRM}/${THIRD_PARTIES}/${vendor_id}/${SERVICES}/${service_id}/${IRQ}`);
    };

    saveInherentRiskQuestionnaire = (vendor_id: string, service_id: string, request: SaveOrSubmitInherentRiskQuestionnaireRequest): Promise<ResponseModel<void>> => {
        return restApiPost(`/${TPRM}/${THIRD_PARTIES}/${vendor_id}/${SERVICES}/${service_id}/${IRQ}`, request);
    };

    getCommonAssessmentPotentialParents = (thirdPartyId: string, serviceId: string): Promise<ResponseModel<ServiceSummary[]>> => {
        return restApiGet(`/${TPRM}/${THIRD_PARTIES}/${thirdPartyId}/${SERVICES}/${serviceId}/${DDQ}/${POTENTIAL_PARENTS}/`);
    };

    getQuestionnaireConfiguration = (): Promise<ResponseModel<QuestionnaireConfigurationResponse>> => {
        return restApiGet(`/${TPRM}/${DDQ}/${CONFIGURATION}`);
    };

    getQuestionnaireFrameworks = (thirdPartyId: string, serviceId: string): Promise<ResponseModel<ControlFrameworkInstanceResponse[]>> => {
        return restApiGet(`/${TPRM}/${THIRD_PARTIES}/${thirdPartyId}/${SERVICES}/${serviceId}/${DDQ}/${FRAMEWORKS}/`);
    };
    getQuestionnaireGroupControls = (thirdPartyId: string, serviceId: string, controlFramework: string, controlGroupId: string): Promise<ResponseModel<QuestionnaireGroupControlsResponse>> => {
        return restApiGet(`/${TPRM}/${THIRD_PARTIES}/${thirdPartyId}/${SERVICES}/${serviceId}/${DDQ}/${FRAMEWORKS}/${controlFramework}/${GROUPS}/${controlGroupId}`);
    };

    getQuestionnaireAggregation = (thirdPartyId: string, serviceId: string): Promise<ResponseModel<QuestionnaireAggregationResponse> | undefined> => {
        return restApiGet(`/${TPRM}/${THIRD_PARTIES}/${thirdPartyId}/${SERVICES}/${serviceId}/${DDQ}/${SUMMARY}/`);
    };

    getQuestionnaireReport = (thirdPartyId: string, serviceId: string): Promise<ResponseModel<QuestionnaireReport>> => {
        return restApiGet(`/${TPRM}/${THIRD_PARTIES}/${thirdPartyId}/${SERVICES}/${serviceId}/${DDQ}/${REPORTS}/`);
    };
    thirdPartySaveControl = (thirdPartyId: string, serviceId: string, controlFramework: string, controlGroupId: string, controlId: string, request: UpdateControlInstanceQuestionsRequest): Promise<ResponseModel<void>> => {
        return Promise.reject(new Error('This should only be called by public DDQs'));
    };
    clientSaveControl = (thirdPartyId: string, serviceId: string, controlFramework: string, controlGroupId: string, controlId: string, request: UpdateControlInstanceRequest): Promise<ResponseModel<void>> => {
        return restApiPost(`/${TPRM}/${THIRD_PARTIES}/${thirdPartyId}/${SERVICES}/${serviceId}/${DDQ}/${FRAMEWORKS}/${controlFramework}/${GROUPS}/${controlGroupId}/${CONTROLS}/${controlId}`, request);
    };
    deleteControlAssessmentDocument = (thirdPartyId: string, serviceId: string, controlFramework: string, controlGroupId: string, controlId: string, documentId: string): Promise<ResponseModel<void>> => {
        return restApiDelete(`/${TPRM}/${THIRD_PARTIES}/${thirdPartyId}/${SERVICES}/${serviceId}/${DDQ}/${FRAMEWORKS}/${controlFramework}/${GROUPS}/${controlGroupId}/${CONTROLS}/${controlId}/${ASSESSMENTS}/${DOCUMENTS}/${documentId}`);
    };
    deleteAnswerDocument = (thirdPartyId: string, serviceId: string, controlFramework: string, controlGroupId: string, controlId: string, questionId: string, documentId: string): Promise<ResponseModel<void>> => {
        return restApiDelete(`/${TPRM}/${THIRD_PARTIES}/${thirdPartyId}/${SERVICES}/${serviceId}/${DDQ}/${FRAMEWORKS}/${controlFramework}/${GROUPS}/${controlGroupId}/${CONTROLS}/${controlId}/${QUESTIONS}/${questionId}/${DOCUMENTS}/${documentId}`);
    };

    /**
     * Reset the Due Diligence Questionnaire. Reset credentials and notify the specified Third-Party Service contacts.
     */
    resetAndNotifyThirdParty = (thirdPartyId: string, serviceId: string, resetAndNotifyThirdPartyRequest: ResetAndNotifyThirdPartyRequest): Promise<ResponseModel<void>> => {
        return restApiPost(`/${TPRM}/${THIRD_PARTIES}/${thirdPartyId}/${SERVICES}/${serviceId}/${DDQ}/${NOTIFICATIONS}`, resetAndNotifyThirdPartyRequest);
    };

    // Third-Party (Due Diligence) Questionnaire Configuration
    createControlFrameworkConfiguration = (createControlFrameworkConfigurationRequest: CreateControlFrameworkConfigurationRequest): Promise<ResponseModel<void>> => {
        return restApiPost(`/${TPRM}/${DDQ}/${CONFIGURATION}/${FRAMEWORKS}`, createControlFrameworkConfigurationRequest);
    };
    deleteControlFrameworkConfiguration = (controlFramework: string): Promise<ResponseModel<void>> => {
        return restApiDelete(`/${TPRM}/${DDQ}/${CONFIGURATION}/${FRAMEWORKS}/${controlFramework}`);
    };

    createControlGroupConfiguration = (controlFramework: string, controlcreateControlGroupConfigurationRequest: CreateControlGroupConfigurationRequest): Promise<ResponseModel<void>> => {
        return restApiPost(`/${TPRM}/${DDQ}/${CONFIGURATION}/${FRAMEWORKS}/${controlFramework}/${GROUPS}`, controlcreateControlGroupConfigurationRequest);
    };
    deleteControlGroupConfiguration = (controlFramework: string, controlGroupId: string): Promise<ResponseModel<void>> => {
        return restApiDelete(`/${TPRM}/${DDQ}/${CONFIGURATION}/${FRAMEWORKS}/${controlFramework}/${GROUPS}/${controlGroupId}`);
    };

    createControlConfiguration = (controlFramework: string, controlGroupId: string, createControlConfigurationRequest: CreateControlConfigurationRequest): Promise<ResponseModel<void>> => {
        return restApiPost(`/${TPRM}/${DDQ}/${CONFIGURATION}/${FRAMEWORKS}/${controlFramework}/${GROUPS}/${controlGroupId}/${CONTROLS}`, createControlConfigurationRequest);
    };
    deleteControlConfiguration = (controlFramework: string, controlGroupId: string, controlId: string): Promise<ResponseModel<void>> => {
        return restApiDelete(`/${TPRM}/${DDQ}/${CONFIGURATION}/${FRAMEWORKS}/${controlFramework}/${GROUPS}/${controlGroupId}/${CONTROLS}/${controlId}`);
    };
    updateConfiguration = (updateConfigurationRequest: Map<string, UpdateConfigurationRequest>): Promise<ResponseModel<void>> => {
        return restApiPost(`/${TPRM}/${DDQ}/${CONFIGURATION}`, Object.fromEntries(updateConfigurationRequest));
    };

    // Settings
    getServiceAssessmentSchedule = (): Promise<ResponseModel<ServiceAssessmentSchedule>> => {
        return restApiGet(`/${TPRM}/${ASSESSMENTS}/${SCHEDULE}/`);
    };
    saveServiceAssessmentSchedule = (serviceAssessmentSchedule: ServiceAssessmentSchedule): Promise<ResponseModel<void>> => {
        return restApiPost(`/${TPRM}/${ASSESSMENTS}/${SCHEDULE}/`, serviceAssessmentSchedule);
    };

    getInherentRiskQuestionnaireTargetCompletion = (): Promise<ResponseModel<ScheduleV2 | undefined>> => {
        return restApiGet(`/${TPRM}/${IRQ}/${SCHEDULE}/`);
    };
    setInherentRiskQuestionnaireTargetCompletion = (schedule: ScheduleV2): Promise<ResponseModel<ScheduleV2>> => {
        return restApiPost(`/${TPRM}/${IRQ}/${SCHEDULE}/`, schedule);
    };
    deleteInherentRiskQuestionnaireTargetCompletion = (): Promise<ResponseModel<undefined>> => {
        return restApiDelete(`/${TPRM}/${IRQ}/${SCHEDULE}/`);
    };

    // Third-Party Service Reporting
    getAssessmentSummaryReport = (thirdPartyId: string, serviceId: string, timestamp: string): Promise<ResponseModel<RiskWorkflowArchive>> => {
        return restApiGet(`/${TPRM}/${THIRD_PARTIES}/${thirdPartyId}/${SERVICES}/${serviceId}/${REPORTS}/${timestamp}`, {});
    };
    getAllAssessmentSummaryReports = (thirdPartyId: string, serviceId: string): Promise<ResponseModel<RiskWorkflowArchive[]>> => {
        return restApiGet(`/${TPRM}/${THIRD_PARTIES}/${thirdPartyId}/${SERVICES}/${serviceId}/${REPORTS}`, {});
    };

    //TPRM Documents/Folders
    getAllFolderTypes = (): Promise<ResponseModel<FolderTypes>> => {
        return restApiGet(`/${TPRM}/${FOLDERS}`, {});
    };

    updateFolderTypes = (folderTypes: FolderTypes): Promise<ResponseModel<void>> => {
        return restApiPost(`/${TPRM}/${FOLDERS}`, folderTypes);
    };

    getFolders = (thirdPartyId: string): Promise<ResponseModel<FolderVersion[]>> => {
        return restApiGet(`/${TPRM}/${THIRD_PARTIES}/${thirdPartyId}/${FOLDERS}`, {});
    };

    createNewFolder = (thirdPartyId: string, createNewFolderRequest: CreateOrUpdateFolderRequest): Promise<ResponseModel<string>> => {
        return restApiPost(`/${TPRM}/${THIRD_PARTIES}/${thirdPartyId}/${FOLDERS}`, createNewFolderRequest);
    };

    getAllFolderVersions = (thirdPartyId: string, folderId: string): Promise<ResponseModel<FolderAllVersions>> => {
        return restApiGet(`/${TPRM}/${THIRD_PARTIES}/${thirdPartyId}/${FOLDERS}/${folderId}`);
    };

    createNewFolderVersion = (thirdPartyId: string, folderId: string, createNewFolderVersionRequest: CreateOrUpdateFolderRequest): Promise<ResponseModel<void>> => {
        return restApiPost(`/${TPRM}/${THIRD_PARTIES}/${thirdPartyId}/${FOLDERS}/${folderId}/${VERSIONS}`, createNewFolderVersionRequest);
    };

    manageFolder = (thirdPartyId: string, folderId: string, createNewFolderVersionRequest: CreateOrUpdateFolderRequest): Promise<ResponseModel<void>> => {
        return restApiPost(`/${TPRM}/${THIRD_PARTIES}/${thirdPartyId}/${FOLDERS}/${folderId}/${VERSIONS}/${CURRENT}`, createNewFolderVersionRequest);
    };

    deleteFolder = (thirdPartyId: string, folderId: string): Promise<ResponseModel<void>> => {
        return restApiDelete(`/${TPRM}/${THIRD_PARTIES}/${thirdPartyId}/${FOLDERS}/${folderId}`);
    };
}
