import { BarAndLineChart, BarAndLineChartProps } from 'Components/BaseCharts/BarAndLineChart';
import { CSSColors } from 'Components/Colors';

export interface ControlBarData {
    inactive: number;
    fail: number;
    weak: number;
    moderate: number;
    strong: number;
    robust: number;
}

export interface ControlBarGraphProps {
    controlBarData: ControlBarData;
    yAxisLabel: string;
    onItemClick: (item: string) => void;
}

export const ControlBarGraph = (props: ControlBarGraphProps) => {
    const barAndLineChart: BarAndLineChartProps = {
        onChartClick: props.onItemClick,
        bar: {
            name: 'Effectiveness',
            color: CSSColors.LIGHT_BLUE,
            data: [
                { value: props.controlBarData.robust, itemStyle: { color: CSSColors.DARK_GREEN } },
                { value: props.controlBarData.strong, itemStyle: { color: CSSColors.LIGHT_GREEN } },
                { value: props.controlBarData.moderate, itemStyle: { color: CSSColors.YELLOW } },
                { value: props.controlBarData.weak, itemStyle: { color: CSSColors.ORANGE } },
                { value: props.controlBarData.fail, itemStyle: { color: CSSColors.RED } },
                { value: props.controlBarData.inactive, itemStyle: { color: CSSColors.DARK_GRAY } },
            ],
        },
        xAxisLabels: ['Robust', 'Strong', 'Moderate', 'Weak', 'Fail', 'Inactive'],
        yAxisInfo: [
            {
                minInterval: 1,
                name: props.yAxisLabel,
                position: 'left',
            },
        ],
    };

    return <BarAndLineChart {...barAndLineChart} />;
};
