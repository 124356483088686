/*
    AssessmentDetails.tsx -- Displays the history of an assessment.
*/

import { faClipboard, faClipboardCheck } from '@fortawesome/free-solid-svg-icons';
import { Fragment, type JSX, useMemo, useState } from 'react';

import { Link } from 'Components/Buttons/Buttons';
import { Role } from 'Components/Context/RBACContext';
import { SortDirection, SortableTableHeader, SortableTableHeaderProps } from 'Components/Table/SortableTableHeader/SortableTableHeader';
import { Table, TableBody, TableCellDefaultText, TableRow } from 'Components/Table/Table/Table';
import { Text } from 'Components/Text/Text';
import { undefinedComparator } from 'Helpers/Compare';
import { iso8601ToUsDateLong } from 'Helpers/DateTimeUtils/DateTimeUtils';
import { Assessment, AssessmentHistory, AssessmentRole, AssessmentState, OperationalControl, determineUserAssessmentRoles } from 'Models/OperationalControls';

import styles from './AssessmentDetails.module.css';

enum AssessmentHistorySortOptions {
    APPROVED_TIMESTAMP = 'approved_timestamp',
    OWNER_COMMENT = 'owner_comment',
    REVIEWER_COMMENT = 'reviewer_comment',
    SUBMITTED_TIMESTAMP = 'submitted_timestamp',
}

export interface AssessmentDetailsProps {
    detailedControlResponse: OperationalControl;
    assessment?: Assessment;
    assessmentDetailsError?: string;
    assessmentHistoryList?: AssessmentHistory[];
    assessmentLinkData: { to: string; state: Record<string, any> };
    authenticatedUserSubject?: string;
    authenticatedUserRoles: Role[];
}

export const AssessmentDetails = (props: AssessmentDetailsProps): JSX.Element => {
    const [currentSort, setCurrentSort] = useState<string>(AssessmentHistorySortOptions.APPROVED_TIMESTAMP);
    const [currentSortDirection, setCurrentSortDirection] = useState<SortDirection>(SortDirection.DESC);
    const userAssessmentRoles = useMemo(() => determineUserAssessmentRoles(props.authenticatedUserSubject, props.authenticatedUserRoles, props.detailedControlResponse.configuration.review_configuration?.owner_subject, props.detailedControlResponse.configuration.review_configuration?.reviewer_subject), [props.authenticatedUserSubject, props.authenticatedUserRoles, props.detailedControlResponse]);

    const assessmentHasStarted = (): boolean => {
        if (props.assessment?.assessment_state === AssessmentState.UNDER_REVIEW) {
            return true;
        }

        return props.assessment?.assessment_state === AssessmentState.IN_PROGRESS && ((props.assessment.owner_comments && props.assessment.owner_comments.length > 0) || props.assessment.assessment_effectiveness !== undefined);
    };

    const assessmentButton = (): JSX.Element | null => {
        const assessmentConfigured = props.assessment !== undefined;
        const userCanSubmitForReview = userAssessmentRoles.has(AssessmentRole.ADMIN) || userAssessmentRoles.has(AssessmentRole.OWNER);
        const userCanApprove = userAssessmentRoles.has(AssessmentRole.ADMIN) || userAssessmentRoles.has(AssessmentRole.REVIEWER);

        if (!assessmentConfigured) {
            return null;
        }

        let buttonText = 'Assessment';

        if (!assessmentHasStarted()) {
            if (userCanSubmitForReview) {
                buttonText = 'Begin Assessment';
            }
            return (
                <Link variant="primaryButton" to={props.assessmentLinkData.to} state={props.assessmentLinkData.state} fontAwesomeImage={faClipboard}>
                    {buttonText}
                </Link>
            );
        }

        if (props.assessment?.assessment_state === AssessmentState.IN_PROGRESS) {
            return (
                <Link variant="primaryButton" to={props.assessmentLinkData.to} state={props.assessmentLinkData.state} fontAwesomeImage={faClipboard}>
                    Assessment
                </Link>
            );
        }

        if (userCanApprove) {
            buttonText = 'Review Assessment';
        }

        return (
            <Link variant="primaryButton" to={props.assessmentLinkData.to} state={props.assessmentLinkData.state} fontAwesomeImage={faClipboardCheck}>
                {buttonText}
            </Link>
        );
    };

    const sortAssessmentHistory = (): AssessmentHistory[] => {
        if (!props.assessmentHistoryList) {
            return [];
        }
        const copiedList = [...props.assessmentHistoryList];

        copiedList.sort((assessmentHistoryItemA, assessmentHistoryItemB) => {
            let propertyA;
            let propertyB;

            switch (currentSort) {
                case AssessmentHistorySortOptions.OWNER_COMMENT:
                    propertyA = getLatestOwnerCommentString(assessmentHistoryItemA);
                    propertyB = getLatestOwnerCommentString(assessmentHistoryItemB);
                    break;
                case AssessmentHistorySortOptions.REVIEWER_COMMENT:
                    propertyA = assessmentHistoryItemA.assessment.reviewer_comment?.text;
                    propertyB = assessmentHistoryItemB.assessment.reviewer_comment?.text;
                    break;
                case AssessmentHistorySortOptions.SUBMITTED_TIMESTAMP:
                    propertyA = getLatestSubmittedTimestamp(assessmentHistoryItemA);
                    propertyB = getLatestSubmittedTimestamp(assessmentHistoryItemB);
                    break;
                default: // AssessmentHistorySortOptions.APPROVED_TIMESTAMP
                    propertyA = assessmentHistoryItemA.timestamp;
                    propertyB = assessmentHistoryItemB.timestamp;
                    break;
            }

            return undefinedComparator(propertyA, propertyB, (propertyA, propertyB) => {
                const sortCompareResult = (propertyA as string).localeCompare(propertyB as string);

                return currentSortDirection === SortDirection.ASC ? sortCompareResult : -sortCompareResult;
            });
        });

        return copiedList;
    };

    const tableRow = (assessmentHistory: AssessmentHistory): JSX.Element => {
        return (
            <TableRow key={assessmentHistory.timestamp}>
                <TableCellDefaultText>
                    <Text noStyles={true}>{assessmentHistory.assessment.owner_comments && assessmentHistory.assessment.owner_comments.length > 0 ? iso8601ToUsDateLong(getLatestSubmittedTimestamp(assessmentHistory)!) : '-'}</Text>
                </TableCellDefaultText>
                <TableCellDefaultText>
                    <Text noStyles={true}>{iso8601ToUsDateLong(assessmentHistory.timestamp)}</Text>
                </TableCellDefaultText>
                <TableCellDefaultText>
                    <Text noStyles={true} preserveLineBreaks>
                        {getLatestOwnerCommentString(assessmentHistory)}
                    </Text>
                </TableCellDefaultText>
                <TableCellDefaultText>
                    <Text noStyles={true} preserveLineBreaks>
                        {assessmentHistory.assessment.reviewer_comment?.text !== undefined ? assessmentHistory.assessment.reviewer_comment.text : '-'}
                    </Text>
                </TableCellDefaultText>
            </TableRow>
        );
    };

    /** Get the most recent Control Owner comments.  */
    const getLatestOwnerCommentString = (assessmentHistory: AssessmentHistory): string | undefined => {
        const owner_comments = assessmentHistory.assessment.owner_comments;
        if (!owner_comments || owner_comments.length === 0) {
            return;
        }
        return owner_comments[owner_comments.length - 1].text;
    };

    /** Get the timestamp of the most recent Control Owner comments.  */
    const getLatestSubmittedTimestamp = (assessmentHistory: AssessmentHistory): string | undefined => {
        const owner_comments = assessmentHistory.assessment.owner_comments;
        if (!owner_comments || owner_comments.length === 0) {
            return;
        }
        return owner_comments[owner_comments.length - 1].timestamp;
    };

    const sortableTableProps: SortableTableHeaderProps = {
        headers: [
            { dataKey: AssessmentHistorySortOptions.SUBMITTED_TIMESTAMP, label: 'Submitted' },
            { dataKey: AssessmentHistorySortOptions.APPROVED_TIMESTAMP, label: 'Approved' },
            { dataKey: AssessmentHistorySortOptions.OWNER_COMMENT, label: 'Owner Comment' },
            { dataKey: AssessmentHistorySortOptions.REVIEWER_COMMENT, label: 'Reviewer Comment' },
        ],
        setSortColumnAndDirection: (newSort, newSortDirection) => {
            setCurrentSort(newSort as AssessmentHistorySortOptions);
            setCurrentSortDirection(newSortDirection);
        },
        currentSort,
        currentSortDirection,
        tableIncludesOverflowMenu: false,
    };

    return (
        <Fragment>
            <div className={styles.formHeaderContainer}>
                <Text color="blue" variant="Header2">
                    Assessment History
                </Text>
                {assessmentButton()}
            </div>
            {props.assessmentDetailsError && <Text color="red">{props.assessmentDetailsError}</Text>}
            <div className={styles.tableContainer}>
                <Table>
                    <SortableTableHeader {...sortableTableProps} />
                    <TableBody>{sortAssessmentHistory().map(tableRow)}</TableBody>
                </Table>
            </div>
        </Fragment>
    );
};
